

import React from "react";
import { useContext } from "react";
import { DASHBOARD_CONTEXT } from "../DashboardAPI";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from "recharts";

const ProjectView = () => {

      const { PROJECT_VIEW } = useContext(DASHBOARD_CONTEXT);

    const [data1, data2] = PROJECT_VIEW;
    
      const getIntroOfPage = (label) => {
        if (label === "Running Project") {
          return (
            <div className="">
              {data1
                .filter((data) => data.status === "running")
                .map((filter, i) => (
                  <div className="mb-3" key={i}>
                    <p className="text-sm text-base-100 font-standard">
                      name:{" "}
                      <span className="text-primary font-medium">
                        {filter.name}
                      </span>
                    </p>
                    <p className="font-premium text-xl">
                      started:{" "}
                      <span className="text-purple-400 text-lg">{filter.started}</span>
                    </p>
                  </div>
                ))}
            </div>
          );
        }
        if (label === "Completed Project") {
          return (
            <div>
              {data1
                .filter((data) => data.status === "complete")
                .map((filter, i) => (
                  <div className="mb-3" key={i}>
                    <p className="text-sm font-standard">
                      name:{" "}
                      <span className="text-primary font-medium">
                        {filter.name}
                      </span>
                    </p>
                    <p className="font-premium">
                      started:{" "}
                      <span className="text-base-100">{filter.started}</span>
                    </p>
                    <p className="font-premium">
                      end:{" "}
                      <span className="text-base-100">{filter.completed}</span>
                    </p>
                    <p className="text-lg font-standard font-medium">
                      duration:{filter.duration}
                    </p>
                  </div>
                ))}
            </div>
          );
        }

        return "";
    };
    
      const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="p-5 bg-info bg-opacity-80">
              <p className="font-premium text-accent text-lg mb-2">{`${label} : ${payload[0].value}`}</p>
              <div>{getIntroOfPage(label)}</div>
            </div>
          );
        }

        return null;
      };
    return (
      <section className="my-20 overflow-hidden">
        <div>
          <h1 className="lg:text-4xl text-3xl font-premium mb-10">
            Project Analytics
          </h1>
        </div>

        <div className="lg:w-10/12 w-[400px] h-[300px] lg:h-[370px] overflow-hidden">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart width={500} height={300} data={data2}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="value" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="count" barSize={20} fill="#588157" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </section>
    );
};

export default ProjectView;



import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, FreeMode, Navigation } from "swiper";
import { RiDoubleQuotesL } from "react-icons/ri";
import { FaStar, FaUserAlt } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";



import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import HeadingText from "../../../components/HeadingText/HeadingText";



const WhatClientSay = () => {

  
   const { data: reviews = [] } = useQuery({
     queryKey: ["reviews"],
     queryFn: async () => {
       const response = await fetch(
         `${process.env.REACT_APP_SERVER_URL}/reviews/testimonial`
       );
       const data = await response.json();
       return data;
     },
   });
  
    
 
  

    return (
      <section className="my-32 bg-primary">
        <div className="lg:max-w-screen-xl pt-28 pb-36 mx-auto w-[90%]">
          <HeadingText
            headingClass={"text-gray-100"}
            descriptionClass={"text-gray-400 my-10 lg:my-20"}
            heading={"Praise of Clients"}
            description={
              " we value each and every one of our customers, and we're committed to providing exceptional service that meets your unique needs. This section is dedicated to showcasing the feedback and reviews we've received from our clients, as we believe that their insights are crucial to our continuous improvement and growth. "
            }
          ></HeadingText>
          <div>
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              freeMode={true}
              grabCursor={true}
              navigation={true}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[FreeMode, Pagination, Navigation]}
              className="mySwiper"
            >
              {reviews.length > 0 &&
                reviews?.map(
                  (
                    { name, ratings, review, image, reviewDate, reviewFor },
                    i
                  ) => (
                    <SwiperSlide key={i}>
                      <div className="flex gap-5 mt-10 lg:mt-0 justify-start lg:mb-8 mb-5">
                        <div className="h-16 w-16">
                          <figure className="lg:h-16 h-14 lg:w-16 w-14 rounded-full">
                            {image ? (
                              <img
                                className="rounded-full lg:h-16 h-14 lg:w-16 w-14"
                                src={image}
                                alt=""
                              />
                            ) : (
                              <FaUserAlt className="rounded-full w-full bg-gray-500" />
                            )}
                          </figure>
                        </div>

                        <div className="w-auto">
                          <p className="lg:text-xl text-lg text-base-200 font-standard">
                            {name}
                          </p>
                          <p className="flex items-center gap-1">
                            {[...Array(ratings)]?.map((rate, i) => (
                              <FaStar
                                key={i}
                                size={15}
                                className="text-orange-400"
                              />
                            ))}
                          </p>
                          <p className="text-sm font-premium text-gray-400">
                            {reviewDate}
                          </p>
                          <p className="text-gray-200">
                            Review for:{" "}
                            <span className="text-accent tracking-wider text-sm">
                              {reviewFor}
                            </span>
                          </p>
                          <div className="flex items-start -ml-14 gap-5 mt-10">
                            <p className="bg-gray-200 h-12 p-2 -mt-4 w-12 grid place-items-center rounded-full">
                              <RiDoubleQuotesL
                                size={30}
                                className="text-gray-500"
                              />
                            </p>
                            <p className="text-lg text-gray-200 font-light tracking-wider font-premium">
                              {review?.slice(0, 150)}..
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                )}
            </Swiper>
          </div>
        </div>
      </section>
    );
};

export default WhatClientSay;
/** @format */

import React from "react";
import { useState } from "react";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaCheck, FaChevronRight, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../../../components/CommonButton/CommonButton";
import EditTags from "../../../../components/EditTags/EditTags";
import Loading from "../../../../components/Loading/Loading";
import {
  handleEditMotive,
  handleSetMotive,
  setAddNewMotive,
  setMotiveIndex,
  setNewMotiveValue,
  setProjectGalleryIndex,
  updatedProjects,
} from "../../../../state/EditProjectReducer/EditProjectActionCreator";
import { getTimeDuration } from "../../../../utilities/getTimeDuration";
import { EDIT_PROJECT_CONTEXT } from "./EditProject";
import UseDeviceWidth from "../../../../Hooks/UseDevice/UseDeviceWidth";

const EditProjectDate = () => {
  const navigate = useNavigate();
  const { state, editDispatch } = useContext(EDIT_PROJECT_CONTEXT);
  const projectId = localStorage.getItem("ProjectId");
  const mobile = UseDeviceWidth();
  const [running, setRunning] = useState(false);

  const {
    loading,
    error,
    errorMessage,
    newMotive,
    addNewMotive,
    newMotiveValue,
    editMotive,
    deleteMotiveIndex,
    newGallery,
    deleteGalleryIndex,
    updateProject,
  } = state;

  const {
    title,
    email,
    phone,
    location,
    thumbnail,
    description,
    motive,
    status,
    startDate,
    endDate,
    index: dataIndex,
  } = updateProject;

  // Create react hook form variables
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Set default values for input fields. It's because the default values isn't showing because of the fetching/loading
  useEffect(() => {
    let cleanup = false;
    if (status === 'running' && !cleanup) {
      setRunning(true);
    } 

    reset({
      title,
      email,
      phone,
      location,
      description,
      motive,
      startDate,
      endDate,
    });

    return () => {
      cleanup = true;
    }
  }, [
    reset,
    title,
    email,
    phone,
    location,
    startDate,
    endDate,
    description,
    motive,
    status
  ]);

  if (loading) {
    return <Loading>Loading...</Loading>;
  }
  if (error) {
    return (
      <p className="text-center my-52 text-2xl tracking-widest text-error font-semibold ">
        {errorMessage}
      </p>
    );
  }


  const [inputValue, index] = editMotive;

  if (editMotive.length >= 1) {
    newMotive[index] = inputValue;
    editDispatch(handleEditMotive([]));
  }

  if (deleteMotiveIndex !== null && newMotive.length >= deleteMotiveIndex) {
    newMotive.splice(deleteMotiveIndex, 1);
    editDispatch(setMotiveIndex(null));
  }

  // Delete all motive
  const handleDeleteAllMotive = () => {
    editDispatch(handleSetMotive([]));
  };

  // Add new motive
  const handleAddNewMotive = () => {
    editDispatch(handleSetMotive([...newMotive, newMotiveValue]));
    editDispatch(setAddNewMotive(false));
  };

  // Edit gallery
  if (deleteGalleryIndex !== null) {
    newGallery.splice(deleteGalleryIndex, 1);
    editDispatch(setProjectGalleryIndex(null));
  }

  /* Submitting form on clicking "next" button */
  const handleEditProject = (data) => {
    data.motive = newMotive;
    data.status = status;
    data.index = dataIndex;
    data.thumbnail = thumbnail;

    if (running && status === "complete") {
      let { year, month } = getTimeDuration(data.startDate);
      data.status = "running";
      data.duration = year ? year + " " + month : month;
      data.endDate = "";
    } 
   else if (!running && status === "running") {
      let { year, month } = getTimeDuration(data.startDate, data.endDate);
      data.duration = year ? year + " " + month : month;
      data.status = "complete";

    }

    editDispatch(updatedProjects({ ...updateProject, ...data }));
    navigate(`/dashboard/projects/${projectId}/editImage`);
  };


  return (
    <section>
      <div>
        <form onSubmit={handleSubmit(handleEditProject)} className="lg:mt-20">
          {/* ===================== Title ============================= */}

          <div className="relative mb-10 flex items-center gap-5">
            <h2 className="text-xl font-premium text-gray-800 flex font-medium">
              Title: <span className="text-red-600 ml-1 -mt-1">*</span>
            </h2>
            <input
              type="text"
              defaultValue={title}
              id="name"
              placeholder="Enter project title here"
              className="custom-text-input font-standard"
              {...register("title", { required: "Title is required" })}
            />
            {errors.title && (
              <p className="text-error">{errors.title?.message}</p>
            )}
          </div>

          {/* 
            {/* ============================== Description =============================*/}

          <div className="relative mb-10 flex flex-col gap-5">
            <div className="flex lg:flex-row flex-col lg:gap-5 gap-2">
              <h2 className="text-xl font-premium flex  text-gray-800 font-medium">
                Description: <span className="text-red-600 ml-1 -mt-1">*</span>
              </h2>
              <textarea
                defaultValue={description}
                placeholder="Write project description"
                className="custom-text-input font-standard"
                cols="30"
                rows={mobile ? "20" : "10"}
                {...register("description", {
                  required: "This field is required",
                })}
              ></textarea>
              {errors.description && (
                <p className="text-error">{errors.description?.message}</p>
              )}
            </div>
          </div>

          {/* ==================================== Motive ==============================*/}

          <div className="relative mb-6 flex flex-col gap-5">
            <h2 className="text-xl font-premium text-gray-800 font-medium underline underline-offset-2">
              Motives:
            </h2>

            {newMotive?.map((highlight, i) => (
              <EditTags
                key={i}
                highlight={highlight}
                index={i}
                editDispatch={editDispatch}
                deleteAction={setMotiveIndex}
                editAction={handleEditMotive}
              ></EditTags>
            ))}

            {addNewMotive && (
              <div className="flex lg:flex-row flex-col items-center gap-5">
                <textarea
                  onBlur={(e) =>
                    editDispatch(setNewMotiveValue(e.target.value))
                  }
                  type="text"
                  cols="50"
                  rows="4"
                  className="custom-text-input font-standard"
                />
                <div className="flex lg:flex-col flex-row  items-center gap-3">
                  <span className="p-3 hover:scale-110 transition-transform duration-200 bg-gray-300 rounded-full">
                    <FaCheck
                      onClick={handleAddNewMotive}
                      className="text-lg text-gray-700"
                    />
                  </span>
                  <span className="p-3 hover:scale-110 transition-transform duration-200 bg-gray-300 rounded-full">
                    <FaTimes
                      onClick={() => editDispatch(setAddNewMotive(false))}
                      className="text-lg text-gray-700"
                    />
                  </span>
                </div>
              </div>
            )}
            <div className="lg:my-5 my-7 flex gap-10">
              <CommonButton
                type={"button"}
                customClass={"!text-sm !py-2 tracking-[1px]"}
                clickFunction={() => editDispatch(setAddNewMotive(true))}
              >
                Add
              </CommonButton>
              <CommonButton
                customClass={"!text-sm !py-2 tracking-[1px]"}
                type={"button"}
                clickFunction={handleDeleteAllMotive}
              >
                Delete all
              </CommonButton>
            </div>
            {errors.motive && (
              <p className="text-error">{errors.motive?.message}</p>
            )}
          </div>

          {/* ==================== Starting and Ending Date ==============*/}

          <div className="flex flex-col  gap-7">
            <div className="relative mb-6 flex items-center w-full gap-5">
              <h2 className="text-xl w-1/4 font-premium flex text-gray-800 font-medium">
                Starting Date:{" "}
                <span className="text-red-600 ml-1 -mt-1">*</span>
              </h2>
              <input
                defaultValue={startDate}
                type="date"
                id="startDate"
                className="custom-text-input w-3/4 font-standard"
                {...register("startDate", {
                  required: "starting date is required",
                })}
              />
              {errors.startDate && (
                <p className="text-error">{errors.startDate?.message}</p>
              )}
            </div>
            <div className="mb-7">
              <label className="cursor-pointer mb-3 flex items-center gap-5">
                <input
                  onClick={() => setRunning(!running)}
                  type="checkbox"
                  className="checkbox checkbox-success"
                  defaultChecked={status === "running"}
                />
                <span className="text-lg font-standard">
                  Are you still working on this project?
                </span>
              </label>
              {!running && (
                <div className="relative mb-6 flex items-center w-full gap-5">
                  <h2 className="text-xl w-1/4 font-premium flex text-gray-800 font-medium">
                    Ending Date:{" "}
                    <span className="text-red-600 ml-1 -mt-1">*</span>
                  </h2>
                  <input
                    defaultValue={endDate}
                    type="date"
                    id="endDate"
                    className="custom-text-input w-3/4 font-standard"
                    {...register("endDate", {
                      required: "ending date is required",
                    })}
                  />
                  {errors.endDate && (
                    <p className="text-error">{errors.endDate?.message}</p>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* ================================ Email & Phone ========================= */}

          <div className="flex lg:flex-row flex-col mb-7 lg:gap-10 gap-2">
            <div className="relative mb-6 flex items-center lg:w-1/2 gap-5">
              <h2 className="text-xl font-premium flex text-gray-800 font-medium">
                Email: <span className="text-red-600 ml-1 -mt-1">*</span>
              </h2>
              <input
                defaultValue={email}
                type="email"
                placeholder="Enter your email address"
                id="email"
                className="custom-text-input font-standard"
                {...register("email", { required: "Email is required" })}
              />
              {errors.email && (
                <p className="text-error">{errors.email?.message}</p>
              )}
            </div>
            <div className="relative mb-6 flex items-center lg:w-1/2 gap-5">
              <h2 className="text-xl font-premium flex  text-gray-800 font-medium">
                Phone: <span className="text-red-600 ml-1 -mt-1">*</span>
              </h2>
              <input
                defaultValue={phone}
                type="number"
                placeholder="Enter phone number"
                id="phone"
                className="custom-text-input font-standard"
                {...register("phone", { required: "Phone is required" })}
              />
              {errors.phone && (
                <p className="text-error">{errors.phone?.message}</p>
              )}
            </div>
          </div>

          {/* ============================== Location ========================= */}
          <div className="relative mb-6 flex lg:flex-row flex-col lg:gap-5 gap-2">
            <h2 className="text-xl font-premium text-gray-800 font-medium">
              Location:{" "}
            </h2>
            <textarea
              defaultValue={location}
              type="text"
              placeholder="Enter contract location"
              id="location"
              rows="5"
              className="custom-text-input font-standard"
              {...register("location")}
            />
            {errors.location && (
              <p className="text-error">{errors.location?.message}</p>
            )}
          </div>

          <div className="text-right mt-10">
            <CommonButton type={"submit"} customClass={"tracking-[3px]"}>
              Next
              <FaChevronRight className="ml-3" />
            </CommonButton>
          </div>
        </form>
      </div>
    </section>
  );
};

export default EditProjectDate;

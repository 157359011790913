/** @format */

import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Link } from "react-router-dom";
import CommonButton from "../../../components/CommonButton/CommonButton";
import HeadingText from "../../../components/HeadingText/HeadingText";


const MyProjectSample = () => {


  const { data: projects = [], refetch } = useQuery({
    queryKey: ["projects"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/projects?data=2`
      );
      const data = await response.json();
      return data;
    },
  });


  return (
    <section id="projectSample" className="lg:max-w-screen-xl w-[90%] mx-auto">
      <HeadingText
        heading={"My Projects"}
        descriptionClass={"text-gray-700"}
        description={
          "Here you will find a showcase of some of the projects I have worked on, highlighting my skills, expertise and experience in the field. Each project has been carefully chosen to demonstrate my ability to deliver high-quality work, meet client needs and exceed expectations. Take a look around and get a sense of the range of projects I have undertaken, from small-scale assignments to large, complex projects. "
        }
      ></HeadingText>
      <div>
        {projects.length &&
          projects?.map(({ _id, thumbnail, description, title }, i) => (
            <div
              key={_id}
              className="flex gap-14 relative project-container items-center lg:pt-24 flex-col lg:mb-0 mb-10"
            >
              <div className="lg:w-1/2 w-full ml-2 lg:self-start mt-10">
                <figure className="lg:w-[480px] lg:min-h-[300px] w-[350px] ml-2 lg:mx-auto my-auto projectThumb relative">
                  <img
                    className="lg:w-[480px] w-[350px] lg:min-h-[300px] ml-2 lg:ml-0 rounded-2xl"
                    src={thumbnail?.url}
                    alt=""
                  />
                </figure>
              </div>

              <div className="lg:w-1/2 lg:-mt-20 lg:self-end w-full">
                <h2
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-easing="ease-in-cubic"
                  className="lg:text-5xl  text-primary tracking-widest text-4xl font-Bangla"
                >
                  {title}
                </h2>
                <p className="w-24 h-[2px] mb-10 ml-[10%] mt-2  bg-accent"></p>
                <p className="font-standard text-lg lg:mb-10 mb-3">
                  {description?.slice(0, 350)}...
                </p>
                <Link
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                  to={`/project/${_id}`}
                  className="font-premium text-lg text-info font-medium tracking-widest hover:underline"
                >
                  Read more..
                </Link>
              </div>
            </div>
          ))}
      </div>

      <div className="text-center mt-[15%]">
        <Link
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          to="/allProjects"
        >
          <CommonButton type={"button"} customClass={"w-1/3"}>
            See All
          </CommonButton>
        </Link>
      </div>
    </section>
  );
};

export default MyProjectSample;

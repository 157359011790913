/** @format */

import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useState } from "react";
import { useEffect } from "react";
import { v4 } from "uuid";
import { storage } from "../../Firebase/Firebase.config";


const useUploadImage = (image, folder, id, profile) => {
  const [progress, setProgress] = useState(null);
  const [imgUrl, setImgUrl] = useState([]);

  useEffect(() => {
    if (image?.length === 0) {
      return;
    }

    let cleanup = false;

    if (image?.length >= 1 && folder) {
      image.forEach((img) => {
        let locationId = v4();

        let imageRef;
        if (profile) {
          imageRef = ref(storage, `${folder}/${id}`);
        } else {
          imageRef = ref(storage, `${folder}/${id}/${locationId}`);
        }

        const uploadTask = uploadBytesResumable(imageRef, img);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress);
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
              default:
                break;
            }
          },
          (error) => {
            switch (error.code) {
              case "storage/unauthorized":
                break;
              case "storage/canceled":
                break;

              case "storage/unknown":
                break;
              default:
                break;
            }
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              if (!cleanup) {
                setProgress(null);
                setImgUrl((prev) => [...prev, { url, imageId: locationId }]);
              }
            });
          }
        );
      });
    }

    return () => {
      cleanup = true;
    };
  }, [id, image, folder, profile]);

  return [imgUrl, progress];
};

export default useUploadImage;

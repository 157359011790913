
import React from 'react';
import './CommonButton.css';


const CommonButton = ({ children, clickFunction, type, customClass }) => {
  return (
    <button onClick={clickFunction} type={type} className={`custom-btn ${customClass && customClass}`}>
      {children}
    </button>
  );
};

export default CommonButton;

/** @format */

import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { HiOutlineUpload } from "react-icons/hi";
import useResizeImage from "../../../../../Hooks/ResizeImage/useResizeImage";
import useUploadImage from "../../../../../Hooks/UploadImage/useUploadImage";

import { ADD_BLOGS_CONTEXT } from "./AddNewBlogs";

const BlogThumbUpload = () => {
  const { newBlogs, setNewBlogs } = useContext(ADD_BLOGS_CONTEXT);
  const { thumbnail, storageId } = newBlogs;

  const [file, setFile] = useState([]);

  const compressedImage = useResizeImage(file, 720, 70);

  const [url, progress] = useUploadImage(
    compressedImage,
    "Blogs",
    storageId,
    false
  );

  useEffect(() => {
    let cleanup = false;

    if (url?.length === file?.length && !cleanup) {
      setNewBlogs({ ...newBlogs, thumbnail: url[0] });
    }

    return () => {
      cleanup = true;
    };
  }, [url]);


  return (
    <section className="my-32">
      <div className="flex lg:flex-row flex-col items-center lg:gap-10 mb-6">
        <div className="lg:w-[600px] lg:ml-16">
          <img
            className="h-[200px] lg:h-auto w-[350px] lg:w-full rounded-lg"
            src={thumbnail?.url}
            alt=""
          />
        </div>
        <div className="relative flex flex-col my-10 items-start gap-1 w-full">
          <h2 className="text-xl text-gray-800 flex font-standard font-semibold">
            Thumbnail:
          </h2>

          <input
            type="file"
            id="thumbnail"
            className="opacity-0 relative w-1 h-1"
            onChange={(e) => setFile(e.target.files)}
          />
          {progress !== null ? (
            <progress
              className="progress h-1 mt-5 progress-success w-full"
              value={progress}
              max="100"
            ></progress>
          ) : (
            <label
              title="upload cover image"
              htmlFor="thumbnail"
              className="custom-file-label !font-premium  tracking-[2px]"
            >
              <HiOutlineUpload size={30} />
              Select Thumbnail
            </label>
          )}
        </div>
      </div>
    </section>
  );
};

export default BlogThumbUpload;


import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Link } from 'react-router-dom';
import CommonButton from '../../../components/CommonButton/CommonButton';
import HeadingText from '../../../components/HeadingText/HeadingText';
import './Services.css';

const Services = () => {

    const { data: services = [], refetch } = useQuery({
        queryKey: ['services'],
        queryFn: async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/services?data=3`);
            const data = await response.json();
            return data;

        }
    })

    

    return (
      <section
        id="service"
        className="py-32 lg:max-w-screen-xl w-[90%] mx-auto"
      >
        <p className="uppercase text-secondary text-center font-medium text-lg">
          I am great at
        </p>
        <HeadingText
          heading={"Explore my services"}
          description={
            "My services are designed to meet the needs of humanity and individuals alike, delivering tailored solutions that drive success. Whether you are looking for comprehensive project management, health, financial, skilled labor, or specialized expertise, I am equipped to deliver the highest quality services to meet your unique needs. With a focus on efficiency, communication, and collaboration, I work closely with clients to ensure seamless project execution and exceptional results. Let's work together to turn your vision into reality."
          }
          descriptionClass={"text-gray-700"}
        ></HeadingText>

        <div className="grid lg:grid-cols-3 grid-cols-1 mt-32 lg:gap-7 gap-3">
          {services?.length >= 1 &&
            services?.map(({ _id, thumbnail, title, about }) => (
              <div
                key={_id}
                data-aos="fade-up"
                data-aos-duration="1000"
                data-easing="ease-in-cubic"
                className=" mb-8 lg:mb-0 relative bg-base-100 flex flex-row lg:flex-col shadow-xl rounded-xl"
              >
                <div className="service-card">
                  <figure className="h-full grid place-items-center lg:h-64 lg:w-[360px] w-36 overflow-hidden lg:rounded-t-md lg:rounded-bl-none rounded-l-md bg-white">
                    <img
                      src={thumbnail?.url}
                      alt="service"
                      className="w-32 h-32 rounded-md lg:h-64 lg:w-[360px]"
                    />
                  </figure>
                </div>
                <div className="lg:p-3  rounded-r-md  lg:rounded-r-none p-2 pl-5">
                  <h2 className="lg:text-2xl text-xl font-Bangla tracking-[2px] lg:mb-10 text-info">
                    {title}
                  </h2>
                  <p className="font-standard hidden mb-10 lg:block">
                    {about?.slice(0, 120)}...
                  </p>
                  <p className="font-standard text-sm pt-5 pb-8 lg:hidden">
                    {about?.slice(0, 50)}...
                  </p>

                  <Link
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                    className="absolute text-info lg:text-xl text-base font-stylish font-semibold tracking-widest underline-offset-2 lg:bottom-5 bottom-2 underline right-5 lg:right-10"
                    to={`/service/${_id}`}
                  >
                    Explore..
                  </Link>
                </div>
              </div>
            ))}
        </div>
        <div className="lg:mt-24 mt-10 text-center">
          <Link
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            to="/allServices"
          >
            <CommonButton
              customClass={"lg:w-1/4 w-1/2 tracking-[3px]"}
              type={"button"}
            >
              See More
            </CommonButton>
          </Link>
        </div>
      </section>
    );
};

export default Services;

import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { AuthContext } from '../../Contexts/UserContext/UserContext';
import useResizeImage from '../../Hooks/ResizeImage/useResizeImage';
import useUploadImage from '../../Hooks/UploadImage/useUploadImage';
import UseTitle from '../../Hooks/UseTitle/UseTitle';
import { deleteImage } from '../../utilities/deleteImage';
import { NavHashLink as NavLink } from "react-router-hash-link";
import CommonButton from '../../components/CommonButton/CommonButton';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import { FaUserAlt } from "react-icons/fa";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin2Line } from "react-icons/ri";
import profileSVG from "../../assets/SVG/profile.svg";


const Profile = () => {

  UseTitle('JE-Profile')
  const {
    updateUser,
    user,
    updateUserEmail,
    updateUserPassword,
    setLoading,
  } = useContext(AuthContext);

  const [uploadImg, setUploadImg] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const compressedImage = useResizeImage(uploadImg, 250, 70);

  const [url, progress] = useUploadImage(
    compressedImage,
    "Profiles",
    user?.uid,
    true
    );
    

  // Update user information on form submit
  const handleUpdateUser = (data) => {
    const { name, email, uPassword } = data;

    if (name && name !== user?.displayName) {
      const userInfo = {
        displayName: name,
      };

      updateUser(userInfo)
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (email && email !== user?.email) {
      updateUserEmail(email)
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (uPassword) {
      updateUserPassword(uPassword)
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    toast.success("User updated successfully");
  };

  // Update photo url
  if (url?.length > 0) {
    const userInfo = {
      photoURL: url[0].url,
    };

    toast.success("User photo updated successfully");
    updateUser(userInfo)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // Handle delete user photo
  const handleDeleteUserPhoto = () => {
    const { deleted } = deleteImage("Profiles", null, user?.uid);

    if (deleted) {
      toast.success("User photo deleted successfully");
      const userInfo = {
        photoURL: "",
      };

      updateUser(userInfo)
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };



  return (
    <section className="lg:max-w-screen-lg overflow-x-hidden overflow-y-hidden mx-auto w-[90%] pt-32 mb-32">
      <div className="mb-16 lg:text-start text-center">
        <h1 className="text-4xl font-stylish font-bold">Update your profile</h1>
        <p className="w-28 h-1 mb-10 lg:ml-[10%] mt-2 ml-[35%]  bg-primary"></p>
      </div>
      <div className="flex lg:flex-row gap-10 flex-col">
        <div className="lg:w-2/5 w-full lg:border-r-2 mt-10 relative border-gray-400">
          <div
            className="h-48 w-48 mx-auto  online relative rounded-full ring ring-primary
                 ring-offset-accent ring-offset-2"
          >
            {user?.photoURL === null ? (
              <FaUserAlt className="text-6xl text-gray-300 h-[90%] w-[90%] absolute top-4 left-3 rounded-full" />
            ) : (
              <img
                className="w-full h-full rounded-full"
                src={user?.photoURL}
                alt="user"
              />
            )}
            <input
              multiple
              onChange={(e) => setUploadImg(...uploadImg, e.target.files)}
              type="file"
              id="photo"
              className="opacity-0 relative w-1 h-1"
            />
          </div>

          {/* ============== SVG ============= */}
          <img
            className="absolute -top-10 mr-10 -z-10 left-10 rotate-12 scale-125"
            src={profileSVG}
            alt=""
          />
          {progress !== null ? (
            <div className="mt-10 flex items-center gap-2 flex-col">
              <progress
                className="progress mt-5 progress-success w-56"
                value={progress}
                max="100"
              ></progress>
              <p className="text-xl text-primary">{progress} %</p>
            </div>
          ) : (
            ""
          )}
          <p className="flex justify-end gap-4 mr-8 mt-20">
            <label htmlFor="confirmModal">
              <CommonButton customClass={"!px-3"}>
                <RiDeleteBin2Line title="Delete photo" className="text-3xl" />
              </CommonButton>
            </label>
            <label htmlFor="photo">
              <CommonButton  customClass={"!px-3"}>
                <TbEdit title="Edit photo" className="text-3xl " />
              </CommonButton>
            </label>
          </p>
        </div>
        <div className="lg:w-3/5 w-full lg:px-10 px-5">
          <form
            onSubmit={handleSubmit(handleUpdateUser)}
            className="flex gap-10 flex-col "
            action=""
          >
            <div>
              <label
                className="text-gray-700 font-standard mb-1 font-medium"
                htmlFor="name"
              >
                Your Name
              </label>
              <input
                type="text"
                name="name"
                defaultValue={user?.displayName}
                className="custom-text-input !py-3"
                {...register("name")}
              />
              {errors.name && (
                <span className="text-red-700">{errors.name?.message}</span>
              )}
            </div>
            <div>
              <label
                className="text-gray-700 font-standard mb-1 font-medium"
                htmlFor="name"
              >
                Your Email
              </label>
              <input
                type="email"
                name="email"
                defaultValue={user?.email}
                className="custom-text-input !py-3"
                {...register("email")}
              />
              {errors.email && (
                <span className="text-red-700">{errors.email?.message}</span>
              )}
            </div>
            <div>
              <label
                className="text-gray-700 font-standard mb-1 font-medium"
                htmlFor="name"
              >
                Enter updated password
              </label>
              <input
                type="password"
                name="uPassword"
                placeholder="******"
                className="custom-text-input !py-3"
                {...register("uPassword")}
              />
              {errors.uPassword && (
                <span className="text-red-700">
                  {errors.uPassword?.message}
                </span>
              )}
            </div>
            <div className="flex gap-10 mb-5">
              <CommonButton type={"submit"}>Update</CommonButton>
              <NavLink to="/#home">
                <CommonButton>Cancel</CommonButton>
              </NavLink>
            </div>
          </form>
        </div>
      </div>

      <ConfirmationModal
        modalText={"Are you sure you want to delete your photo?"}
        actionText={"Delete"}
        action={handleDeleteUserPhoto}
      ></ConfirmationModal>
    </section>
  );
};

export default Profile;
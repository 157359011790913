
import { deleteObject, ref } from "firebase/storage";
import { storage } from "../Firebase/Firebase.config";


export const deleteImage = (folder, subFolder, imageId) => {
  if (!folder || !imageId) return;

  let deleteRef;
  if (subFolder === null) {
    deleteRef = ref(storage, `${folder}/${imageId}`);
  } else {
    deleteRef = ref(storage, `${folder}/${subFolder}/${imageId}`);
  }

  deleteObject(deleteRef)
    .then(() => {})
    .catch((err) => {
      console.log(err);
    });

  return { deleted: true };
};
/** @format */

import React, { useReducer } from "react";
import { useContext } from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { AuthContext } from "../../../Contexts/UserContext/UserContext";
import { IoIosArrowUp } from "react-icons/io";
import Login from "../Login/Login";
import SignUp from "../SignUp/SignUp";
import "./Navbar.css";
import {
  navInitialState,
  navReducer,
} from "../../../state/NavReducer/NavReducer";
import { useEffect } from "react";
import { navActionTypes } from "../../../state/NavReducer/NavActionTypes";
import useToken from "../../../Hooks/GetToken/useToken";
import { useMemo } from "react";
import UserDetails from "./UserDetails";
import { useLocation } from "react-router-dom";

const Navbar3 = () => {
  const { user, logOut, setAutoModal } = useContext(AuthContext);
  const token = useToken(user?.email);
  const { pathname } = useLocation();

  const [state, dispatch] = useReducer(navReducer, navInitialState);
  const { toggle, scroll } = state;

  const newUser = {
    name: user?.displayName,
    email: user?.email,
    image: user?.photoURL,
    uid: user?.uid,
  };

  const dependencies = useMemo(
    () => ({
      name: newUser.name,
      email: newUser.email,
      image: newUser.image,
    }),
    [newUser.name, newUser.image, newUser.email]
  );

  // Scroll detection
  useEffect(() => {
    window.addEventListener("scroll", function () {
      if (window.scrollY >= 250) {
        dispatch({ type: navActionTypes.SCROLL, payload: true });
      } else if (pathname.includes("dashboard") && this.window.scrollY >= 100) {
        dispatch({ type: navActionTypes.SCROLL, payload: true });
      } else {
        dispatch({ type: navActionTypes.SCROLL, payload: false });
      }
    });

    if (token) {
      // Update database user
      fetch(`${process.env.REACT_APP_SERVER_URL}/user/update`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
        body: JSON.stringify(newUser),
      })
        .then((res) => {
          if (res.status === 401 || res.status === 403) {
            return logOut();
          }
          return res.json();
        })
        .then((data) => {});
    }
  }, [logOut, token, dependencies]);

  // Toggle auto login/signUp modal
  useEffect(() => {
    let cleanup = false;

    setTimeout(() => {
      if (!user && !cleanup) {
        setAutoModal(true);
        dispatch({ type: navActionTypes.TOGGLE, payload: false });
      }
    }, 5000);

    return () => {
      cleanup = true;
    };
  }, [setAutoModal, user]);

  const handleTopScroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const navItems = [
    { route: "Home", path: "/#home" },
    { route: "About", path: "/#about" },
    { route: "Services", path: "/#service" },
    { route: "Projects", path: "/#projectSample" },
    { route: "Insights", path: "/#blog" },
    { route: "Contact", path: "/contact" },
  ];

  return (
    <section className="fixed top-0 z-[999]">
      <nav className={`nav ${scroll ? "bg-[#111]" : ""}`}>
        <NavLink
          smooth
          to="/#home"
          className={`logo text-base-100 lg:text-primary text-2xl lg:w-auto w-3/5 font-extralight lg:font-normal font-standard ${
            scroll ? "!pt-2 lg:!pt-0 lg:text-base-100" : ""
          }`}
        >
          <span className="font-stylish font-semibold text-accent text-4xl">
            Jahid
          </span>{" "}
          Enterprise
        </NavLink>

        <ul
          className={`navlinks lg:w-auto w-2/5 ${scroll ? "lg:!p-0" : ""} ${
            toggle ? "open" : ""
          }`}
        >
          {navItems.map(({ route, path }) => (
            <li
              key={route}
              onClick={() =>
                dispatch({ type: navActionTypes.TOGGLE, payload: false })
              }
              className={toggle ? "fade" : ""}
            >
              <NavLink
                className={` hover:!text-accent ${
                  scroll ? "lg:!text-base-100" : "lg:!text-neutral"
                }`}
                smooth
                to={path}
              >
                {route}
              </NavLink>
            </li>
          ))}

          {/* ============================== User details ======================== */}

          <UserDetails state={state} dispatch={dispatch}></UserDetails>
        </ul>

        {/* ===================== Toggle button ==================== */}
        <span
          onClick={() =>
            dispatch({ type: navActionTypes.TOGGLE, payload: !toggle })
          }
          className={`navTrigger ${toggle ? "active" : ""}`}
        >
          <i></i>
          <i></i>
          <i></i>
        </span>
      </nav>

      {/* Login and SignUp modal */}
      <Login></Login>
      <SignUp></SignUp>

      {/* ================= Scroll Top ============ */}
      <p
        onClick={handleTopScroll}
        title="Back to Top"
        className="p-3 fixed lg:bottom-10 bottom-5 lg:hover:bottom-9 transition-all duration-200 hover:bg-gray-700 lg:right-10 right-5 bg-neutral shadow-2xl shadow-slate-600 z-50 rounded-full text-center"
      >
        <IoIosArrowUp className="text-2xl  text-gray-200 shadow-2xl" />
      </p>
    </section>
  );
};

export default Navbar3;

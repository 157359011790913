
import React from "react";
import { useState, useEffect, useContext } from "react";
import { HiOutlineUpload } from "react-icons/hi";
import useResizeImage from "../../../../../Hooks/ResizeImage/useResizeImage";
import useUploadImage from "../../../../../Hooks/UploadImage/useUploadImage";
import { updatedProjects } from "../../../../../state/EditProjectReducer/EditProjectActionCreator";
import { deleteImage } from "../../../../../utilities/deleteImage";
import { EDIT_PROJECT_CONTEXT } from "../EditProject";

const EditProjectThumb = () => {

      const { state, editDispatch } = useContext(EDIT_PROJECT_CONTEXT);

      const [file, setFile] = useState(null);
      const { updateProject } = state;
      const { storageId } = updateProject;

      const compressedImage = useResizeImage(file, 720, 70);

      const [newUrl, progress] = useUploadImage(
        compressedImage,
        "Projects",
        storageId,
        false
      );

      useEffect(() => {
        let cleanup = false;

        if (!cleanup) {
          if (newUrl?.length > 0) {
            deleteImage(
              "Services",
              storageId,
              updateProject?.thumbnail?.imageId
            );

            editDispatch(
              updatedProjects({
                ...updateProject,
                thumbnail: newUrl[0],
              })
            );
          }
        }

        return () => {
          cleanup = true;
        };
      }, [newUrl]);
    return (
      <section className="flex lg:flex-row flex-col items-center lg:gap-10 mb-6">
        <div className="lg:w-[600px] lg:ml-16">
          <img
            className="h-[200px]  lg:h-auto w-[350px] lg:w-full rounded-lg"
            src={updateProject?.thumbnail?.url}
            alt=""
          />
        </div>
        <div className="relative flex flex-col my-10 items-start gap-1 w-full">
          <h2 className="text-xl text-gray-800 flex font-standard font-semibold">
            Thumbnail:
          </h2>

          <input
            type="file"
            id="thumbnail"
            className="opacity-0 relative w-1 h-1"
            onChange={(e) => setFile(e.target.files)}
          />
          {progress !== null ? (
            <progress
              className="progress h-1 mt-5 progress-success w-full"
              value={progress}
              max="100"
            ></progress>
          ) : (
            <label
              title="upload cover image"
              htmlFor="thumbnail"
              className="custom-file-label !font-premium  tracking-[2px]"
            >
              <HiOutlineUpload size={30} />
              Update Thumbnail
            </label>
          )}
        </div>
      </section>
    );
};

export default EditProjectThumb;
/** @format */

import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useEffect, useState, useContext } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineDelete } from "react-icons/ai";
import { FaStar } from "react-icons/fa";
import { RiDoubleQuotesL } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../components/CommonButton/CommonButton";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import Star from "../../components/Ratings/Star";
import { AuthContext } from "../../Contexts/UserContext/UserContext";
import UseAdmin from "../../Hooks/UseAdmin/UseAdmin";

const RatingsAndReviews = ({ id }) => {
  const [rating, setRating] = useState(null);
  const [deleteReviewId, setDeleteReviewId] = useState(null);
  const { user, setLoginModal } = useContext(AuthContext);
  const navigate = useNavigate();
  const [data] = UseAdmin(user?.email);

  const [allReview, setAllReview] = useState([]);
  const [reviewLimit, setReviewLimit] = useState(5);
  const [splitReview, setSplitReview] = useState([]);

  useEffect(() => {
    setSplitReview(allReview?.slice(0, reviewLimit));
  }, [allReview, reviewLimit]);

  // Getting all ratings and reviews
  const { data: reviews = [], refetch } = useQuery({
    queryKey: ["reviews"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/service/${id}/reviews`
      );
      const data = await response.json();
      setAllReview(data);
      return data;
    },
  });

  // Add new ratings & reviews
  const handleReview = (e) => {
    e.preventDefault();

    if (!user) {
      toast.error("Please login to add review");

      setTimeout(() => {
        return navigate("/login");
      }, 800);
    } else {
      e.preventDefault();
      const reviewText = e.target.review.value;

      const newReview = {
        uid: user?.uid,
        review: reviewText,
        name: user?.displayName ? user.displayName : "Unknown",
        reviewDate: new Date().toLocaleDateString(),
        ratings: rating,
        image: user.photoURL,
      };

      fetch(`${process.env.REACT_APP_SERVER_URL}/reviews/${id}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(newReview),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.acknowledged) {
            refetch();
            toast.success("Review added successfully");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Handle delete reviews
  const handleDeleteReview = () => {
    console.log(deleteReviewId);

    fetch(`${process.env.REACT_APP_SERVER_URL}/reviews/${id}/delete`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ deleteReviewId }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged) {
          refetch();
          toast.success("Review deleted successfully");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <section
      data-aos="fade-up"
      data-aos-duration="800"
      data-aos-easing="ease-in-cubic"
      className="bg-white py-20 my-32"
    >
      <div className="lg:max-w-screen-xl flex items-start lg:flex-row flex-col gap-20 w-[90%] mx-auto">
        <div className="lg:w-1/2 w-full">
          <form className="flex flex-col gap-10" onSubmit={handleReview}>
            <div>
              <h2 className="text-2xl font-stylish font-bold mb-3">
                Rate our service
              </h2>
              <Star rating={rating} setRating={setRating}></Star>
            </div>
            <div>
              <h2 className="text-2xl font-stylish font-bold mb-3">
                Add a review
              </h2>
              <textarea
                className="border-2 font-standard font-medium w-full p-3"
                name="review"
                rows="8"
                required="Add a review"
              ></textarea>
              {!user && (
                <p className="font-premium text-xl">
                  Please{" "}
                  <span
                    onClick={() => setLoginModal(true)}
                    className="underline cursor-pointer hover:text-accent tracking-wider"
                  >
                    login
                  </span>{" "}
                  to add review
                </p>
              )}
              <div className="flex gap-10 mt-5">
                <CommonButton>
                  <input type="submit" value="Add review" />
                </CommonButton>
                <CommonButton>
                  <input type="reset" value="Cancel" />
                </CommonButton>
              </div>
            </div>
          </form>
        </div>

        {/* ====================== REVIEWS ==================== */}
        {splitReview?.length >= 1 && (
          <div className="lg:w-1/2 w-full">
            <h2 className="lg:text-3xl text-2xl font-stylish font-bold text-primary">
              Some of our customer reviews
            </h2>
            <p className="w-36 h-1 mb-16 lg:ml-[20%] mt-2 ml-[15%]  bg-accent"></p>
            {splitReview?.map(
              ({ review, name, reviewDate, rating, uid }, i) => (
                <div key={i} className="flex gap-3 justify-start lg:mb-8 mb-5">
                  <p className="bg-gray-200 h-[50px] w-[50px] grid place-items-center rounded-full">
                    <RiDoubleQuotesL size={35} className="text-gray-500" />
                  </p>
                  <div>
                    <p className="lg:text-xl text-lg font-standard font-semibold">
                      {name}
                    </p>
                    <p className="flex items-center gap-1">
                      {[...Array(rating)]?.map((rate, i) => (
                        <FaStar key={i} size={15} className="text-orange-400" />
                      ))}
                    </p>
                    <p className="text-sm text-gray-600 mb-5">{reviewDate}</p>
                    <p className="text-lg font-premium ml-5">{review}</p>
                    {(data.admin || uid === user?.uid) && (
                      <label
                        htmlFor="confirmModal"
                        onClick={() => setDeleteReviewId(uid)}
                        className="w-10 h-10 bg-secondary mt-5 text-gray-300 hover:text-gray-300 rounded-full grid place-items-center hover:bg-primary"
                      >
                        <AiOutlineDelete className="text-xl" />
                      </label>
                    )}
                  </div>
                </div>
              )
            )}
            {allReview?.length > splitReview?.length ? (
              <p
                onClick={() => setReviewLimit(reviewLimit + 5)}
                className="text-blue-500 hover:underline"
              >
                View more...
              </p>
            ) : (
              splitReview?.length > 5 && (
                <p
                  onClick={() => setReviewLimit(reviewLimit - 5)}
                  className="text-blue-500 hover:underline"
                >
                  View less...
                </p>
              )
            )}
          </div>
        )}
      </div>

      <ConfirmationModal
        actionText={"Delete"}
        modalText={"Are you sure you want to delete this review?"}
        action={handleDeleteReview}
      ></ConfirmationModal>
    </section>
  );
};

export default RatingsAndReviews;

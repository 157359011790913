/** @format */

import { useContext, useState } from "react";
import { useEffect } from "react";
import { AuthContext } from "../../Contexts/UserContext/UserContext";

const UseAdmin = (email) => {
  const [admin, setAdmin] = useState(false);
  const [adminLoading, setAdminLoading] = useState(true);
  const { logOut } = useContext(AuthContext);

  useEffect(() => {
    if (email) {
      fetch(`${process.env.REACT_APP_SERVER_URL}/verifyUser?email=${email}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
      })
        .then((res) => {
          if (res.status === 401 || res.status === 403) {
            return logOut();
          }
          return res.json();
        })
        .then((data) => {
          setAdmin(data);
          setAdminLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [email, logOut]);
  return [admin, adminLoading];
};

export default UseAdmin;

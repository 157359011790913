
import React, { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { FaCheck, FaChevronRight, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../../../components/CommonButton/CommonButton";
import EditTags from "../../../../components/EditTags/EditTags";
import Loading from "../../../../components/Loading/Loading";
import { newProjectData, setAddNewMotive, setDeleteMotiveIndex, setEditMotive, setHighLightMotive, setInputValue } from "../../../../state/AddProjectReducer/AddProjectActionCreator";
import { getTimeDuration } from "../../../../utilities/getTimeDuration";
import { ADD_PROJECT_CONTEXT } from "./AddNewProject";

const UploadProjectData = () => {
  const { state, newDispatch } = useContext(ADD_PROJECT_CONTEXT);
  const navigate = useNavigate();
  const [running, setRunning] = useState(false);

  const {
    addNewMotive,
    highlightMotive,
    inputValue,
    deleteMotiveIndex,
    editMotive,
    addProjectLoading,
    newProject,
  } = state;

  useEffect(() => {
    let cleanup = false;
    if (newProject?.status === "running" && !cleanup) {
      setRunning(true);
    }
    return () => {
      cleanup = true;
    };
  }, [newProject?.status]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...newProject },
  });

  const handleNewMotive = () => {
    newDispatch(setHighLightMotive([...highlightMotive, inputValue]));
    newDispatch(setAddNewMotive(false));
  };

  if (editMotive.length >= 1) {
    const [editValue, index] = editMotive;
    highlightMotive[index] = editValue;

    newDispatch(setEditMotive([]));
  }

  if (deleteMotiveIndex !== null) {
    highlightMotive.splice(deleteMotiveIndex, 1);

    newDispatch(setDeleteMotiveIndex(null));
  }

  /* ====================== Save the data to the local state ===================== */
  const handleAddNewProject = (data) => {
    data.index = 0;
    data.motive = highlightMotive;
    if (running) {
      let { year, month } = getTimeDuration(data.startDate);
      data.status = "running";
      data.duration = year ? year + " " + month : month;
    } else {
      let { year, month } = getTimeDuration(data.startDate, data.endDate);
      data.duration = year ? year + " " + month : month;
      data.status = "complete";
    }

    newDispatch(newProjectData(data));
    navigate("/dashboard/addProject/imageUpload");
  };



  return (
    <section>
      {addProjectLoading ? (
        <Loading>Adding new project. Please wait..</Loading>
      ) : (
        <div>
          <form
            onSubmit={handleSubmit(handleAddNewProject)}
            className="lg:mt-20"
          >
            {/* ===================== Title ============================= */}

            <div className="relative mb-10 flex items-center gap-5">
              <h2 className="text-xl font-premium text-gray-800 flex font-medium">
                Title: <span className="text-red-600 ml-1 -mt-1">*</span>
              </h2>
              <input
                type="text"
                id="name"
                placeholder="Enter project title here"
                className="custom-text-input font-standard"
                {...register("title", { required: "Title is required" })}
              />
              {errors.title && (
                <p className="text-error">{errors.title?.message}</p>
              )}
            </div>

            {/* 
            {/* ============================== Description =============================*/}

            <div className="relative mb-10 flex flex-col gap-5">
              <div className="flex lg:flex-row flex-col lg:gap-5 gap-2">
                <h2 className="text-xl font-premium flex  text-gray-800 font-medium">
                  Description:{" "}
                  <span className="text-red-600 ml-1 -mt-1">*</span>
                </h2>
                <textarea
                  placeholder="Write project description"
                  className="custom-text-input font-standard"
                  cols="30"
                  rows="10"
                  {...register("description", {
                    required: "This field is required",
                  })}
                ></textarea>
                {errors.description && (
                  <p className="text-error">{errors.description?.message}</p>
                )}
              </div>
            </div>

            {/* ==================================== Motive ==============================*/}

            <div className="relative mb-6 flex flex-col gap-5">
              <h2 className="text-xl font-premium text-gray-800 font-medium underline underline-offset-2">
                Motives:
              </h2>

              {highlightMotive?.map((highlight, i) => (
                <EditTags
                  key={i}
                  highlight={highlight}
                  index={i}
                  newDispatch={newDispatch}
                  newDelete={setDeleteMotiveIndex}
                  newEdit={setEditMotive}
                ></EditTags>
              ))}

              {addNewMotive && (
                <div className="flex lg:flex-row flex-col items-center gap-5">
                  <textarea
                    onBlur={(e) => newDispatch(setInputValue(e.target.value))}
                    type="text"
                    cols="50"
                    rows="4"
                    className="custom-text-input font-standard"
                  />
                  <div className="flex lg:flex-col flex-row  items-center gap-3">
                    <span className="p-3 hover:scale-110 transition-transform duration-200 bg-gray-300 rounded-full">
                      <FaCheck
                        onClick={handleNewMotive}
                        className="text-lg text-gray-700"
                      />
                    </span>
                    <span className="p-3 hover:scale-110 transition-transform duration-200 bg-gray-300 rounded-full">
                      <FaTimes
                        onClick={() => newDispatch(setAddNewMotive(false))}
                        className="text-lg text-gray-700"
                      />
                    </span>
                  </div>
                </div>
              )}
              <div className="lg:my-5 my-7 flex gap-10">
                <CommonButton
                  type={"button"}
                  customClass={"!text-sm !py-2 tracking-[1px]"}
                  clickFunction={() => newDispatch(setAddNewMotive(true))}
                >
                  Add
                </CommonButton>
                <CommonButton
                  customClass={"!text-sm !py-2 tracking-[1px]"}
                  type={"button"}
                  clickFunction={() => newDispatch(setHighLightMotive([]))}
                >
                  Delete all
                </CommonButton>
              </div>
              {errors.motive && (
                <p className="text-error">{errors.motive?.message}</p>
              )}
            </div>

            {/* ==================== Starting and Ending Date ==============*/}

            <div className="flex flex-col  gap-7">
              <div className="relative mb-6 flex items-center w-full gap-5">
                <h2 className="text-xl w-1/4 font-premium flex text-gray-800 font-medium">
                  Starting Date:{" "}
                  <span className="text-red-600 ml-1 -mt-1">*</span>
                </h2>
                <input
                  type="date"
                  id="startDate"
                  className="custom-text-input w-3/4 font-standard"
                  {...register("startDate", {
                    required: "starting date is required",
                  })}
                />
                {errors.startDate && (
                  <p className="text-error">{errors.startDate?.message}</p>
                )}
              </div>
              <div className="mb-7">
                <label className="cursor-pointer mb-3 flex items-center gap-5">
                  <input
                    defaultChecked={newProject?.status === 'running'}
                    onClick={() => setRunning(!running)}
                    type="checkbox"
                    className="checkbox checkbox-success"
                  />
                  <span className="text-lg font-standard">
                    Are you still working on this project?
                  </span>
                </label>
                {!running  && (
                  <div className="relative mb-6 flex items-center w-full gap-5">
                    <h2 className="text-xl w-1/4 font-premium flex text-gray-800 font-medium">
                      Ending Date:{" "}
                      <span className="text-red-600 ml-1 -mt-1">*</span>
                    </h2>
                    <input
                      type="date"
                      id="endDate"
                      className="custom-text-input w-3/4 font-standard"
                      {...register("endDate", {
                        required: "ending date is required",
                      })}
                    />
                    {errors.endDate && (
                      <p className="text-error">{errors.endDate?.message}</p>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* ================================ Email & Phone ========================= */}

            <div className="flex lg:flex-row flex-col mb-7 lg:gap-10 gap-2">
              <div className="relative mb-6 flex items-center lg:w-1/2 gap-5">
                <h2 className="text-xl font-premium flex text-gray-800 font-medium">
                  Email: <span className="text-red-600 ml-1 -mt-1">*</span>
                </h2>
                <input
                  type="email"
                  placeholder="Enter your email address"
                  id="email"
                  className="custom-text-input font-standard"
                  {...register("email", { required: "Email is required" })}
                />
                {errors.email && (
                  <p className="text-error">{errors.email?.message}</p>
                )}
              </div>
              <div className="relative mb-6 flex items-center lg:w-1/2 gap-5">
                <h2 className="text-xl font-premium flex  text-gray-800 font-medium">
                  Phone: <span className="text-red-600 ml-1 -mt-1">*</span>
                </h2>
                <input
                  type="number"
                  placeholder="Enter phone number"
                  id="phone"
                  className="custom-text-input font-standard"
                  {...register("phone", { required: "Phone is required" })}
                />
                {errors.phone && (
                  <p className="text-error">{errors.phone?.message}</p>
                )}
              </div>
            </div>

            {/* ============================== Location ========================= */}
            <div className="relative mb-6 flex lg:flex-row flex-col lg:gap-5 gap-2">
              <h2 className="text-xl font-premium text-gray-800 font-medium">
                Location:{" "}
              </h2>
              <textarea
                type="text"
                placeholder="Enter contract location"
                id="location"
                rows="5"
                className="custom-text-input font-standard"
                {...register("location")}
              />
              {errors.location && (
                <p className="text-error">{errors.location?.message}</p>
              )}
            </div>

            <div className="w-full mt-10 text-right">
              <CommonButton
                clickFunction={() =>
                  window.scrollTo({ top: 0, behavior: "smooth" })
                }
                customClass={"tracking-[3px]"}
                type={"submit"}
              >
                Next
                <FaChevronRight className="ml-2" />
              </CommonButton>
            </div>
          </form>
        </div>
      )}
    </section>
  );
};

export default UploadProjectData;
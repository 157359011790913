import React from "react";
import { Link, useLoaderData } from "react-router-dom";
import CommonButton from "../../components/CommonButton/CommonButton";
import UseTitle from "../../Hooks/UseTitle/UseTitle";

const AllServices = () => {
  UseTitle("all-services");
  const services = useLoaderData();

  return (
    <section className="lg:py-32 py-20 lg:max-w-screen-xl w-[90%] mx-auto">
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-easier="ease-in-cubic"
        className="text-center mb-20"
      >
        <h1 className="lg:text-5xl text-4xl text-neutral font-semibold underline">
          My Services
        </h1>
      </div>

      <div className="grid lg:grid-cols-3 grid-cols-1 gap-7">
        {services.length > 0 &&
          services?.map((service, index) => (
            <div
              key={index}
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-easier="ease-in-cubic"
              className="card bg-base-100 shadow-xl rounded-md"
            >
              <div className="service-card">
                <figure className="h-64 rounded-t-md">
                  <img
                    src={service.thumbnail.url}
                    alt="service"
                    className="w-full h-full"
                  />
                </figure>
              </div>
              <div className="card-body pt-2 text-start">
                <h2 className="card-title text-2xl font-semibold text-primary">
                  {service.title}
                </h2>
                <p className="my-5">{service?.about?.slice(0, 120)}...</p>
                <Link
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                  to={`/service/${service._id}`}
                  className="card-actions justify-end"
                >
                  <CommonButton type={"button"}>Explore</CommonButton>
                </Link>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};

export default AllServices;

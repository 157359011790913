/** @format */

import React, { useContext } from "react";
import { FaTimes } from "react-icons/fa";
import facebook from "../../../assets/Login/facebook.ico";
import google from "../../../assets/Login/google.ico";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../Contexts/UserContext/UserContext";
import CommonButton from "../../../components/CommonButton/CommonButton";
import { useState } from "react";
import { toast } from "react-hot-toast";

const LoginModal = ({
  handleLogin,
  handleGoogleSignIn,
  handleFacebookSignIn,
  error,
  setError,
}) => {
  const {
    setLoginModal,
    autoModal,
    setAutoModal,
    setSignUpModal,
    resetPassword,
  } = useContext(AuthContext);
  const [email, setEmail] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toggleModal = () => {
    setLoginModal(false);
    setAutoModal(false);
  };

  const toggleSignUp = () => {
    setLoginModal(false);
    if (autoModal) {
      setAutoModal(false);
    }
    setSignUpModal(true);
  };

  // Reset password
  const handleResetPassword = () => {
    resetPassword(email)
      .then(() => {
        toast.success("A password reset mail has been sent to your account");
      })
      .catch((e) => setError(e.message));
  };

  return (
    <div>
      <input
        onClick={() => setLoginModal(true)}
        type="checkbox"
        id="loginModal"
        className="modal-toggle"
      />
      <div
        data-aos="zoom-in"
        data-aos-duration="200"
        data-aos-easing="ease-in-cubic"
        className="flex justify-center items-center fixed top-0 left-0 right-0 bottom-0 z-[999]"
      >
        <div className="modal-box bg-white lg:w-[450px] w-[400px]">
          <div className="pt-5 relative rounded-lg">
            <span className="absolute hover:bg-gray-400 rounded-full p-1 right-4 top-3">
              <FaTimes
                onClick={toggleModal}
                className="text-neutral text-xl"
              ></FaTimes>
            </span>
            <h2 className="text-center mb-8 lg:mb-14 text-5xl text-gray-900">
              Login
            </h2>
            <form
              onSubmit={handleSubmit(handleLogin)}
              className="lg:px-6 px-2 flex flex-col gap-4"
              action=""
            >
              <div>
                <label
                  className="text-gray-700 mb-1 font-medium"
                  htmlFor="name"
                >
                  Enter Your Email
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Type here"
                  className="custom-text-input !py-3"
                  {...register("email", { required: "Email is required" })}
                />
                {errors.email && (
                  <span className="text-error">{errors.email?.message}</span>
                )}
              </div>
              <div>
                <label
                  className="text-gray-700 mb-1 font-medium"
                  htmlFor="name"
                >
                  Enter Your password
                </label>
                <input
                  type="password"
                  name="password"
                  placeholder="Type here"
                  className="custom-text-input !py-3"
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
                {errors.password && (
                  <span className="text-error">{errors.password?.message}</span>
                )}
                <label
                  role="button"
                  className="text-gray-600 hover:text-primary underline text-sm"
                  htmlFor="forgotModal"
                >
                  Forgot Your password?
                </label>
              </div>
              <div className="divider my-0 font-medium">OR</div>
              {error && <span className="text-error text-center">{error}</span>}
              <div className="flex justify-center mb-0 gap-5">
                <img
                  className="w-9 h-9 cursor-pointer"
                  onClick={handleGoogleSignIn}
                  src={google}
                  alt=""
                />
                <img
                  className="w-9 h-9 cursor-pointer"
                  onClick={handleFacebookSignIn}
                  src={facebook}
                  alt=""
                />
              </div>
              <p className="text-base mt-3">
                Don't have any account? Please{" "}
                <span
                  onClick={toggleSignUp}
                  className="text-primary underline mx-2 text-lg font-medium cursor-pointer"
                >
                  register
                </span>
                here.
              </p>
              <CommonButton customClass={"!text-xl"} type={"submit"}>
                Login
              </CommonButton>
            </form>
          </div>
        </div>
      </div>

      {/* Forgot modal */}

      <input type="checkbox" id="forgotModal" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box py-5">
          <h2 className="mb-5 font-premium text-lg">
            Enter your email address here and we will send a password reset
            email to your email address.
          </h2>
          <input
            onBlur={(e) => setEmail(e.target.value)}
            type="email"
            className="custom-text-input"
            placeholder="Enter your email address"
          />
          <div className="modal-action gap-3">
            <CommonButton
              customClass={"btn-sm !px-6 !py-0 !h-10"}
              clickFunction={handleResetPassword}
            >
              <label htmlFor="forgotModal">Send</label>
            </CommonButton>
            <CommonButton customClass={"btn-sm !px-4 !py-0 !h-10"}>
              <label htmlFor="forgotModal">Cancel</label>
            </CommonButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;

/** @format */

import { editServiceActions } from "./EditServiceActions";

export const editServiceInitialState = {
  loading: false,
  error: false,
  service: {},
  errorMessage: "",
  highLights: [],
  addNewTag: false,
  newTagValue: [""],
  editTags: [],
  deleteTagIndex: [null],
  newGallery: [],
  deleteGalleryIndex: [null],
  updateService: {},
  updateLoading: false,
};



export const editServiceReducer = (state, action) => {
    

  switch (action.type) {
    case editServiceActions.FETCHING_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case editServiceActions.FETCHING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        service: action.payload,
        updateService: {...state.updateService, ...action.payload}
      };
    case editServiceActions.FETCHING_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case editServiceActions.SET_HIGHLIGHT:
      return {
        ...state,
        highLights: action.payload,
      };
    case editServiceActions.ADD_NEW_TAG:
      return {
        ...state,
        addNewTag: action.payload,
      };
    case editServiceActions.NEW_TAG_VALUE:
      return {
        ...state,
        newTagValue: action.payload,
      };
    case editServiceActions.EDIT_TAGS:
      return {
        ...state,
        editTags: action.payload,
      };
    case editServiceActions.DELETE_TAG_INDEX:
      return {
        ...state,
        deleteTagIndex: action.payload,
      };
    case editServiceActions.NEW_GALLERY:

      return {
        ...state,
        newGallery: [...state.newGallery,...action.payload],
      };
    case editServiceActions.DELETE_GALLERY_INDEX:
      return {
        ...state,
        deleteGalleryIndex: action.payload,
      };
    case editServiceActions.SET_UPDATE_SERVICE:
      return {
        ...state,
        updateService: action.payload,
      };
    case editServiceActions.SET_UPDATE_LOADING:
      return {
        ...state,
        updateLoading: action.payload,
      };

    default:
      return state;
  }
};

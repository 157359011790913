import { addServiceAction } from "./AddServiceActionTypes"


export const serviceLoading = (data) => {
    return {
        type: addServiceAction.SET_ADD_SERVICE_LOADING,
        payload: data
    }
};

export const newServiceData = (data) => {
    return {
        type: addServiceAction.SET_NEW_SERVICE,
        payload: data
    }
};

export const setEditTags = (data) => {
    return {
        type: addServiceAction.SET_EDIT_TAGS,
        payload: data
    }
};


export const setDeleteTagIndex = (data) => {
    return {
        type: addServiceAction.SET_DELETE_TAG_INDEX,
        payload: data
    }
};


export const setAddNewTag = (data) => {
    return {
        type: addServiceAction.SET_ADD_NEW_TAG,
        payload: data
    }
};

export const setHighLightTag = (data) => {
    return {
        type: addServiceAction.SET_HIGH_LIGHT_TAG,
        payload: data
    }
};

export const setInputValue = (data) => {
    return {
        type: addServiceAction.SET_INPUT_VALUE,
        payload: data
    }
};

export const setImageToGallery = (data) => {
    return {
        type: addServiceAction.SET_GALLERY_IMAGE,
        payload: data
    }
};

import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import HeadingText from "../../components/HeadingText/HeadingText";

const AllProjects = () => {

 const { data: projects = [], refetch } = useQuery({
   queryKey: ["projects"],
   queryFn: async () => {
     const response = await fetch(
       `${process.env.REACT_APP_SERVER_URL}/projects`
     );
     const data = await response.json();
     return data;
   },
 });

  return (
    <section id="projects" className="lg:max-w-screen-xl pt-32 w-[90%] mx-auto">
      <HeadingText
        heading={"Explore My Projects"}
        description={
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, totam saepe delectus omnis pariatur recusandae officiis, nostrum quos voluptas animi suscipit, sapiente at sit harum! Esse facilis quod repellat aliquid provident facere dolorem nihil id necessitatibus quasi porro, molestias minima?"
        }
      ></HeadingText>
      <div>
        {projects.length &&
          projects?.map(({ _id, thumbnail, description, title }, i) => (
            <div
              key={_id}
              className="flex gap-14 relative project-container items-center lg:pt-24 flex-col lg:mb-0 mb-10"
            >
              <div className="lg:w-1/2 w-full self-start mt-10">
                <figure className="lg:w-[480px] lg:min-h-[300px] w-[350px] ml-2 lg:mx-auto my-auto projectThumb relative">
                  <img
                    className="lg:w-[480px] lg:min-h-[300px]  w-[350px] ml-2 lg:ml-0 rounded-2xl"
                    src={thumbnail?.url}
                    alt=""
                  />
                </figure>
              </div>

              <div className="lg:w-1/2 lg:-mt-20 self-end w-full">
                <h2
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-easing="ease-in-cubic"
                  className="lg:text-5xl font-medium text-gray-700 text-4xl font-Bangla"
                >
                  {title}
                </h2>
                <p className="w-24 h-[2px] mb-10 ml-[8%] mt-2  bg-primary"></p>
                <p className="font-standard text-lg lg:mb-10 mb-3">
                  {description?.slice(0, 250)}...
                </p>
                <Link
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                  to={`/project/${_id}`}
                  className="font-premium text-lg text-primary font-medium tracking-widest hover:underline"
                >
                  Read more..
                </Link>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};

export default AllProjects;
/** @format */

import React, { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { BsReply } from "react-icons/bs";
import { AiOutlineSend } from "react-icons/ai";
import { MdOutlineCancel } from "react-icons/md";
import { useBlogs } from "./BlogDetailsAPI";
import { v4 } from "uuid";
import { RiDeleteBin7Line } from "react-icons/ri";
import UseAdmin from "../../Hooks/UseAdmin/UseAdmin";
import { AuthContext } from "../../Contexts/UserContext/UserContext";

const Reply = ({ commentId, user, uid }) => {
  const [reply, setReply] = useState(false);
  const [replyText, setReplyText] = useState("");
  const { user: currentUser } = useContext(AuthContext);

  const { id, refetch, deleteComment } = useBlogs();
  const [data] = UseAdmin(currentUser?.email);

  // Handle reply
  const handleReply = (e) => {
    e.preventDefault();

    const newReply = {
      message: replyText,
      id: v4(),
      time: new Date().toDateString(),
      commentId,
      user: currentUser.displayName,
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}/blog/${id}/reply/?commentId=${commentId}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(newReply),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.modifiedCount) {
          refetch();
          toast.success("Reply sent successfully");
          setReply(false);
        }
      });
  };

  return (
    <div>
      {reply ? (
        <form onSubmit={handleReply} className="" action="">
          <textarea
            onBlur={(e) => setReplyText(e.target.value)}
            className="border border-gray-600 block mb-6 p-5"
            cols="40"
            rows="4"
            placeholder={`Reply to ${user}`}
          ></textarea>
          <button type="submit" className="mr-5" title="send reply">
            <AiOutlineSend className="text-3xl text-primary" />
          </button>
          <button onClick={() => setReply(false)} title="cancel">
            <MdOutlineCancel className="text-3xl text-primary" />
          </button>
        </form>
      ) : (
        <p className="cursor-pointer flex items-center gap-2 hover:underline text-blue-600">
          {currentUser?.uid !== uid && (
            <BsReply
              onClick={() => setReply(true)}
              title="reply"
              className="text-2xl"
            />
          )}

          {(data.admin || currentUser?.uid === uid) && (
            <RiDeleteBin7Line
              onClick={() => deleteComment(commentId)}
              title="delete"
              className="text-xl"
            />
          )}
        </p>
      )}
    </div>
  );
};

export default Reply;


import React from "react";
import { useContext } from "react";
import { toast } from "react-hot-toast";
import { FaChevronLeft, FaPlus } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../../../../../components/CommonButton/CommonButton";
import { AuthContext } from "../../../../../Contexts/UserContext/UserContext";
import { projectLoading } from "../../../../../state/AddProjectReducer/AddProjectActionCreator";
import { ADD_PROJECT_CONTEXT } from "../AddNewProject";
import UploadProjectGallery from "./UploadProjectGallery";
import UploadProjectThumb from "./UploadProjectThumb";

const UploadProjectImage = () => {
  const navigate = useNavigate();
  const { logOut } = useContext(AuthContext);

  const {
    state: { newProject, storageId },
    newDispatch,
  } = useContext(ADD_PROJECT_CONTEXT);

  /* ============================  Handle submit data =========================== */
  const handleAddNewProject = () => {
    newDispatch(projectLoading(true));
    newProject.storageId = storageId;

    console.log(newProject);

    fetch(`${process.env.REACT_APP_SERVER_URL}/admin/project/newProject`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
      body: JSON.stringify(newProject),
    })
      .then((res) => {
        if (res.status === 401 || res.status === 403) {
          return logOut();
        }
        return res.json();
      })
      .then((data) => {
        if (data.acknowledged) {
          toast.success("New project added successfully");
          newDispatch(projectLoading(false));
          navigate(`/allProjects`);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <section>
      <div className="flex flex-col w-full gap-20">
        {/* ===================== Thumbnail ================= */}

        <UploadProjectThumb />

        {/* ========================= Gallery ==================== */}

        <UploadProjectGallery />
      </div>
      <div className="flex justify-between items-center my-20">
        <Link to="/dashboard/addProject">
          <CommonButton
            clickFunction={() =>
              window.scrollTo({ top: 0, behavior: "smooth" })
            }
            type={"button"}
          >
            <FaChevronLeft className="mr-2" />
            Previous
          </CommonButton>
        </Link>
        <CommonButton type={"submit"} clickFunction={handleAddNewProject}>
          Add Project
          <FaPlus className="ml-2" />
        </CommonButton>
      </div>
    </section>
  );
};

export default UploadProjectImage;

import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useContext, useState } from "react";
import { FaCircle, FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../Contexts/UserContext/UserContext";
import Loading from "../../../../components/Loading/Loading";
import { toast } from "react-hot-toast";
import { deleteImage } from "../../../../utilities/deleteImage";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";
import CommonButton from "../../../../components/CommonButton/CommonButton";

const ManageProjects = () => {
  const { logOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const [deleteProject, setDeleteProject] = useState([]);

  /* ============================= GET ALL PROJECT ===================== */
  const {
    data: projects = [],
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["projects"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/projects`);
      const data = await response.json();
      return data;
    },
  });

  if (isLoading) {
    return <Loading>Loading..</Loading>;
  }

  const runningProject = projects.filter(
    (project) => project.status === "running"
  );
  const completeProject = projects.filter(
    (project) => project.status === "complete"
  );

  const navigateWithId = (id) => {
    localStorage.setItem("ProjectId", id);
    navigate(`/dashboard/projects/${id}`);
  };

  /* ============================= DELETE SERVICE ========================== */
  const handleDelete = () => {
    const [id, storageId] = deleteProject;

    let imgLocations = [];
    const findProject = projects.find((project) => project._id === id);
    const { thumbnail, gallery } = findProject;

    imgLocations.push(thumbnail.imageId);

    if (gallery?.length > 0) {
      gallery.forEach((data) => {
        imgLocations.push(data.imageId);
      });
    }

    imgLocations.forEach((location) => {
      deleteImage("Projects", storageId, location);
    });

    fetch(`${process.env.REACT_APP_SERVER_URL}/admin/deleteProject/${id}`, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    })
      .then((res) => {
        if (res.status === 401 || res.status === 403) {
          return logOut();
        }
        return res.json();
      })
      .then((data) => {
        if (data.acknowledged) {
          refetch();
          toast.success("Project deleted successfully");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <section>
      <h2 className="text-4xl font-stylish font-semibold mt-16">My Projects</h2>
      <p className="w-24 h-1 mb-16  mt-2 ml-10  bg-primary"></p>
      <div className="flex items-center lg:flex-row flex-col gap-5 lg:gap-0 justify-between mb-10 ">
        <div className="flex gap-5 items-center">
          <p className="text-lg">
            Total Projects :{" "}
            <span className="text-2xl font-semibold">{projects.length}</span>
          </p>
          <p className="text-lg">
            Running:{" "}
            <span className="text-2xl text-green-700 font-semibold">
              {runningProject.length}
            </span>
          </p>
          <p className="text-lg">
            Completed:{" "}
            <span className="text-2xl text-blue-600 font-semibold">
              {completeProject.length}
            </span>
          </p>
        </div>
        <div>
          <Link to="/dashboard/addProject">
            <CommonButton>
              Add new Project
              <FaPlus className="ml-2" />
            </CommonButton>
          </Link>
        </div>
      </div>

      <div>
        {projects.length &&
          projects?.map(
            (
              {
                _id,
                thumbnail,
                title,
                description,
                status,
                email,
                phone,
                location,
                storageId,
              },
              i
            ) => (
              <div key={i} className="flex items-start lg:gap-5 gap-2">
                <h2 className="text-2xl font-semibold">{i + 1}</h2>
                <div
                  key={_id}
                  className="flex w-screen lg:flex-row flex-col bg-gray-50 items-center gap-0 lg:gap-4 justify-between mb-8 rounded-lg  p-3 shadow-2xl"
                >
                  <figure className="lg:w-[20%] mx-auto">
                    <img
                      className="rounded-xl lg:h-[150px] bg-white lg:w-full w-[350px] h-[200px]"
                      src={thumbnail?.url}
                      alt=""
                    />
                  </figure>
                  <div className="lg:w-1/2 pb-0">
                    <h2 className="text-2xl font-Bangla tracking-[2px] text-gray-800 mb-3">
                      {title}
                    </h2>
                    <p className="text-base font-standard text-gray-600">
                      {description?.slice(0, 100)}...
                    </p>
                    <div className="lg:mt-3 my-8 flex  items-center justify-center lg:justify-start lg:my-3">
                      <p
                        title="edit service"
                        onClick={() => navigateWithId(_id)}
                        className="hover:bg-gray-300 hover:scale-105 cursor-pointer transition-all duration-300  grid place-items-center rounded-full w-12 h-12"
                      >
                        <FaEdit className="text-xl" />
                      </p>
                      <label
                        title="delete service"
                        onClick={() => setDeleteProject([_id, storageId])}
                        htmlFor="confirmModal"
                        className="hover:bg-gray-300 hover:scale-95 cursor-pointer transition-all duration-300 mr-8 grid place-items-center rounded-full w-12 h-12"
                      >
                        <FaTrash className="text-xl" />
                      </label>
                      {status === "running" ? (
                        <p className="flex items-center gap-2">
                          <FaCircle className="text-green-700 text-sm" />
                          <span className="font-medium font-premium text-lg mr-10">
                            Running
                          </span>
                        </p>
                      ) : (
                        <p className="flex items-center gap-2">
                          <BsFillCheckCircleFill className="text-blue-600 text-xl" />
                          <span className="font-medium font-premium text-lg mr-10">
                            Completed
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="lg:w-[30%] text-center">
                    <p className="text-lg font-medium font-premium text-gray-800">
                      {email}
                    </p>
                    <p className="font-premium">{phone}</p>
                    <p className="font-standard">{location?.slice(0, 50)}...</p>
                  </div>
                </div>
              </div>
            )
          )}
      </div>
      <ConfirmationModal
        actionText={"Delete"}
        modalText={"Are you sure you want to delete this project?"}
        action={handleDelete}
      ></ConfirmationModal>
    </section>
  );
};

export default ManageProjects;
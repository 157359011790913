
/** @format */

import React from "react";
import { useState } from "react";
import { FaStar } from "react-icons/fa";
import { MdNotInterested } from "react-icons/md";

const Star = ({ rating, setRating }) => {
  const [hover, setHover] = useState(null);

  return (
    <div className="flex items-center gap-2">
      <MdNotInterested
        className="text-gray-500 lg:text-3xl text-2xl"
        title="no review"
        onClick={() => setRating(null)}
      />
      {[...Array(5)].map((star, i) => {
        const ratingValue = i + 1;

        return (
          <label key={i}>
            <input
              type="radio"
              name="rating"
              className="hidden"
              value={ratingValue}
              onClick={() => setRating(ratingValue)}
            />
            <FaStar
              title={`${ratingValue} star`}
              className={`lg:text-3xl text-2xl ${
                ratingValue <= (hover || rating)
                  ? "text-orange-400 transition-colors duration-200"
                  : "text-gray-500"
              }`}
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(null)}
            />
          </label>
        );
      })}
    </div>
  );
};

export default Star;

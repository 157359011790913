/** @format */

import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../pages/Shared/Navbar/Navbar";
import {
  FaHome,
  FaUserAlt,
  FaHandHoldingHeart,
  FaBlogger,
  FaProjectDiagram,
  FaChevronRight,
} from "react-icons/fa";
import UseAdmin from "../Hooks/UseAdmin/UseAdmin";
import { useContext } from "react";
import { AuthContext } from "../Contexts/UserContext/UserContext";
import { NavLink } from "react-router-dom";

const DashboardLayout = () => {
  const { user } = useContext(AuthContext);
  const [data] = UseAdmin(user?.email);

  const activeClass =
    "bg-gray-800 text-accent px-3 lg:w-3/5 w-full lg:py-2 py-1 lg:rounded-r-full shadow-2xl transition-all duration-500 text-lg lg:border-l-4 border-accent flex items-center gap-2 font-medium";

  const inActiveClass =
    "flex items-center gap-2 w-3/5 px-3 lg:px-0 font-medium lg:py-2 py-1 pr-3 lg:pr-0 hover:text-accent text-gray-700 lg:border-r-0 border-r-2 lg:border-l-4 border-base-100";

  return (
    <section>
      <Navbar></Navbar>
      <div className="rounded-xl max-w-7xl mx-auto my-28 pt-10 lg:pl-10 ">
        <div className=" w-11/12 mx-auto lg:w-full flex lg:flex-row flex-col">
          <div className=" lg:w-1/4 w-full">
            <div className="flex gap-4">
              <span>
                <img
                  className="h-12 w-12 rounded-full"
                  src={user?.photoURL}
                  alt=""
                />
              </span>
              <p className="flex flex-col">
                <span className="text-2xl font-semibold">
                  {user?.displayName}
                </span>
                <span className="font-medium text-secondary">
                  {(data.type === "admin" && "Admin") ||
                    (data.type === "super-admin" && "Super Admin")}
                </span>
              </p>
            </div>

            <div className="breadcrumbs mx-5 lg:mx-0">
              <ul className="flex lg:flex-col  flex-row mt-10 mb-5 lg:mb-0 lg:gap-8 gap-5  rounded-3xl">
                {data.admin && (
                  <>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? `${activeClass}` : `${inActiveClass}`
                      }
                      to="/dashboard/dashboard"
                    >
                      <span className="bg-gray-800 p-2 rounded-full">
                        <FaHome className="text-xl text-gray-300" />
                      </span>
                      Dashboard
                      <span>
                        <FaChevronRight />
                      </span>
                    </NavLink>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? `${activeClass}` : `${inActiveClass}`
                      }
                      to="/dashboard/manageServices"
                    >
                      <span className="bg-gray-800 p-2 rounded-full">
                        <FaHandHoldingHeart className="text-xl text-gray-300" />
                      </span>
                      Services
                      <span>
                        <FaChevronRight />
                      </span>
                    </NavLink>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? `${activeClass}` : `${inActiveClass}`
                      }
                      to="/dashboard/manageProjects"
                    >
                      <span className="bg-gray-800 p-2 rounded-full">
                        <FaProjectDiagram className="text-xl text-gray-300" />
                      </span>
                      Projects
                      <span>
                        <FaChevronRight />
                      </span>
                    </NavLink>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? `${activeClass}` : `${inActiveClass}`
                      }
                      to="/dashboard/manageBlogs"
                    >
                      <span className="bg-gray-800 p-2 rounded-full">
                        <FaBlogger className="text-xl text-gray-300" />
                      </span>
                      Blogs
                      <span>
                        <FaChevronRight />
                      </span>
                    </NavLink>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? `${activeClass}` : `${inActiveClass}`
                      }
                      to="/dashboard/manageUsers"
                    >
                      <span className="bg-gray-800 p-2 rounded-full">
                        <FaUserAlt className="text-xl text-gray-300" />
                      </span>
                      Users
                      <span>
                        <FaChevronRight />
                      </span>
                    </NavLink>
                  </>
                )}
              </ul>
            </div>
          </div>
          <div className="lg:w-3/4">
            <Outlet></Outlet>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardLayout;

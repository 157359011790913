/** @format */

import { useQuery } from "@tanstack/react-query";
import React, { useContext } from "react";
import { toast } from "react-hot-toast";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { FaUserAlt } from "react-icons/fa";
import Loading from "../../../components/Loading/Loading";
import { AuthContext } from "../../../Contexts/UserContext/UserContext";
import UseAdmin from "../../../Hooks/UseAdmin/UseAdmin";

const ManageUsers = () => {
  const { logOut, user } = useContext(AuthContext);
  const [data] = UseAdmin(user?.email);

  /* =============== GET USERS =================== */
  const {
    data: users = [],
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/admin/users`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );

      if (response.status === 401 || response.status === 403) {
        return logOut();
      }

      const data = await response.json();
      return data;
    },
  });

  /* =========================== MAKE ADMIN ======================= */
  const handleMakeAdmin = (uid) => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/admin/user/makeAdmin`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
      body: JSON.stringify({ uid }),
    })
      .then((res) => {
        if (res.status === 401 || res.status === 403) {
          return logOut();
        }
        return res.json();
      })
      .then((data) => {
        if (data.acknowledged) {
          refetch();
          toast.success("New admin created");
        }
      });
  };

  /* =========================== MAKE USER ======================= */
  const handleMakeUser = (uid) => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/admin/user/makeUser`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
      body: JSON.stringify({ uid }),
    })
      .then((res) => {
        if (res.status === 401 || res.status === 403) {
          return logOut();
        }
        return res.json();
      })
      .then((data) => {
        if (data.acknowledged) {
          refetch();
          toast.success("User role updated");
        }
      });
  };

  /* =========================== DELETE USER ======================= */
  const handleDeleteUser = (uid) => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/admin/user/deleteUser`, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
      body: JSON.stringify({ uid }),
    })
      .then((res) => {
        if (res.status === 401 || res.status === 403) {
          return logOut();
        }
        return res.json();
      })
      .then((data) => {
        if (data.acknowledged) {
          refetch();
          toast.success("User deleted successfully");
        }
      });
  };

  if (isLoading) {
    return <Loading>Loading...</Loading>;
  }

  return (
    <section>
      <h2 className="text-5xl mt-10 font-stylish">Manage users</h2>
      <p className="w-28 h-1 mb-16 lg:ml-[10%] mt-2 ml-[20%]  bg-primary"></p>
      <h3 className="text-2xl font-standard mb-5">
        Total user: <span>{users.length}</span>
      </h3>
      <div className="grid lg:grid-cols-3 grid-cols-2 lg:gap-5 gap-3">
        {users?.length > 0 ? (
          users?.map((singleUser, i) => (
            <div
              key={i}
              className={`${
                singleUser.uid === user.uid ? "bg-accent" : ""
              } "text-center lg:px-5 px-1 py-3 relative shadow-2xl rounded-xl"`}
            >
              <figure className="lg:w-28 w-20 mx-auto rounded-full overflow-hidden lg:h-28 h-20">
                {singleUser?.image === null ? (
                  <FaUserAlt className="rounded-full transition-all duration-300 hover:scale-110  lg:h-28 h-16 lg:w-28 w-16" />
                ) : (
                  <img
                    src={singleUser?.image}
                    className="rounded-full transition-all duration-300 hover:scale-110  lg:h-28 h-16 lg:w-28 w-16 mx-auto"
                    alt=""
                  />
                )}
              </figure>
              <h3 className="font-stylish lg:mt-5 text-2xl font-semibold">
                {singleUser.name}
              </h3>
              <p className="font-premium lg:text-lg text-base tracking-wider text-gray-700">
                {singleUser.email}
              </p>
              <span className="bg-secondary  px-2 rounded-2xl absolute -top-2 -left-2 font-premium text-accent text-lg">
                {(singleUser.type === "admin" && "Admin") ||
                  (singleUser.type === "super-admin" && "Super Admin") ||
                  (singleUser.type !== "admin" &&
                    singleUser.type !== "super-admin" &&
                    "User")}
              </span>
              <div className="absolute right-2 top-3">
                <div className="dropdown dropdown-left drop-shadow-2xl">
                  {singleUser.uid !== user.uid &&
                    data.type !== singleUser.type &&
                    singleUser.type !== "super-admin" && (
                      <label tabIndex={0} className="">
                        <BiDotsVerticalRounded
                          size={30}
                          className=" text-primary  cursor-pointer hover:shadow-2xl"
                        />
                      </label>
                    )}

                  <ul
                    tabIndex={0}
                    className="dropdown-content px-2 py-3 shadow bg-base-100 rounded-xl lg:w-44 w-40"
                  >
                    {/* ======================= SUPER ADMIN ====================== */}
                    {data.type === "super-admin" &&
                      singleUser.type &&
                      singleUser.type !== "super-admin" && (
                        <li
                          onClick={() => handleMakeUser(singleUser.uid)}
                          className="font-premium hover:bg-opacity-30 text-lg transition-colors duration-200 tracking-wide text-gray-600 hover:text-neutral mb-3 rounded-md hover:bg-secondary"
                        >
                          Make User
                        </li>
                      )}
                    {(data.type === "super-admin" || data.type === "admin") &&
                      !singleUser.type &&
                      singleUser.type !== "super-admin" && (
                        <li
                          onClick={() => handleMakeAdmin(singleUser.uid)}
                          className="font-premium hover:bg-opacity-30 text-lg transition-colors duration-200 tracking-wide text-gray-600 hover:text-neutral mb-3 rounded-md hover:bg-secondary"
                        >
                          Make Admin
                        </li>
                      )}
                    {data.type === "super-admin" &&
                      singleUser.type !== "super-admin" && (
                        <li
                          onClick={() => handleDeleteUser(singleUser.uid)}
                          className="font-premium hover:bg-opacity-30 text-lg transition-colors duration-200 tracking-wide text-gray-600 hover:text-neutral mb-3 rounded-md hover:bg-secondary"
                        >
                          Delete
                        </li>
                      )}

                    {/* =======================  ADMIN ====================== */}
                    {data.type === "admin" &&
                      !singleUser.type &&
                      singleUser.type !== "super-admin" && (
                        <li
                          onClick={() => handleDeleteUser(singleUser.uid)}
                          className="font-premium hover:bg-opacity-30 text-lg transition-colors duration-200 tracking-wide text-gray-600 hover:text-neutral mb-3 rounded-md hover:bg-secondary"
                        >
                          Delete
                        </li>
                      )}
                  </ul>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>
            <h1 className="text-3xl font-standard font-medium">
              No user found..
            </h1>
          </div>
        )}
      </div>
    </section>
  );
};

export default ManageUsers;

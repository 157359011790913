

import React from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";

const ShowGallery = ({ gallery, customClass }) => {
  return (
    <div className={`lg:max-w-screen-lg w-[90%]  mx-auto my-[20%] ${customClass && customClass}`}>
      {gallery?.length && (
        <div className="my-24">
          <h1 className="lg:text-5xl text-4xl font-medium font-stylish">
            Photo Gallery
          </h1>
          <p className="w-28 h-1 mb-16 lg:ml-[8%] mt-2 ml-[15%]  bg-accent"></p>
          <PhotoProvider
            speed={() => 800}
            easing={(type) =>
              type === 2
                ? "cubic-bezier(0.36, 0, 0.66, -0.56)"
                : "cubic-bezier(0.34, 1.56, 0.64, 1)"
            }
          >
            <div className="lg:columns-3 columns-2 gap-5">
              {gallery?.map(({ url }, index) => (
                <PhotoView key={index} src={url}>
                  <img
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-cubic"
                    className="mb-3 w-full cursor-pointer"
                    src={url}
                    alt=""
                  />
                </PhotoView>
              ))}
            </div>
          </PhotoProvider>
        </div>
      )}
    </div>
  );
};

export default ShowGallery;
/** @format */

export const editServiceActions = {
  FETCHING_START: "FETCHING_START",
  FETCHING_SUCCESS: "FETCHING_SUCCESS",
  FETCHING_ERROR: "FETCHING_ERROR",
  SET_HIGHLIGHT: "SET_HIGHLIGHT",
  ADD_NEW_TAG: "ADD_NEW_TAG",
  NEW_TAG_VALUE: "NEW_TAG_VALUE",
  EDIT_TAGS: "EDIT_TAGS",
  DELETE_TAG_INDEX: "DELETE_TAG_INDEX",
  DELETE_GALLERY_INDEX: "DELETE_GALLERY_INDEX",
  NEW_GALLERY: "NEW_GALLERY",
  SET_UPDATE_SERVICE: "SET_UPDATE_SERVICE",
  SET_UPDATE_LOADING: "SET_UPDATE_LOADING",
 
};


import React, { useContext } from "react";
import { toast } from "react-hot-toast";
import { FaChevronLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../../../../components/CommonButton/CommonButton";
import Loading from "../../../../../components/Loading/Loading";
import { AuthContext } from "../../../../../Contexts/UserContext/UserContext";
import { handleUpdateLoading } from "../../../../../state/EditProjectReducer/EditProjectActionCreator";
import { EDIT_PROJECT_CONTEXT } from "../EditProject";
import EditProjectGallery from "./EditProjectGallery";
import EditProjectThumb from "./EditProjectThumb";


const EditProjectImage = () => {
  const { state, editDispatch } = useContext(EDIT_PROJECT_CONTEXT);
  const projectId = localStorage.getItem("ProjectId");

  const navigate = useNavigate();
  const { logOut } = useContext(AuthContext);

  const {
    loading,
    error,
    errorMessage,
    newGallery,
    updateProject,
    updateLoading,
  } = state;

  if (loading) {
    return <Loading>Loading...</Loading>;
  }
  if (error) {
    return (
      <p className="text-center my-52 text-2xl tracking-widest text-error font-semibold ">
        {errorMessage}
      </p>
    );
  }

  /* ======================Submit updated Project to the db ============================= */
  const handleUpdateProject = () => {
    editDispatch(handleUpdateLoading(true));
    delete updateProject._id;
    updateProject.gallery = newGallery;

    fetch(
      `${process.env.REACT_APP_SERVER_URL}/admin/project/${projectId}/update`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
        },
        body: JSON.stringify(updateProject),
      }
    )
      .then((res) => {
        if (res.status === 401 || res.status === 403) {
          return logOut();
        }
        return res.json();
      })
      .then((data) => {
        if (data.acknowledged) {
          editDispatch(handleUpdateLoading(false));
          toast.success("Your project has been modified successfully");
          navigate(`/project/${projectId}`);
          localStorage.removeItem("ProjectId");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <section>
      {updateLoading ? (
        <Loading>Updating your Project. Please wait..</Loading>
      ) : (
        <div>
          {/* =============================== Thumbnail ============================== */}

          <EditProjectThumb></EditProjectThumb>

          {/* ==================================== Gallery ============================ */}

          <EditProjectGallery></EditProjectGallery>

          <div className="flex justify-between items-center mt-10">
            <Link to={`/dashboard/projects/${projectId}`}>
              <CommonButton type={"button"} customClass={"tracking-[3px]"}>
                <FaChevronLeft className="mr-3" />
                Previous
              </CommonButton>
            </Link>
            <CommonButton
              type={"submit"}
              clickFunction={handleUpdateProject}
              customClass={"tracking-[3px]"}
            >
              Update project
            </CommonButton>
          </div>
        </div>
      )}
    </section>
  );
};

export default EditProjectImage;
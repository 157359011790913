
import  { useEffect, useState } from 'react';

const UseDeviceWidth = () => {
   const [mobile, setMobile] = useState(false);

  useEffect(() => {
    //add a listener for changes to the screen size
    const mediaQuery = window.matchMedia("(max-width: 500px)");

    // set the initial value of the 'isMobile' state variable
    setMobile(mediaQuery.matches);

    // Define a callback function to handle changes to the media query
    const handleMediaQueryChange = (event) => {
      setMobile(event.matches);
    };

    // Add the callback function as a listener for changes to the media query
    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

   return mobile;
};

export default UseDeviceWidth;

import React, { useContext } from 'react';
import { toast } from 'react-hot-toast';
import { FaChevronLeft, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import CommonButton from '../../../../../components/CommonButton/CommonButton';
import { AuthContext } from '../../../../../Contexts/UserContext/UserContext';
import { serviceLoading } from '../../../../../state/AddServiceReducer/AddServiceActionCreator';
import { ADD_SERVICE_CONTEXT } from '../AddNewService';
import UploadServiceGallery from './UploadServiceGallery';
import UploadServiceThumb from './UploadServiceThumb';

const UploadServiceImage = () => {
  const navigate = useNavigate();
  const { logOut } = useContext(AuthContext);

  const {
    state: { newService, storageId },
    newDispatch,
  } = useContext(ADD_SERVICE_CONTEXT);

  /* ============================  Handle submit data =========================== */
  const handleAddNewService = () => {
    newDispatch(serviceLoading(true));
    newService.storageId = storageId;
    newService.reviews = [];
    newService.allRatings = [];
    newService.ratings = {
      sumOfRatings: 0,
      ratingPercent: 0,
    };

    fetch(`${process.env.REACT_APP_SERVER_URL}/admin/service/newService`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
      body: JSON.stringify(newService),
    })
      .then((res) => {
        if (res.status === 401 || res.status === 403) {
          return logOut();
        }
        console.log(res);
        return res.json();
      })
      .then((data) => {
        console.log(data);
        if (data.acknowledged) {
          toast.success("New service added successfully");
          newDispatch(serviceLoading(false));
          navigate(`/allServices`);
        }
      })
      .catch((err) => console.log(err));
  };

    return (
      <section>
        <div className="flex flex-col w-full gap-20">
          {/* ===================== Thumbnail ================= */}

          <UploadServiceThumb />

          {/* ========================= Gallery ==================== */}

          <UploadServiceGallery />
        </div>
        <div className="flex justify-between items-center my-20">
          <Link to="/dashboard/addService">
            <CommonButton
              clickFunction={() =>
                window.scrollTo({ top: 0, behavior: "smooth" })
              }
              type={"button"}
            >
              <FaChevronLeft className="mr-2" />
              Previous
            </CommonButton>
          </Link>
          <CommonButton type={"submit"} clickFunction={handleAddNewService}>
            Add service
            <FaPlus className="ml-2" />
          </CommonButton>
        </div>
      </section>
    );
};

export default UploadServiceImage;

import React, { useContext } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { DASHBOARD_CONTEXT } from "../DashboardAPI";

const BlogView = () => {

    const { BLOGS_VIEW } = useContext(DASHBOARD_CONTEXT);
    
    return (
      <section>
        <h1 className="lg:text-4xl text-3xl font-premium mb-10">
          Blog Analytics
        </h1>
        <div className="flex lg:flex-row overflow-hidden flex-col-reverse gap-5">
          <div className="lg:w-2/3 w-[400px] h-[200px] mt-10 overflow-hidden">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={200}
                data={BLOGS_VIEW}
                syncId="anyId"
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="value" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="count"
                  stroke="#8884d8"
                  fill="#8884d8"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="lg:w-2/6 flex lg:flex-col mb-10 flex-row lg:gap-5 gap-3 w-full">
            {BLOGS_VIEW?.map(({ value, count }, i) => (
              <div
                key={i}
                className="lg:w-3/4 w-full py-3 relative serviceView rounded-2xl text-center shadow-2xl"
              >
                <h3 className="lg:text-xl text-base lg:mb-3 font-standard text-gray-800">
                  {value}
                </h3>
                <p className="text-3xl font-stylish font-bold text-primary">
                  {count}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
};

export default BlogView;
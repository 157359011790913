/** @format */

import { addServiceAction } from "./AddServiceActionTypes";

export const addServiceInitialState = {
  addNewTag: false,
  highlightTag: [],
  inputValue: "",
  deleteTagIndex: null,
  editTags: [],
  addServiceLoading: false,
  newService: {},
  storageId: null,
};

export const addServiceReducer = (state, action) => {
  switch (action.type) {
    case addServiceAction.SET_ADD_NEW_TAG:
      return {
        ...state,
        addNewTag: action.payload,
      };
    case addServiceAction.SET_HIGH_LIGHT_TAG:
      return {
        ...state,
        highlightTag: action.payload,
      };
    case addServiceAction.SET_INPUT_VALUE:
      return {
        ...state,
        inputValue: action.payload,
      };
    case addServiceAction.SET_DELETE_TAG_INDEX:
      return {
        ...state,
        deleteTagIndex: action.payload,
      };
    case addServiceAction.SET_EDIT_TAGS:
      return {
        ...state,
        editTags: action.payload,
      };
    case addServiceAction.SET_NEW_SERVICE:
      return {
        ...state,
        newService: { ...state.newService, ...action.payload },
      };
    case addServiceAction.SET_GALLERY_IMAGE:
      return {
        ...state,
        newService: { ...state.newService, gallery: [...action.payload] },
      };
    case addServiceAction.SET_STORAGE_ID:
      return {
        ...state,
        storageId: action.payload,
      };
    default:
      return state;
  }
};


/** @format */

export const getTimeDuration = (sDate, eDate) => {
  let endingDate;
  if (eDate) {
    endingDate = new Date(eDate);
  } else {
    endingDate = new Date();
  }

  let startingDate = new Date(sDate);
  let diffMs = endingDate - startingDate; // milliseconds between now & Christmas
  let diffDays = Math.floor(diffMs / 86400000); // days

  let year;
  let month;
  if (diffDays > 365) {
    year = (diffDays / 365).toFixed() + " " + "year";
    month = ((diffDays % 365) / 30).toFixed() + " " + "months";
  } else {
    year = null;
    month = (diffDays / 30).toFixed() + " " + "months";
  }

  return { year, month };
};

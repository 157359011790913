
import React from "react";
import { useReducer } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import { Outlet } from "react-router-dom";
import { v4 } from "uuid";
import { addProjectAction } from "../../../../state/AddProjectReducer/AddProjectActionTypes";
import { addProjectInitialState, addProjectReducer } from "../../../../state/AddProjectReducer/AddProjectReducer";


export const ADD_PROJECT_CONTEXT = createContext();
const AddNewProject = () => {

const [state, newDispatch] = useReducer(
  addProjectReducer,
  addProjectInitialState
);

useEffect(() => {
  newDispatch({ type: addProjectAction.SET_STORAGE_ID, payload: v4() });
}, []);

const data = {
  state,
  newDispatch,
};


    return (
      <section>
        <h2 className="text-gray-800 font-stylish mt-10 text-4xl font-semibold">
          Add new Project
        </h2>
        <p className="w-28 h-1 mb-16 lg:ml-[7%] mt-2 ml-[17%]  bg-primary"></p>

        <div className="flex flex-col gap-10">
          <div>
            <ADD_PROJECT_CONTEXT.Provider value={data}>
              <Outlet></Outlet>
            </ADD_PROJECT_CONTEXT.Provider>
          </div>
        </div>
      </section>
    );
};

export default AddNewProject;
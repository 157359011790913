
import React from 'react';
import { useState, Fragment } from "react";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import UseDeviceWidth from '../../../Hooks/UseDevice/UseDeviceWidth';
import HeadingText from '../../../components/HeadingText/HeadingText';

const MySkills = () => {

    const mobile = UseDeviceWidth();
    const skills = [
      {
        id: 1,
        title: "Marketing, sales and customer service",
        description:
          "It is important to be able to promote your products or services effectively. Providing good customer service and having a marketing strategy in place will help you to generate sales. These skills work together to deliver exceptional results and exceed customer expectations. From crafting compelling campaigns to closing deals and providing exceptional support, I am passionate about delivering the highest level of service to help businesses thrive.",
        animate: "fade-left",
      },
      {
        id: 2,
        title: "Communication and negotiation",
        description:
          "As a professional with extensive experience in communication and negotiation, I understand the nuances and complexities of these skills. I know how to actively listen, express myself clearly and persuasively, and adapt my communication style to different audiences. When it comes to negotiation, I am skilled in identifying common ground, finding creative solutions, and building trust with my counterparts.",
        animate: "fade-right",
      },
      {
        id: 3,
        title: "Leadership",
        description:
          "Leadership is not just about giving orders and delegating tasks but about building trust, fostering collaboration, and nurturing talent. Through effective communication, mentorship, and feedback, I strive to help individuals grow and develop in their careers, while also achieving organizational goals. I believe that everyone has unique strengths and perspectives to offer, and it's my role as a leader to create an environment where everyone can thrive and contribute to their fullest potential.",
        animate: "fade-left",
      },
      {
        id: 4,
        title: "Project management and planning",
        description:
          "From defining project scope to identifying key milestones, allocating resources, and managing budgets, I am well-versed in the technical aspects of project management. But beyond that, I believe that successful project management is also about building strong relationships, inspiring teamwork, and fostering a positive work culture. I take a collaborative and agile approach to project management, encouraging input from all stakeholders and adapting to changing circumstances as needed.",
        animate: "fade-right",
      },
      {
        id: 5,
        title: "Networking",
        description:
          "Networking is not just about collecting business cards or making connections on LinkedIn; it's about building meaningful relationships with people who share similar interests, goals, and values. By being authentic, approachable, and helpful, I strive to create a positive impression on everyone I meet, whether it's at a conference, a social gathering, or an online forum.  I am passionate about mentoring young professionals, volunteering for nonprofit organizations, and sharing my knowledge and expertise with others.",
        animate: "fade-left",
      },
    ];


    const [open, setOpen] = useState(1);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    const customAnimation = {
        mount: { scale: 1 },
        unmount: { scale: 0.9 },
    };

    return (
      <section id="skill" className="bg-white">
        <div className="max-w-screen-xl mx-auto py-28">
          <HeadingText
            heading={"Mastery"}
            headingClass={"text-gray-800"}
            descriptionClass={"text-gray-600"}
            description={
              "In my career, I have honed a wide range of skills that have enabled me to excel in my field. I pride myself on my ability to think critically, problem-solve, and approach challenges with a creative and innovative mindset. I am a skilled communicator and collaborator, able to work effectively with individuals and teams from diverse backgrounds and cultures. My ability to build strong relationships with clients, colleagues, and stakeholders has been critical to my success."
            }
          ></HeadingText>
          <div className="lg:max-w-screen-xl w-[90%] mx-auto">
            <Fragment>
              {skills.map(({ id, animate, title, description }) => (
                <Accordion
                  key={id}
                  data-aos={mobile ? "fade-up" : `${animate}`}
                  data-aos-duration="1500"
                  data-easing="ease-in-cubic"
                  className="mb-4"
                  open={open === id}
                  animate={customAnimation}
                >
                  <AccordionHeader
                    className="bg-primary lg:pl-[20%] text-gray-200 rounded-full hover:scale-95 py-2 transition-all font-light ease-out duration-700 text-xl lg:text-xl tracking-widest font-premium  px-5 hover:text-white mb-3"
                    onClick={() => handleOpen(id)}
                  >
                    {title}
                  </AccordionHeader>
                  <AccordionBody>
                    <p className="text-xl font-premium text-gray-700">
                      {description}
                    </p>
                  </AccordionBody>
                </Accordion>
              ))}
            </Fragment>
          </div>
        </div>
      </section>
    );
};

export default MySkills;
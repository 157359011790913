
import { addProjectAction } from "./AddProjectActionTypes";

export const projectLoading = (data) => {
  return {
    type: addProjectAction.SET_ADD_PROJECT_LOADING,
    payload: data,
  };
};

export const newProjectData = (data) => {
  return {
    type: addProjectAction.SET_NEW_PROJECT,
    payload: data,
  };
};

export const setEditMotive = (data) => {
  return {
    type: addProjectAction.SET_EDIT_MOTIVE,
    payload: data,
  };
};

export const setDeleteMotiveIndex = (data) => {
  return {
    type: addProjectAction.SET_DELETE_MOTIVE_INDEX,
    payload: data,
  };
};

export const setAddNewMotive = (data) => {
  return {
    type: addProjectAction.SET_ADD_NEW_MOTIVE,
    payload: data,
  };
};

export const setHighLightMotive = (data) => {
  return {
    type: addProjectAction.SET_HIGH_LIGHT_MOTIVE,
    payload: data,
  };
};

export const setInputValue = (data) => {
  return {
    type: addProjectAction.SET_INPUT_VALUE,
    payload: data,
  };
};

export const setProjectGallery = (data) => {
  return {
    type: addProjectAction.SET_GALLERY_IMAGE,
    payload: data,
  };
};
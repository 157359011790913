import { navActionTypes } from "./NavActionTypes";

export const navInitialState = {
  toggle: false,
  scroll: false,
  updateModal: false,
  uploadImg: false,
  profile: false,
};

export const navReducer = (state, action) => {
    switch (action.type) {
      case navActionTypes.TOGGLE:
        return {
          ...state,
          toggle: action.payload,
        };
      case navActionTypes.SCROLL:
        return {
          ...state,
          scroll: action.payload,
        };
      case navActionTypes.UPDATE_MODAL:
        return {
          ...state,
          updateModal: action.payload,
        };
      case navActionTypes.UPLOAD_IMG:
        return {
          ...state,
          uploadImg: action.payload,
        };
      case navActionTypes.PROFILE:
        return {
          ...state,
          profile: action.payload,
        };

      default:
        return state;
    }
}
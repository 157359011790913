import React from "react";
import { useLoaderData } from "react-router-dom";
import serviceBanner from "../../assets/SPB/ServiceBanner.png";
import { FaMapMarkerAlt, FaPhone, FaPaperPlane } from "react-icons/fa";
import "react-photo-view/dist/react-photo-view.css";
import ShowGallery from "../../components/ShowGallery/ShowGallery";
import RatingsAndReviews from "./RatingsAndReviews";

const ServiceDetails = () => {
  const service = useLoaderData();

  const {
    _id,
    thumbnail,
    title,
    email,
    phone,
    location,
    tags,
    gallery,
    about,
  } = service;

  const contacts = [
    {
      name: "Address",
      value: location,
      icon: <FaMapMarkerAlt />,
    },
    {
      name: "Phone",
      value: phone,
      icon: <FaPhone />,
    },
    {
      name: "Email",
      value: email,
      icon: <FaPaperPlane />,
    },
  ];

  return (
    <section>
      <div
        style={{ backgroundImage: `url(${serviceBanner})` }}
        className="bg-cover bg-no-repeat lg:bg-center bg-top lg:h-[90vh] h-[60vh] flex justify-center hidden lg:items-center items-end"
      >
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in"
          className="text-center px-10 my-16 lg:mb-0"
        >
          <p className="text-xl  mb-3">
            To give real service you must add something which cannot be bought
            or measured with money, and that is sincerity and integrity.
          </p>
          <h1 className="text-neutral font-stylish text-5xl font-semibold">
            Service Details
          </h1>
        </div>
      </div>
      <div className="my-32">
        <div className="lg:max-w-screen-xl w-[90%] mx-auto">
          <h2
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-cubic"
            className="lg:text-5xl text-4xl font-Bangla"
          >
            {title}
          </h2>
          <p className="w-28 h-1 mb-16 lg:ml-[8%] mt-2 ml-[15%]  bg-accent"></p>
          <p className="text-gray-600 text-lg font-standard">{about}</p>
          <div className="my-20 flex lg:flex-row flex-col gap-20">
            <div className="lg:w-1/2 w-full">
              <figure
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-easing="ease-in-cubic"
                className="lg:w-[350px] w-[300px]"
              >
                <img
                  src={thumbnail?.url}
                  className="lg:w-[350px] w-[300px]"
                  alt="service"
                />
              </figure>
            </div>
            {tags.length > 0 && (
              <div
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-easing="ease-in-cubic"
                className="lg:mt-10 pl-5 lg:w-1/2 w-full"
              >
                <h2 className="text-4xl font-semibold">We Provide:</h2>
                <p className="w-28 h-1 mb-16 lg:ml-[8%] mt-2 ml-[15%]  bg-accent"></p>
                <ul>
                  {tags?.map((tag, index) => (
                    <li
                      key={index}
                      className="text-lg mb-3 tagList text-gray-700"
                    >
                      {tag}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

        {/* =========================== Gallery ========================= */}

        {/* Gallery */}
        <ShowGallery customClass={"my-[10%]"} gallery={gallery}></ShowGallery>

        {/* Ratings and Reviews */}
        <RatingsAndReviews id={_id}></RatingsAndReviews>

        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-easing="ease-in-cubic"
          className="flex flex-col lg:max-w-screen-xl w-[90%] mx-auto gap-8 mt-14"
        >
          <div>
            <h1 className="text-3xl font-stylish text-neutral font-semibold">
              For more information:
            </h1>
            <p className="w-36 h-1 lg:mb-5 mb-0 lg:ml-[5%] mt-2 ml-[15%]  bg-accent"></p>
          </div>
          {contacts.map((contact, index) => (
            <div key={index} className="flex items-center gap-5">
              <div className="text-2xl text-base-100 bg-secondary rounded-full p-4">
                {contact.icon}
              </div>
              <div className="text-start">
                <p className="text-xl text-gray-700 font-semibold">
                  {contact.name}:
                </p>
                <p className="text-xl font-premium text-gray-700">
                  {contact.value}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceDetails;

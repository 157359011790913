/** @format */

import { useQuery } from "@tanstack/react-query";
import React, { useContext } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import {
  FaTrash,
  FaEdit,
  FaCircle,
  FaEye,
  FaEyeSlash,
  FaPlus,
} from "react-icons/fa";
import CommonButton from "../../../../components/CommonButton/CommonButton";
import { AuthContext } from "../../../../Contexts/UserContext/UserContext";
import { deleteImage } from "../../../../utilities/deleteImage";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";

const ManageServices = () => {
  const { logOut } = useContext(AuthContext);
  const [deleteService, setDeleteService] = useState([]);

  const navigate = useNavigate();
  const navigateWithId = (id) => {
    localStorage.setItem("ServiceId", id);
    navigate(`/dashboard/services/${id}`);
  };

  /* ============================= GET ALL SERVICE ===================== */
  const { data: services = [], refetch } = useQuery({
    queryKey: ["myServices"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/admin/services`,
        {
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      const data = await response.json();
      return data;
    },
  });

  const activeService = services.filter(
    (service) => service.status === "active"
  );
  const inActiveService = services.filter(
    (service) => service.status === "inactive"
  );

  /* ============================= DELETE SERVICE ========================== */
  const handleDelete = () => {
    const [id, storageId] = deleteService;

    let imgLocations = [];
    const findService = services.find((service) => service._id === id);
    const { thumbnail, gallery } = findService;

    imgLocations.push(thumbnail.imageId);

    if (gallery?.length > 0) {
      gallery.forEach((data) => {
        imgLocations.push(data.imageId);
      });
    }

    imgLocations.forEach((location) => {
      deleteImage("Services", storageId, location);
    });

    fetch(`${process.env.REACT_APP_SERVER_URL}/admin/deleteService/${id}`, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    })
      .then((res) => {
        if (res.status === 401 || res.status === 403) {
          return logOut();
        }
        return res.json();
      })
      .then((data) => {
        if (data.acknowledged) {
          refetch();
          toast.success("Service deleted successfully");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /* ======================= INACTIVE THE SERVICE ======================== */
  const handleInActive = (id) => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/admin/inActiveService/${id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    })
      .then((res) => {
        if (res.status === 401 || res.status === 403) {
          return logOut();
        }
        return res.json();
      })
      .then((data) => {
        if (data.acknowledged) {
          toast.success("Your service is deactivated");
          refetch();
        }
      });
  };

  /* ============================= ACTIVE THE SERVICE ======================== */
  const handleActive = (id) => {
    console.log(id);
    fetch(`${process.env.REACT_APP_SERVER_URL}/admin/activeService/${id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    })
      .then((res) => {
        if (res.status === 401 || res.status === 403) {
          return logOut();
        }
        return res.json();
      })
      .then((data) => {
        if (data.acknowledged) {
          toast.success("Your service is activated");
          refetch();
        }
      });
  };

  return (
    <section>
      <h2 className="text-4xl font-semibold mt-16 mb-10 underline">
        My Services
      </h2>
      <div className="flex items-center lg:flex-row flex-col gap-5 lg:gap-0 justify-between mb-10 ">
        <div className="flex gap-5 items-center">
          <p className="text-lg">
            Total Service :{" "}
            <span className="text-2xl font-semibold">{services.length}</span>
          </p>
          <p className="text-lg">
            Active:{" "}
            <span className="text-2xl text-green-700 font-semibold">
              {activeService.length}
            </span>
          </p>
          <p className="text-lg">
            Inactive:{" "}
            <span className="text-2xl text-red-700 font-semibold">
              {inActiveService.length}
            </span>
          </p>
        </div>
        <div>
          <Link to="/dashboard/addService">
            <CommonButton customClass={"flex gap-2"}>
              Add new service
              <FaPlus />
            </CommonButton>
          </Link>
        </div>
      </div>

      <div>
        {services?.map(
          (
            {
              _id,
              thumbnail,
              title,
              about,
              status,
              email,
              phone,
              location,
              storageId,
            },
            i
          ) => (
            <div key={i} className="flex items-start lg:gap-5 gap-2">
              <h2 className="text-2xl font-semibold">{i + 1}</h2>
              <div
                key={_id}
                className="flex w-screen lg:flex-row flex-col bg-gray-50 items-center gap-0 lg:gap-4 justify-between mb-8 rounded-lg  p-2 shadow-2xl"
              >
                <figure className="lg:w-[20%] mx-auto">
                  <img
                    className="rounded-xl lg:h-[150px] bg-white lg:w-full w-[350px] h-[200px]"
                    src={thumbnail?.url}
                    alt=""
                  />
                </figure>
                <div className="lg:w-1/2 pb-0">
                  <h2 className="text-2xl font-standard  text-gray-800 mb-3">
                    {title}
                  </h2>
                  <p className="text-base font-standard text-gray-600">
                    {about?.slice(0, 100)}...
                  </p>
                  <div className="lg:mt-3 my-8 flex  items-center justify-center lg:justify-start lg:my-3">
                    <p
                      title="edit service"
                      onClick={() => navigateWithId(_id)}
                      className="hover:bg-gray-300 hover:scale-105 cursor-pointer transition-all duration-300  grid place-items-center rounded-full w-12 h-12"
                    >
                      <FaEdit className="text-xl" />
                    </p>
                    <label
                      title="delete service"
                      onClick={() => setDeleteService([_id, storageId])}
                      htmlFor="confirmModal"
                      className="hover:bg-gray-300 hover:scale-95 cursor-pointer transition-all duration-300 mr-8 grid place-items-center rounded-full w-12 h-12"
                    >
                      <FaTrash className="text-xl" />
                    </label>
                    {status === "active" ? (
                      <div className="flex items-center gap-2">
                        <FaCircle className="text-green-700 text-sm" />
                        <span className="font-medium font-premium text-lg mr-10">
                          Active
                        </span>

                        <span className="p-3 cursor-pointer hover:scale-110 transition-transform duration-200 bg-gray-300 rounded-full">
                          <FaEye
                            title="inactive service"
                            onClick={() => handleInActive(_id)}
                            className="text-lg text-gray-700"
                          />
                        </span>
                      </div>
                    ) : (
                      <div className="flex items-center gap-2">
                        <FaCircle className="text-red-700 text-sm" />
                        <span className="font-medium font-premium text-lg mr-10">
                          Inactive
                        </span>
                        <span className="p-3 hover:scale-110 transition-transform duration-200 bg-gray-300 rounded-full">
                          <FaEyeSlash
                            title="active service"
                            onClick={() => handleActive(_id)}
                            className="text-lg text-gray-700"
                          />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="lg:w-[30%] text-center">
                  <p className="text-lg font-medium font-premium text-gray-800">
                    {email}
                  </p>
                  <p className="font-premium">{phone}</p>
                  <p className="font-standard">{location?.slice(0, 50)}...</p>
                </div>
              </div>
            </div>
          )
        )}
      </div>

      <ConfirmationModal
        actionText={"Delete"}
        modalText={"Are you sure you want to delete this service"}
        action={handleDelete}
      ></ConfirmationModal>
    </section>
  );
};

export default ManageServices;

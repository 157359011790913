/** @format */


import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../../../Contexts/UserContext/UserContext";
import useToken from "../../../Hooks/GetToken/useToken";
import LoginModal from "./LoginModal";

const Login = () => {
  const {
    loginModal,
    setLoginModal,
    signIn,
    googleSignIn,
    facebookSignIn,
    autoModal,
    setAutoModal,
  } = useContext(AuthContext);
  const [error, setError] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const token = useToken(userEmail);

  /* Navigate when get JWT */
  if (token) {
    setLoginModal(false);
    setAutoModal(false);
  }

  const handleLogin = (data) => {
    const { email, password } = data;
    

    // email/password login
    signIn(email, password)
      .then((result) => {

        const user = result.user;
        if (user.uid) {
          setUserEmail(email);
          toast.success("Login successfully");
        }
      })
      .catch((e) => setError(e.message));
  };

  // Google sign in
  const handleGoogleSignIn = () => {
    googleSignIn()
      .then((result) => {
        const user = result.user;
        if (user.uid) {
          setUserEmail(user?.email);

          saveUser(user.displayName, user.email, user.uid, user.photoURL);
        }
      })
      .catch((e) => setError(e.message));
  };

  //Facebook sign in
  const handleFacebookSignIn = () => {
    facebookSignIn()
      .then((result) => {
        const user = result.user;
        setUserEmail(user?.email);
        saveUser(user.displayName, user.email, user.uid, user.photoURL);
      })
      .catch((e) => setError(e.message));
  };

  // Save user to database
  const saveUser = (name, email, uid, image) => {
    const user = {
      name,
      email,
      uid,
      image,
    };

    fetch(`${process.env.REACT_APP_SERVER_URL}/users`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.insertedCount >= 1) {
          toast.success("Login successfully");
        }
      })
      .catch((err) => {
        setError(err);
      });
  };

  return (
    <div>
      {loginModal || autoModal ? (
        <LoginModal
          handleLogin={handleLogin}
          handleGoogleSignIn={handleGoogleSignIn}
          handleFacebookSignIn={handleFacebookSignIn}
          error={error}
          setError={setError}
        ></LoginModal>
      ) : (
        ""
      )}
    </div>
  );
};

export default Login;

/** @format */

import React from "react";
import BlogView from "./View/BlogView";
import ProjectView from "./View/ProjectView";
import ServiceView from "./View/ServiceView";
import TotalView from "./View/TotalView";
import UserView from "./View/UserView";

const Dashboard = () => {
  return (
    <section>
      <div className="mt-20 lg:mb-20">
        <h2 className="lg:text-4xl text-3xl text-center lg:text-left font-stylish font-semibold">
          Eyes on a quick overview
        </h2>
        <p className="w-32 h-1 mb-16 lg:ml-[12%] mt-2 ml-[35%]  bg-primary"></p>
      </div>
      <div>
        <div className="flex lg:flex-row flex-col items-start gap-10">
          <TotalView></TotalView>
          <UserView></UserView>
        </div>
        <ServiceView></ServiceView>
        <ProjectView></ProjectView>
        <BlogView></BlogView>
      </div>
    </section>
  );
};

export default Dashboard;

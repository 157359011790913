/** @format */


import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../../../Contexts/UserContext/UserContext";
import useToken from "../../../Hooks/GetToken/useToken";
import SignUpModal from "./SignUpModal";

const SignUp = () => {
  const {
    signUpModal,
    setSignUpModal,
    createUser,
    updateUser,
    googleSignIn,
    facebookSignIn,
    logOut,
  } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");

  const token = useToken(email);

  /* Navigate when get JWT */
  if (token) {
    setSignUpModal(false);
  }

  // Create new user with email and password
  const handleCreateUser = (data) => {
    const { name, email, password } = data;

    createUser(email, password)
      .then((result) => {
        const user = result.user;
        if (user.uid) {
          setEmail(user.email);
          // Update user
          const userInfo = {
            displayName: name,
          };
          updateUser(userInfo)
            .then(() => {
              setError("");
              saveUser(name, email, user.uid, user.photoURL);
            })
            .catch((e) => console.log(e.message));
        }
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  // Create new user with google account
  const handleGoogleSignIn = () => {
    googleSignIn()
      .then((result) => {
        const user = result.user;
        if (user.uid) {
          setEmail(user.email);
          saveUser(user.displayName, user.email, user.uid, user.photoURL);
        }
      })
      .catch((e) => setError(e.message));
  };

  // Create new user with facebook account
  const handleFacebookSignIn = () => {
    facebookSignIn()
      .then((result) => {
        const user = result.user;
        if (user.uid) {
          setEmail(user.email);
          saveUser(user.displayName, user.email, user.uid, user.photoURL);
        }
      })
      .catch((e) => setError(e.message));
  };

  // Save user to database
  const saveUser = (name, email, uid, image) => {
    const user = {
      name,
      email,
      uid,
      image,
    };

    fetch(`${process.env.REACT_APP_SERVER_URL}/users`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.insertedCount >= 1) {
        }
        toast.success("Sign up successfully");
      })
      .catch((err) => {
        setError(err);
      });
  };

  return (
    <div>
      {signUpModal && (
        <SignUpModal
          handleCreateUser={handleCreateUser}
          handleGoogleSignIn={handleGoogleSignIn}
          handleFacebookSignIn={handleFacebookSignIn}
        ></SignUpModal>
      )}
    </div>
  );
};

export default SignUp;


/** @format */

import { addProjectAction } from "./AddProjectActionTypes";

export const addProjectInitialState = {
  addNewMotive: false,
  highlightMotive: [],
  inputValue: "",
  deleteMotiveIndex: null,
  editMotive: [],
  addProjectLoading: false,
  newProject: {},
  storageId: null,
};

export const addProjectReducer = (state, action) => {
  switch (action.type) {
    case addProjectAction.SET_ADD_NEW_MOTIVE:
      return {
        ...state,
        addNewMotive: action.payload,
      };
    case addProjectAction.SET_HIGH_LIGHT_MOTIVE:
      return {
        ...state,
        highlightMotive: action.payload,
      };
    case addProjectAction.SET_INPUT_VALUE:
      return {
        ...state,
        inputValue: action.payload,
      };
    case addProjectAction.SET_DELETE_MOTIVE_INDEX:
      return {
        ...state,
        deleteMotiveIndex: action.payload,
      };
    case addProjectAction.SET_EDIT_MOTIVE:
      return {
        ...state,
        editMotive: action.payload,
      };
    case addProjectAction.SET_NEW_PROJECT:
      return {
        ...state,
        newProject: { ...state.newProject, ...action.payload },
      };
    case addProjectAction.SET_GALLERY_IMAGE:
      return {
        ...state,
        newProject: { ...state.newProject, gallery: [...action.payload] },
      };
    case addProjectAction.SET_STORAGE_ID:
      return {
        ...state,
        storageId: action.payload,
      };
    default:
      return state;
  }
};

/** @format */

import React, { useLayoutEffect, useRef } from "react";
import "./Banner.css";
import jahid from "../../../assets/Banner/jahid.png";
import { Typewriter } from "react-simple-typewriter";
import { gsap } from "gsap";

import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link } from "react-router-dom";

const Banner = () => {
  const mainDiv = useRef();

  // useLayoutEffect(() => {
  //   let animate = gsap.context(() => {
  //     mainDiv.current = gsap
  //       .timeline({ repeat: -1 })
  //       .to("#div1", { opacity: 1, duration: 2 })
  //       .to("#div1", { opacity: 0, display: "none", duration: 3, delay: 1 })
  //       .to("#div2", { opacity: 1, duration: 2 })
  //       .to("#div2", { opacity: 0, display: "none", duration: 3, delay: 1 });
  //   });

  //   return () => {
  //     animate.revert();
  //   };
  // }, []);

  return (
    <section ref={mainDiv} id="home" className="lg:relatives bg-white">
      {/* <Swiper
        slidesPerView={1}
        loop={true}
        autoplay={true}
        Autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        speed={2000}
      >
        <SwiperSlide>
          <div className="lg:h-[800px] h-[100vh] overflow-hidden flex  chevronDiv z-10  w-full  relative">
            <div className="lg:py-[12%] bottom-24 lg:top-3 w-3/4 lg:left-20 left-24 z-30 absolute">
              <h2 className="lg:text-primary leading-5 text-base-100 font-premium mb-5 text-2xl font-normal lg:tracking-[2px] tracking-[3px]">
                Lead your business with me
              </h2>
              <p className="lg:text-6xl lg:text-neutral text-base-100 text-3xl font-premium font-normal">
                Hi, I am{" "}
                <span className="text-accent font-normal font-stylish lg:text-7xl text-5xl">
                  Jahid Hasan
                </span>{" "}
                <br />
                <span className="lg:text-teal-400 font-standard font-normal lg:text-5xl text-2xl">
                  <Typewriter
                    words={["__Businessman", "_Entrepreneur"]}
                    loop={false}
                    cursor
                    cursorStyle="_"
                    typeSpeed={150}
                    deleteSpeed={80}
                    delaySpeed={2000}
                  />
                </span>
              </p>
              <p className="font-standard text-center text-info  hidden lg:block text-lg mt-10 w-[55%]">
                As an experienced business leader, I can help guide your
                organization to success. By fostering a positive work
                environment and empowering your team, I offer a comprehensive
                suite of services to help you achieve your business goals. Let's
                work together to create a brighter future for your business.
              </p>
              <div>
                <Link
                  to="/contact"
                  className="btn text-white lg:text-primary font-premium tracking-wider btn-outline rounded-sm lg:text-lg text-xl font-light lg:px-8 mt-5 lg:mt-8"
                >
                  Contact Me
                </Link>
              </div>
            </div>

            <div className="lg:w-1/2 z-20 lg:ml-[42%]">
              <img
                src={jahid}
                className="lg:h-full lg:ml-[380px] lg:mt-20 mt-0 animate-[zoom_1.5s_ease-in-out_forwards]"
                alt=""
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="lg:h-[800px] h-[100vh] flex chevronDiv2 z-10 overflow-hidden w-full  relative">
            <div className="lg:py-[7%] bg-neutral bg-opacity-40 lg:pl-0 pl-14 lg:bg-transparent bottom-32 flex lg:flex-col flex-col-reverse lg:top-20 lg:w-3/5 w-full lg:left-24 z-30 absolute">
              <h2 className="lg:text-accent text-base-100 font-premium mt-5 mb-5 text-2xl font-normal lg:tracking-[2px] leading-5 tracking-wider">
                The best way to predict the future is to create it <br />
              </h2>

              <p className="lg:text-4xl leading-[60px] text-3xl font-stylish text-accent lg:text-primary font-semibold tracking-widest">
                A first class <br />
                <span className="lg:text-info text-base-100 lg:text-7xl text-5xl font-standard font-extralight tracking-normal">
                  _Contractor
                </span>
              </p>
              <p className="font-standard text-center text-primary hidden lg:block text-lg mt-10 w-[65%]">
                I am excited to share with you my passion for delivering
                top-notch services and exceeding clients' expectations. With
                years of experience and a commitment to excellence, I take pride
                in every project I work on. From start to finish, I am dedicated
                to delivering exceptional results that meet your unique needs
                and exceed your expectations.
              </p>
            </div>

            <div className="lg:w-1/2 z-20  lg:ml-[42%]">
              <img
                src={jahid}
                className="animate-[zoom_1.5s_ease-in-out_forwards]  lg:mt-[50px] mt-[15px]"
                alt=""
              />
            </div>
          </div>
        </SwiperSlide>
      </Swiper> */}

      <div
        id="div1"
        className="lg:h-[800px] h-[100vh] overflow-hidden flex  chevronDiv   w-full  relative"
      >
        <div className="lg:py-[12%] bottom-24 lg:top-3 w-3/4 lg:left-20 left-24 z-10 absolute">
          <h2 className="lg:text-primary leading-5 text-base-100 font-premium mb-5 text-2xl font-normal lg:tracking-[2px] tracking-[3px]">
            Lead your business with me
          </h2>
          <p className="lg:text-6xl lg:text-neutral text-base-100 text-3xl font-premium font-normal">
            Hi, I am{" "}
            <span className="text-accent font-normal font-stylish lg:text-7xl text-5xl">
              Jahid Hasan
            </span>{" "}
            <br />
            <span className="lg:text-teal-400 font-standard font-normal lg:text-5xl text-2xl">
              <Typewriter
                words={[
                  "__Businessman",
                  "_Entrepreneur",
                  "_First Class Contractor",
                ]}
                loop={false}
                cursor
                cursorStyle="_"
                typeSpeed={70}
                deleteSpeed={80}
                delaySpeed={2000}
              />
            </span>
          </p>
          <p className="font-standard text-center text-info  hidden lg:block text-lg mt-10 w-[55%]">
            As an experienced business leader, I can help guide your
            organization to success. By fostering a positive work environment
            and empowering your team, I offer a comprehensive suite of services
            to help you achieve your business goals. Let's work together to
            create a brighter future for your business.
          </p>
          <div>
            <Link
              to="/contact"
              className="btn text-white lg:text-primary font-premium tracking-wider btn-outline rounded-sm lg:text-lg text-xl font-light lg:px-8 mt-5 lg:mt-8"
            >
              Contact Me
            </Link>
          </div>
        </div>

        <div className="lg:w-1/2 z-20 lg:ml-[42%]">
          <img
            src={jahid}
            className="lg:h-full lg:ml-[380px] lg:mt-20 mt-0 animate-[zoom_1.5s_ease-in-out_forwards]"
            alt=""
          />
        </div>
      </div>
      {/* <div
        id="div2"
        className="lg:h-[800px] h-[100vh] flex chevronDiv2 z-10 overflow-hidden w-full  relative"
      >
        <div className="lg:py-[7%] bg-neutral bg-opacity-40 lg:pl-0 pl-14 lg:bg-transparent bottom-32 flex lg:flex-col flex-col-reverse lg:top-20 lg:w-3/5 w-full lg:left-24 z-30 absolute">
          <h2 className="lg:text-accent text-base-100 font-premium mt-5 mb-5 text-2xl font-normal lg:tracking-[2px] leading-5 tracking-wider">
            The best way to predict the future is to create it <br />
          </h2>

          <p className="lg:text-4xl leading-[60px] text-3xl font-stylish text-accent lg:text-primary font-semibold tracking-widest">
            A first class <br />
            <span className="lg:text-info text-base-100 lg:text-7xl text-5xl font-standard font-extralight tracking-normal">
              _Contractor
            </span>
          </p>
          <p className="font-standard text-center text-primary hidden lg:block text-lg mt-10 w-[65%]">
            I am excited to share with you my passion for delivering top-notch
            services and exceeding clients' expectations. With years of
            experience and a commitment to excellence, I take pride in every
            project I work on. From start to finish, I am dedicated to
            delivering exceptional results that meet your unique needs and
            exceed your expectations.
          </p>
        </div>

        <div className="lg:w-1/2 z-20  lg:ml-[42%]">
          <img
            src={jahid}
            className="animate-[zoom_1.5s_ease-in-out_forwards]  lg:mt-[50px] mt-[15px]"
            alt=""
          />
        </div>
      </div> */}
    </section>
  );
};

export default Banner;

/** @format */

import { useQuery } from "@tanstack/react-query";
import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import { AuthContext } from "../../Contexts/UserContext/UserContext";
import BlogDetails from "./BlogDetails";

export const BLOG_DETAILS_CONTEXT = createContext();

const BlogDetailsAPI = () => {

  const { user, setLoginModal } = useContext(AuthContext);
  const { id } = useParams();


  const [currentBlogs, setCurrentBlogs] = useState({});
  const [recentBlogs, setRecentBlogs] = useState([]);


  /* ========================= FETCHING BLOG DETAILS ========================== */
  const {
    data: blog = [],
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["blog"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/blog/${id}?uid=${user?.uid}`,
      );
      const data = await response.json();
      setCurrentBlogs(data.current);
      setRecentBlogs(data.recent);
      return data;
    },
  });

  useEffect(() => {
    refetch();
  }, [id, refetch]);

  const userId = {
    uid: user?.uid,
  };

  /* ============================== HANDLE LIKES =============================== */
  const handleLikes = () => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/blog/${id}/likes`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(userId),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged && data.modifiedCount > 0) {
          refetch();
        }
      });
  };

  /* ============================== HANDLE DISLIKE =============================== */
  const handleDisLikes = () => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/blog/${id}/disLikes`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(userId),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged && data.modifiedCount > 0) {
          refetch();
        }
      });
  };

  /* =========================== POST NEW COMMENT ============================= */
  const handleComments = (data, e) => {
    if (user === null) {
      toast.error("Please login to add comment");

      setTimeout(() => {
        return setLoginModal(true);
      }, 800);
    } else {
      data.time = new Date().toDateString();
      data.image = user?.photoURL;
      data.id = v4();
      data.uid = user?.uid;

      fetch(`${process.env.REACT_APP_SERVER_URL}/comments/?blogId=${id}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.acknowledged) {
            refetch();
            e.target.reset();
            toast.success("Comment added successfully");
          }
        });
    }
  };

  /* ======================= HANDLE DELETE COMMENT =========================== */
  const deleteComment = (commentId) => {


    fetch(`${process.env.REACT_APP_SERVER_URL}/blog/${id}/comment/delete`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ commentId }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.deletedCount >= 1 && data.acknowledged) {
          refetch();
          toast.success("Comment deleted successfully");
        }
      });
  };

  const data = {
    id,
    refetch,
    isLoading,
    handleLikes,
    handleDisLikes,
    handleComments,
    deleteComment,
    currentBlogs,
    recentBlogs
  };

  return (
    <div>
      <BLOG_DETAILS_CONTEXT.Provider value={data}>
        <BlogDetails></BlogDetails>
      </BLOG_DETAILS_CONTEXT.Provider>
    </div>
  );
};

export const useBlogs = () => {
  const blogs = useContext(BLOG_DETAILS_CONTEXT);
  return blogs;
};

export default BlogDetailsAPI;


import React, { useContext } from "react";
import { useCallback } from "react";
import { useState } from "react";
import {
  PieChart,
  Pie,
  Legend,
  Sector,
  ResponsiveContainer,
  Cell,
} from "recharts";
import UseDeviceWidth from "../../../../Hooks/UseDevice/UseDeviceWidth";
import { DASHBOARD_CONTEXT } from "../DashboardAPI";

const TotalView = () => {


      const [activeIndex, setActiveIndex] = useState(0);
      const mobile = UseDeviceWidth();

    const { OVERVIEW } = useContext(DASHBOARD_CONTEXT);
    
      const COLORS = ["#7c73e6", "#119793", "#155263", "#ec7263", "#ec7101"];

      const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const {
          cx,
          cy,
          midAngle,
          innerRadius,
          outerRadius,
          startAngle,
          endAngle,
          fill,
          payload,
          percent,
          value,
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 1;
        const ey = my;
        const textAnchor = cos >= 0 ? "start" : "end";

        return (
          <g>
            <text
              x={cx}
              y={cy}
              dy={8}
              fontSize={30}
              className="font-stylish font-semibold"
              textAnchor="middle"
              fill={fill}
            >
              {payload.name}
            </text>
            <Sector
              cx={cx}
              cy={cy}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              startAngle={startAngle}
              endAngle={endAngle}
              fill={fill}
            />
            <Sector
              cx={cx}
              cy={cy}
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius={outerRadius + 6}
              outerRadius={outerRadius + 10}
              fill={fill}
            />
            <path
              d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
              stroke={fill}
              fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 5}
              y={ey}
              className="font-premium text-lg tracking-wider"
              textAnchor={textAnchor}
              fill="#333"
            >{`Total ${value}`}</text>
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 0}
              y={ey}
              dy={18}
              textAnchor={textAnchor}
              fill="#999"
            >
              {`(Acquire ${(percent * 100).toFixed(2)}%)`}
            </text>
          </g>
        );
      };

      const onPieEnter = useCallback(
        (_, index) => {
          setActiveIndex(index);
        },
        [setActiveIndex]
      );

    
    return (
      <section className="lg:w-3/5 w-[400px] mx-auto lg:h-[550px] h-[350px] overflow-hidden lg:-mt-16 -ml-8 lg:ml-0">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart className="ml-5 lg:ml-0" width="100%" height="100%">
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={OVERVIEW}
              cx="50%"
              cy="50%"
              innerRadius={mobile ? 80 : 90}
              outerRadius={mobile ? 110 : 140}
              dataKey="count"
              onMouseEnter={onPieEnter}
            >
              {OVERVIEW?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Legend className="mx-auto" />
          </PieChart>
        </ResponsiveContainer>
      </section>
    );
};

export default TotalView;
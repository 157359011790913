
import React from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import UseTitle from '../../Hooks/UseTitle/UseTitle';

const AllBlogs = () => {

    UseTitle('all-blogs');
    const blogs = useLoaderData();
    

    return (
      <section className="lg:py-32 py-20 lg:max-w-screen-xl w-[90%] mx-auto">
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easier="ease-in-cubic"
          className="text-center mb-20"
        >
          <h1 className="lg:text-5xl text-4xl text-neutral font-semibold underline">
            My Blogs
          </h1>
        </div>

        <div className="grid lg:grid-cols-3 grid-cols-1 gap-7">
          {blogs.length >= 1 &&
            typeof blogs !== "string" &&
            blogs?.map(({_id, thumbnail, postingTime, admin, title, description}) => (
              <div
                key={_id}
                data-aos="fade-up"
                data-aos-duration="1500"
                data-easing="ease-in-cubic"
                className="card h-[550px] w-full lg:w-[350px] bg-base-100 shadow-xl rounded-none"
              >
                <div className="h-[250px]">
                  <Link to={`/blog/${_id}`}>
                    <img
                      src={thumbnail?.url}
                      className="w-full h-full"
                      alt="Shoes"
                    />
                  </Link>
                </div>
                <div className="bg-white py-2 px-3 h-[300px] relative text-start">
                  <p className="flex gap-5 items-center text-gray-500 font-semibold">
                    <span>{postingTime}</span>
                    <span>{admin}</span>
                  </p>

                  <Link
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                    to={`/blog/${_id}`}
                  >
                    <h2 className="card-title hover:text-primary hover:underline text-2xl font-medium my-3">
                      {title}
                    </h2>
                  </Link>

                  <p className="text-gray-700 absolute text-lg top-36">
                    {description.slice(0, 120)}...
                  </p>
                </div>
              </div>
            ))}
        </div>
      </section>
    );
};

export default AllBlogs;
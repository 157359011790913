/** @format */

import React from "react";
import { Link } from "react-router-dom";

const PrivacyPage = () => {
  return (
    <div className="pt-32 w-[85%] lg:max-w-screen-lg mx-auto">
      <h2 className="lg:text-5xl text-4xl font-stylish font-semibold">
        Privacy Policy:
      </h2>
      <p className="w-24 h-1 mb-16 lg:ml-[7%] mt-2 ml-[15%]  bg-primary"></p>
      <p className="font-standard">
        We take your privacy very seriously and are committed to protecting your
        personal information. This privacy policy explains how we collect, use,
        and disclose personal information that we obtain from you when you use
        our website.
      </p>
      <br /> <br />
      <h3 className="text-2xl font-stylish font-semibold underline">
        Information We Collect
      </h3>
      <p className="font-standard">
        We may collect personal information from you, such as your name, email
        address, and other contact information, when you voluntarily provide it
        to us through our website.
      </p>
      <br />
      <h3 className="text-2xl font-stylish font-semibold underline">
        How We Use Your Information
      </h3>
      <p className="font-standard">
        We may use your personal information to communicate with you, provide
        you with our services, respond to your inquiries, and improve our
        website. We may also use your non-personal information for statistical
        purposes, to analyze and improve our website, and to customize your user
        experience.
      </p>
      <br />
      <h3 className="text-2xl font-stylish font-semibold underline">
        How We Share Your Information
      </h3>
      <p className="font-standard">
        We will not sell, rent, or otherwise disclose your personal information
        to third parties without your consent, except as necessary to provide
        you with our services, comply with legal obligations, or protect our
        legal rights. We may share your non-personal information with
        third-party service providers to assist us in analyzing and improving
        our website.
      </p>
      <br />
      <h3 className="text-2xl font-stylish font-semibold underline">
        Security of Your Information
      </h3>
      <p className="font-standard">
        We take reasonable measures to protect your personal information from
        unauthorized access, use, or disclosure. However, no method of
        transmission over the Internet or method of electronic storage is 100%
        secure. Therefore, we cannot guarantee the absolute security of your
        personal information.
      </p>
      <br />
      <h3 className="text-2xl font-stylish font-semibold underline">
        Your Rights
      </h3>
      <p className="font-standard">
        You have the right to access, correct, update, and delete your personal
        information that we hold. You may also opt-out of receiving marketing
        communications from us at any time. If you have any questions about our
        privacy policy or would like to exercise your rights, please contact us
        using the contact information provided below.
      </p>
      <br />
      <h3 className="text-2xl font-stylish font-semibold underline">
        Changes to Our Privacy Policy
      </h3>
      <p className="font-standard">
        We may modify or update this privacy policy at any time by posting the
        revised policy on our website. We encourage you to review this policy
        periodically to stay informed of any changes.
      </p>
      <br />
      <h3 className="text-2xl font-stylish font-semibold underline">
        Contact Us
      </h3>
      <p className="font-standard">
        If you have any questions or concerns about our privacy policy, please
        contact us at{" "}
        <Link
          target="_blank"
          className="underline link-primary"
          to="tel:01611542262"
        >
          01611542262
        </Link>
        .
      </p>
    </div>
  );
};

export default PrivacyPage;

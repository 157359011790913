
import React, { useContext, useEffect, useState } from "react";
import { HiOutlineUpload } from "react-icons/hi";
import useResizeImage from "../../../../../Hooks/ResizeImage/useResizeImage";
import useUploadImage from "../../../../../Hooks/UploadImage/useUploadImage";
import { setProjectGallery } from "../../../../../state/AddProjectReducer/AddProjectActionCreator";
import { ADD_PROJECT_CONTEXT } from "../AddNewProject";

const UploadProjectGallery = () => {

      const {
        state: { newProject, storageId },
        newDispatch,
      } = useContext(ADD_PROJECT_CONTEXT);
      const { gallery } = newProject;

      const [file, setFile] = useState(null);

      const compressedImage = useResizeImage(file, 720, 70);

      const [url, progress] = useUploadImage(
        compressedImage,
        "Projects",
        storageId,
        false
      );

      useEffect(() => {
        let cleanup = false;
        if (url?.length > 0) {
          if (!cleanup) {
            newDispatch(setProjectGallery(url));
          }
        }

        return () => {
          cleanup = true;
        };
      }, [url]);


    return (
      <section className="flex justify-center flex-col w-full gap-10">
        <div className="flex flex-col items-center">
          <h2 className="lg:text-2xl text-lg text-gray-800 font-semibold">
            Gallery:
          </h2>

          <div className="lg:w-2/5 w-3/5">
            <input
              onChange={(e) => setFile(e.target.files)}
              type="file"
              multiple
              id="gallery"
              className="opacity-0 relative h-1 w-1 "
            />

            {progress !== null ? (
              <progress
                className="progress h-1 mt-8 progress-success w-96"
                value={progress}
                max="100"
              ></progress>
            ) : (
              <label
                title="upload cover image"
                htmlFor="gallery"
                className="custom-file-label !font-premium  tracking-[2px]"
              >
                <HiOutlineUpload size={30} />
                Select gallery
              </label>
            )}

            <p
              htmlFor="gallery"
              className="text-md tracking-wider ml-5 text-gray-900 text-center font-premium "
            >
              Maximum <span className="text-red-500 text-lg">5</span> file can
              be uploaded
            </p>
          </div>
        </div>

        {/* ================= Live Gallery Photo ==================== */}
        {gallery?.length >= 1 && (
          <div className="w-full">
            <p className="text-xl mb-4">
              Gallery items:{" "}
              <span className="text-xl font-semibold text-gray-800">
                {gallery?.length}
              </span>
            </p>
            <div className="lg:columns-5 w-full columns-3">
              {gallery?.map(({ url }, i) => (
                <div className="mb-4" key={i}>
                  <img src={url} className="rounded-md" alt="" />
                </div>
              ))}
            </div>
          </div>
        )}
      </section>
    );
};

export default UploadProjectGallery;

import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { FaCheck, FaChevronRight, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import CommonButton from '../../../../components/CommonButton/CommonButton';
import EditTags from '../../../../components/EditTags/EditTags';
import Loading from '../../../../components/Loading/Loading';
import { newServiceData, setAddNewTag, setDeleteTagIndex, setEditTags, setHighLightTag, setInputValue } from '../../../../state/AddServiceReducer/AddServiceActionCreator';
import { ADD_SERVICE_CONTEXT } from './AddNewService';
import { CgDanger } from "react-icons/cg";

const UploadServiceData = () => {
  const { state, newDispatch } = useContext(ADD_SERVICE_CONTEXT);
  const navigate = useNavigate();

  const {
    addNewTag,
    highlightTag,
    inputValue,
    deleteTagIndex,
    editTags,
    addServiceLoading,
    newService,
  } = state;


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {...newService}
  });

  const handleNewTag = () => {
    newDispatch(setHighLightTag([...highlightTag, inputValue]));
    newDispatch(setAddNewTag(false));
  };

  if (editTags.length >= 1) {
    const [editValue, index] = editTags;
    highlightTag[index] = editValue;

    newDispatch(setEditTags([]));
  }

  if (deleteTagIndex !== null) {
    highlightTag.splice(deleteTagIndex, 1);

    newDispatch(setDeleteTagIndex(null));
  }

  /* ======================== Save the data to the database ========================= */
  const handleAddNewService = (data) => {
    

    data.index = 0;
    data.tags = highlightTag;
    data.status = "active";

    newDispatch(newServiceData(data));
    navigate("/dashboard/addService/UploadServiceImage");

  
  };


  return (
    <section>
      {addServiceLoading ? (
        <Loading>Adding new service. Please wait..</Loading>
      ) : (
        <div>
          <form
            id="comment"
            onSubmit={handleSubmit(handleAddNewService)}
            className="mt-20"
          >
            {/* ===================== Title ============================= */}

            <div className="relative mb-8 flex flex-col items-center gap-5">
              <div className="w-full gap-10 flex items-center">
                <h2 className="text-lg text-gray-800 flex font-semibold">
                  Title: <span className="text-red-600 ml-1 -mt-1">*</span>
                </h2>
                <input
                  type="text"
                  id="name"
                  placeholder="Enter service title here"
                  className="custom-text-input"
                  {...register("title", { required: "Title is required" })}
                />
              </div>
              {errors.title && (
                <p className="flex flex-row items-center gap-3">
                  <CgDanger className="text-error" size={20} />
                  <span className="text-red-600 font-standard text-sm tracking-widest">
                    {errors.title?.message}
                  </span>
                </p>
              )}
            </div>

            {/* 
            {/* ================================= About ================================= */}

            <div className="relative mb-10 flex flex-col gap-5">
              <div className="flex flex-col lg:gap-5 gap-2">
                <h2 className="text-lg flex  text-gray-800 font-semibold">
                  About: <span className="text-red-600 ml-1 -mt-1">*</span>
                </h2>
                <textarea
                  id="comment"
                  placeholder="Write service description"
                  className="custom-text-input"
                  cols="30"
                  rows="10"
                  {...register("about", {
                    required: "This field is required",
                  })}
                ></textarea>
                {errors.about && (
                  <p className="flex flex-row items-center gap-3">
                    <CgDanger className="text-error" size={20} />
                    <span className="text-red-600 font-standard text-sm tracking-widest">
                      {errors.about?.message}
                    </span>
                  </p>
                )}
              </div>
            </div>

            {/* ========================================= Tags ==============================*/}

            <div className="relative mb-6 flex flex-col gap-5">
              <h2 className="text-lg text-gray-800 font-semibold underline underline-offset-2">
                Highlights:
              </h2>

              {highlightTag?.map((highlight, i) => (
                <EditTags
                  key={i}
                  highlight={highlight}
                  index={i}
                  newDispatch={newDispatch}
                  newDelete={setDeleteTagIndex}
                  newEdit={setEditTags}
                ></EditTags>
              ))}

              {addNewTag && (
                <div className="flex lg:flex-row flex-col items-center gap-5">
                  <textarea
                    onBlur={(e) => newDispatch(setInputValue(e.target.value))}
                    type="text"
                    cols="50"
                    rows="4"
                    className="custom-text-input"
                  />
                  <div className="flex lg:flex-col flex-row  items-center gap-3">
                    <CommonButton
                      customClass={"!p-5"}
                      type={"button"}
                      clickFunction={handleNewTag}
                    >
                      <FaCheck className="text-lg text-gray-800" />
                    </CommonButton>
                    <CommonButton
                      type={"button"}
                      customClass={"!p-5"}
                      clickFunction={() => newDispatch(setAddNewTag(false))}
                    >
                      <FaTimes className="text-lg text-gray-800" />
                    </CommonButton>
                  </div>
                </div>
              )}
              <div className="lg:my-5 my-7 flex gap-10">
                <CommonButton
                  type={"button"}
                  customClass={"!text-sm tracking-[1px]"}
                  clickFunction={() => newDispatch(setAddNewTag(true))}
                >
                  Add
                </CommonButton>
                <CommonButton
                  customClass={"!text-sm tracking-[1px]"}
                  type={"button"}
                  clickFunction={() => newDispatch(setHighLightTag([]))}
                >
                  Delete all
                </CommonButton>
              </div>
              {errors.tags && (
                <p className="text-error">{errors.tags?.message}</p>
              )}
            </div>

            {/* ================================ Email & Phone ========================= */}

            <div className="flex lg:flex-row flex-col lg:gap-10 gap-2">
              <div className="relative mb-6 flex items-center lg:w-1/2 gap-5">
                <h2 className="text-lg flex text-gray-800 font-semibold">
                  Email:
                </h2>
                <input
                  type="email"
                  placeholder="Enter your email address"
                  id="email"
                  className="custom-text-input"
                  {...register("email")}
                />
                {errors.email && (
                  <p className="text-error">{errors.email?.message}</p>
                )}
              </div>
              <div className="relative mb-6 flex flex-col items-center lg:w-1/2 gap-5">
                <div className="w-full gap-10 flex items-center">
                  <h2 className="text-lg flex  text-gray-800 font-semibold">
                    Phone: <span className="text-red-600 ml-1 -mt-1">*</span>
                  </h2>
                  <input
                    type="number"
                    placeholder="Enter phone number"
                    id="phone"
                    className="custom-text-input"
                    {...register("phone", { required: "Phone is required" })}
                  />
                </div>

                {errors.phone && (
                  <p className="flex flex-row items-center gap-3">
                    <CgDanger className="text-error" size={20} />
                    <span className="text-red-600 font-standard text-sm tracking-widest">
                      {errors.phone?.message}
                    </span>
                  </p>
                )}
              </div>
            </div>

            {/* ============================== Location ========================= */}
            <div className="relative mb-6 flex flex-col lg:gap-5 gap-2">
              <div className="w-full gap-10 flex items-center">
                <h2 className="text-lg flex text-gray-800 font-semibold">
                  Location: <span className="text-red-600 ml-1 -mt-1">*</span>
                </h2>
                <textarea
                  type="text"
                  placeholder="Enter contact location"
                  id="location"
                  rows="5"
                  className="custom-text-input"
                  {...register("location", { required: "Address is required" })}
                />
              </div>

              {errors.location && (
                <p className="flex flex-row justify-center items-center gap-3">
                  <CgDanger className="text-error" size={20} />
                  <span className="text-red-600 font-standard text-sm tracking-widest">
                    {errors.location?.message}
                  </span>
                </p>
              )}
            </div>

            <div className="w-full mt-10 text-right">
              <CommonButton
                customClass={" lg:ml-24 tracking-[3px]"}
                type={"submit"}
              >
                Next
                <FaChevronRight className="ml-2" />
              </CommonButton>
            </div>
          </form>
        </div>
      )}
    </section>
  );
};

export default UploadServiceData;
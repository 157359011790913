/** @format */

import React from "react";
import Banner from "./Banner/Banner";
import AboutMe from "./AboutMe/AboutMe";
import "./Home.css";
import MySkills from "./MySkills/MySkills";
import Services from "./Services/Services";
import ProjectOnMind from "./ProjectOnMind/ProjectOnMind";
import WhatClientSay from "./WhatClientSay/WhatClientSay";
import MyBlog from "./MyBlog/MyBlog";
import UseTitle from "../../Hooks/UseTitle/UseTitle";
import MyProjectSample from "./MyProjectSample/MyProjectSample";




const Home = () => {

  UseTitle('Home - Jahid Enterprise');

  return (
    <section>
      
      {/* Banner starts */}
      <Banner></Banner>

      {/* About me */}
      <AboutMe></AboutMe>

      {/* My Skills */}
      <MySkills></MySkills>

      {/* Service Highlight */}
      <Services></Services>

      {/* Project on mind */}
      <ProjectOnMind></ProjectOnMind>

      {/* My Project */}
      <MyProjectSample></MyProjectSample>

      {/* Clients Say */}
      <WhatClientSay></WhatClientSay>

      {/* My Blog */}
      <MyBlog></MyBlog>

  
    </section>
  );
};

export default Home;


import React, { createContext, useReducer, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Loading from "../../../../components/Loading/Loading";
import { handleProjectGallery, handleSetMotive, onProjectFetchingError, onProjectFetchingSuccess } from "../../../../state/EditProjectReducer/EditProjectActionCreator";
import { editProjectActions } from "../../../../state/EditProjectReducer/EditProjectActions";
import { editProjectInitialState, editProjectReducer } from "../../../../state/EditProjectReducer/EditProjectReducer";




export const EDIT_PROJECT_CONTEXT = createContext();

const EditProject = () => {
  const projectId = localStorage.getItem("ProjectId");

  const [state, editDispatch] = useReducer(
    editProjectReducer,
    editProjectInitialState
  );

  const data = {
    state,
    editDispatch,
  };

  const { loading, error, errorMessage } = state;

  // Fetching the service
  useEffect(() => {
    if (projectId !== null) {
      editDispatch({ type: editProjectActions.FETCHING_START });
      fetch(`${process.env.REACT_APP_SERVER_URL}/project/${projectId}`)
        .then((res) => res.json())
        .then((data) => {
          editDispatch(onProjectFetchingSuccess(data));
          editDispatch(handleSetMotive(data.motive));
          editDispatch(handleProjectGallery(data.gallery));
        })
        .catch((err) => {
          editDispatch(onProjectFetchingError(err.message));
        });
    }
  }, [projectId]);

  if (loading) {
    return <Loading>Loading..</Loading>;
  }
  if (error) {
    return (
      <p className="text-center my-52 text-2xl tracking-widest text-error font-semibold ">
        {errorMessage}
      </p>
    );
  }

  return (
    <section>
      <h2 className="text-gray-800 font-stylish mt-10 text-4xl font-semibold">
        Update your project
      </h2>
      <p className="w-28 h-1 mb-16 lg:ml-[10%] mt-2 ml-[25%]  bg-primary"></p>

      <div className="flex flex-col gap-10">
        <div>
          <EDIT_PROJECT_CONTEXT.Provider value={data}>
            <Outlet></Outlet>
          </EDIT_PROJECT_CONTEXT.Provider>
        </div>
      </div>
    </section>
  );
};

export default EditProject;
import { editServiceActions } from "./EditServiceActions";



export const handleUpdateLoading = (data) => {
    return {
        type: editServiceActions.SET_UPDATE_LOADING,
        payload: data,
    }
};

export const updatedService = (data) => {
    return {
        type: editServiceActions.SET_UPDATE_SERVICE,
        payload: data,
    }
};

export const onFetchingSuccess = (data) => {
    return {
        type: editServiceActions.FETCHING_SUCCESS,
        payload: data,
    }
};

export const onFetchingError = (data) => {
    return {
        type: editServiceActions.FETCHING_ERROR,
        payload: data,
    }
};

export const handleSetHighLight = (data) => {
    return {
        type: editServiceActions.SET_HIGHLIGHT,
        payload: data,
    }
};

export const handleNewGallery = (data) => {
    return {
        type: editServiceActions.NEW_GALLERY,
        payload: data,
    }
};

export const handleEditTags = (data) => {
    return {
        type: editServiceActions.EDIT_TAGS,
        payload: data,
    }
};

export const setAddNewTag = (data) => {
    return {
        type: editServiceActions.ADD_NEW_TAG,
        payload: data,
    }
};

export const setGalleryIndex = (data) => {
    return {
        type: editServiceActions.DELETE_GALLERY_INDEX,
        payload: data,
    }
};

export const setTagIndex = (data) => {
    return {
        type: editServiceActions.DELETE_TAG_INDEX,
        payload: data,
    }
};

export const setNewTagValue = (data) => {
    return {
        type: editServiceActions.NEW_TAG_VALUE,
        payload: data,
    }
};

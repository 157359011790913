/** @format */

import React from "react";
import ReactLoading from "react-loading";
import UseDeviceWidth from "../../Hooks/UseDevice/UseDeviceWidth";

const Loading = ({ children }) => {

  const mobile = UseDeviceWidth();

  return (
    <section
      style={{ backgroundColor: "rgba(27, 27, 27, 0.222)" }}
      className="grid fixed place-items-center top-0 left-0 h-[100vh] w-[100vw]"
    >
      <div className="flex flex-col lg:gap-4 justify-center items-center">
        <ReactLoading
          type={"bars"}
          color={"rgb(2, 67, 135)"}
          height={mobile ? 40 : 60}
          width={mobile ? 40 : 60}
        />
        <p className="lg:text-xl text-base text-neutral font-semibold">
          {children}
        </p>
      </div>
    </section>
  );
};

export default Loading;

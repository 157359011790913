import React, { useContext } from "react";
import { toast } from "react-hot-toast";
import { FaUserAlt } from "react-icons/fa";
import { IoIosLogOut } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { RxDashboard } from "react-icons/rx";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../Contexts/UserContext/UserContext";
import UseAdmin from "../../../Hooks/UseAdmin/UseAdmin";
import { navActionTypes } from "../../../state/NavReducer/NavActionTypes";

const UserDetails = ({ state, dispatch }) => {
  const { toggle, scroll } = state;

  const { user, setLoginModal, setSignUpModal, logOut } =
    useContext(AuthContext);
  const [data] = UseAdmin(user?.email);

  // Toggle modal and navMenu
  const loginToggle = () => {
    setLoginModal(true);
    dispatch({ type: navActionTypes.TOGGLE, payload: false });
  };

  const signUpToggle = () => {
    setSignUpModal(true);
    dispatch({ type: navActionTypes.TOGGLE, payload: false });
  };

  // Logout user
  const handleLogOut = () => {
    logOut()
      .then(() => {
        toast.success("Logged out successfully");
        dispatch({ type: navActionTypes.UPLOAD_IMG, payload: false });
      })
      .catch((e) => console.error(e));
  };

  return (
    <section>
      {user ? (
        <div className="dropdown text-left lg:dropdown-left w-full lg:w-auto dropdown-bottom lg:mt-3 -mt-7">
          <label tabIndex={0} className="avatar online mt-7 lg:mt-0">
            <div title="User" className="h-10 w-10 rounded-full">
              {user?.photoURL === null ? (
                <FaUserAlt className="text-2xl text-gray-600 h-full ml-[2px] w-full  rounded-full" />
              ) : (
                <img
                  className="w-full h-full rounded-full"
                  src={user?.photoURL}
                  alt="user"
                />
              )}
            </div>
          </label>

          <div
            tabIndex={0}
            className="-mt-3 lg:mt-16 px-5 lg:mr-0 lg:py-5 py-2 gap-0 ml-8 menu menu-compact dropdown-content bg-secondary lg:bg-opacity-80 lg:rounded-box rounded-xl w-[20rem]"
          >
            <p className="text-2xl text-center text-accent font-stylish h-8 cursor-default  font-semibold tracking-widest">
              {user?.displayName ? `${user?.displayName}` : "Unknown"}
            </p>

            <p className="text-sm mb-5 text-center text-gray-50 font-standard cursor-default tracking-wider">
              {user?.email ? `${user?.email}` : "example@gmail.com"}
            </p>

            {data.admin && (
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  dispatch({ type: navActionTypes.TOGGLE, payload: false });
                }}
                to="/dashboard/dashboard"
                className="text-base-100 hover:bg-neutral transition-opacity duration-200 px-5 py-2 rounded-xl hover:bg-opacity-50 font-standard flex items-center gap-5 text-lg"
              >
                <RxDashboard className="text-2xl font-semibold" />
                Dashboard
              </Link>
            )}

            <Link
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                dispatch({ type: navActionTypes.TOGGLE, payload: false });
              }}
              to="/profile"
              className="text-base-100 hover:bg-neutral transition-opacity duration-200 px-5 py-2 rounded-xl hover:bg-opacity-50 flex font-standard items-center gap-5 text-lg"
            >
              <IoSettingsOutline className="text-2xl font-semibold" />
              Settings
            </Link>

            <p
              onClick={handleLogOut}
              className="text-base-100 cursor-pointer hover:bg-neutral transition-opacity duration-200 px-5 font-standard py-2 rounded-xl hover:bg-opacity-50 flex items-center gap-5 text-lg"
            >
              <IoIosLogOut className="text-2xl font-semibold" />
              Logout
            </p>
          </div>
        </div>
      ) : (
        <div>
          <label
            onClick={loginToggle}
            htmlFor="loginModal"
            className={`btn lg:mr-5 rounded-full transition-opacity lg:transition-none duration-1000 ease-in opacity-0 lg:opacity-100 w-3/4 lg:w-auto mx-auto lg:text-base text-lg btn-outline btn-sm mt-5 ${
              scroll ? "btn-accent" : "btn-primary"
            } ${
              toggle
                ? "opacity-100 btn-accent transition-opacity duration-1000 ease-in delay-1000"
                : ""
            }`}
          >
            Login
          </label>

          <label
            onClick={signUpToggle}
            htmlFor="signUpModal"
            className={`btn rounded-full transition-opacity lg:transition-none duration-1000 ease-in opacity-0 lg:opacity-100 w-3/4 lg:w-auto mx-auto lg:text-base text-lg btn-outline  btn-sm mt-5 ${
              scroll ? "btn-accent" : "btn-primary"
            } ${
              toggle
                ? "opacity-100 btn-accent transition-opacity duration-1000 ease-in delay-1000"
                : ""
            }`}
          >
            signup
          </label>
        </div>
      )}
    </section>
  );
};

export default UserDetails;

/** @format */

import React, { useContext, useEffect, useState } from "react";
import { HiOutlineUpload } from "react-icons/hi";
import useResizeImage from "../../../../../Hooks/ResizeImage/useResizeImage";
import useUploadImage from "../../../../../Hooks/UploadImage/useUploadImage";
import { updatedService } from "../../../../../state/EditServiceReducer/EditServiceActionCreator";
import { deleteImage } from "../../../../../utilities/deleteImage";
import { EDIT_SERVICE_CONTEXT } from "../EditService";

const EditServiceThumb = () => {
  const { state, editDispatch } = useContext(EDIT_SERVICE_CONTEXT);
  const [file, setFile] = useState(null);
  const { updateService } = state;
  const { storageId } = updateService;

  const compressedImage = useResizeImage(file, 720, 70);

  const [newUrl, progress] = useUploadImage(
    compressedImage,
    "Services",
    storageId,
    false
  );

  useEffect(() => {
    let cleanup = false;

    if (!cleanup) {
      if (newUrl?.length > 0) {
        deleteImage("Services", storageId, updateService?.thumbnail?.imageId);

        editDispatch(
          updatedService({
            ...updateService,
            thumbnail: newUrl[0],
          })
        );
      }
    }

    return () => {
      cleanup = true;
    };
  }, [newUrl]);

  return (
    <section className="flex lg:flex-row flex-col items-center lg:gap-10 mb-6">
      <div className="lg:w-[600px] lg:ml-16">
        <img
          className="h-[200px]  lg:h-auto w-[350px] lg:w-full rounded-lg"
          src={updateService?.thumbnail?.url}
          alt=""
        />
      </div>
      <div className="relative flex flex-col my-10 items-start gap-1 w-full">
        <h2 className="text-xl text-gray-800 flex font-standard font-semibold">
          Thumbnail:
        </h2>

        <input
          type="file"
          id="thumbnail"
          className="opacity-0 relative w-1 h-1"
          onChange={(e) => setFile(e.target.files)}
        />
        {progress !== null ? (
          <progress
            className="progress h-1 mt-5 progress-success w-full"
            value={progress}
            max="100"
          ></progress>
        ) : (
          <label
            title="upload cover image"
            htmlFor="thumbnail"
            className="custom-file-label !font-premium  tracking-[2px]"
          >
            <HiOutlineUpload size={30} />
            Update Thumbnail
          </label>
        )}
      </div>
    </section>
  );
};

export default EditServiceThumb;

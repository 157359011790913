/** @format */

import React, { useContext, useEffect, useState } from "react";
import { HiOutlineUpload } from "react-icons/hi";
import { MdOutlineCancel } from "react-icons/md";
import useResizeImage from "../../../../../Hooks/ResizeImage/useResizeImage";
import useUploadImage from "../../../../../Hooks/UploadImage/useUploadImage";
import {
  handleNewGallery,
  setGalleryIndex,
} from "../../../../../state/EditServiceReducer/EditServiceActionCreator";
import { deleteImage } from "../../../../../utilities/deleteImage";
import { EDIT_SERVICE_CONTEXT } from "../EditService";

const EditServiceGallery = () => {
  const { state, editDispatch } = useContext(EDIT_SERVICE_CONTEXT);
  const [file, setFile] = useState(null);

  const { updateService, newGallery, deleteGalleryIndex } = state;

  const { storageId } = updateService;

  const compressedImage = useResizeImage(file, 720, 70);

  const [url, progress] = useUploadImage(
    compressedImage,
    "Services",
    storageId,
    false
  );

  useEffect(() => {
    let cleanup = false;

    if (file?.length === url?.length && !cleanup) {
      editDispatch(handleNewGallery(url));
      setFile(null);
    }

    return () => {
      cleanup = true;
    };
  }, [url, file?.length, editDispatch]);

  // Edit gallery
  if (deleteGalleryIndex !== null) {
    newGallery.splice(deleteGalleryIndex, 1);
    editDispatch(setGalleryIndex(null));
  }

  // handle delete gallery image
  const handleGalleryDelete = (index, imageId) => {
    deleteImage("Services", storageId, imageId);

    editDispatch(setGalleryIndex(index));
  };

  return (
    <section className="lg:mt-32">
      <div className="relative flex flex-col  gap-5 mb-6">
        <h2 className="text-xl text-gray-800  w-20 font-standard font-semibold">
          Gallery:
        </h2>

        {newGallery?.length > 0 && (
          <div className="lg:columns-3 columns-2 justify-between">
            {newGallery?.map(({ url, imageId }, index) => (
              <div key={imageId} className="relative">
                <img className="mb-10 w-full" src={url} alt="" />

                <MdOutlineCancel
                  onClick={() => handleGalleryDelete(index, imageId)}
                  className="absolute right-1 top-1 hover:bg-red-500 rounded-full hover:scale-105 transition-all duration-200 text-base-100 text-2xl"
                />
              </div>
            ))}
          </div>
        )}
        <div className="w-full  mb-10 mt-8 lg:mt-0">
          <input
            type="file"
            multiple="multiple"
            id="gallery"
            className="opacity-0 relative w-1 h-1"
            onChange={(e) => setFile(e.target.files)}
          />

          {progress !== null ? (
            <progress
              className="progress h-1 mt-5 progress-success w-full"
              value={progress}
              max="100"
            >
              {progress}
            </progress>
          ) : (
            <label
              title="upload gallery"
              htmlFor="gallery"
              className="custom-file-label !font-premium tracking-[2px] mx-auto w-56"
            >
              <HiOutlineUpload size={30} />
              Update Gallery
            </label>
          )}

          <p
            htmlFor="gallery"
            className="text-sm text-center text-gray-700 font-medium tracking-widest"
          >
            Maximum <span className="text-red-500 text-xl">5</span> file can be
            uploaded
          </p>
        </div>
      </div>
    </section>
  );
};

export default EditServiceGallery;

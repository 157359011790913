
import { editProjectActions } from "./EditProjectActions";

export const handleUpdateLoading = (data) => {
  return {
    type: editProjectActions.SET_UPDATE_LOADING,
    payload: data,
  };
};

export const updatedProjects = (data) => {
  return {
    type: editProjectActions.SET_UPDATE_PROJECT,
    payload: data,
  };
};

export const onProjectFetchingSuccess = (data) => {
  return {
    type: editProjectActions.FETCHING_SUCCESS,
    payload: data,
  };
};

export const onProjectFetchingError = (data) => {
  return {
    type: editProjectActions.FETCHING_ERROR,
    payload: data,
  };
};

export const handleSetMotive = (data) => {
  return {
    type: editProjectActions.SET_MOTIVE,
    payload: data,
  };
};

export const handleProjectGallery = (data) => {
  return {
    type: editProjectActions.NEW_GALLERY,
    payload: data,
  };
};

export const handleEditMotive = (data) => {
  return {
    type: editProjectActions.EDIT_MOTIVES,
    payload: data,
  };
};

export const setAddNewMotive = (data) => {
  return {
    type: editProjectActions.ADD_NEW_MOTIVE,
    payload: data,
  };
};

export const setProjectGalleryIndex = (data) => {
  return {
    type: editProjectActions.DELETE_GALLERY_INDEX,
    payload: data,
  };
};

export const setMotiveIndex = (data) => {
  return {
    type: editProjectActions.DELETE_MOTIVE_INDEX,
    payload: data,
  };
};

export const setNewMotiveValue = (data) => {
  return {
    type: editProjectActions.NEW_MOTIVE_VALUE,
    payload: data,
  };
};

import React, { useContext } from "react";
import { AiOutlineUsergroupAdd, AiOutlineUser } from "react-icons/ai";
import { RiAdminLine } from "react-icons/ri";
import { DASHBOARD_CONTEXT } from "../DashboardAPI";

const UserView = () => {
  const { USER_VIEW } = useContext(DASHBOARD_CONTEXT);

  const icons = [<AiOutlineUsergroupAdd />, <RiAdminLine />, <AiOutlineUser />];

  return (
    <section className="lg:w-2/5 w-[90%] mx-auto bg-info rounded-2xl py-10 px-10">
      <h1 className="text-2xl text-accent border-b-2 w-3/4 font-premium border-primary tracking-widest mb-10">
        User Analytics
      </h1>
      <div>
        {USER_VIEW?.map(({ value, count }, i) => (
          <div key={i} className="flex flex-col">
            <p className="flex items-center gap-5">
              <span className="text-3xl bg-secondary bg-opacity-75 hover:bg-opacity-100 text-base-100 grid place-items-center h-12 w-12 rounded-full">
                {icons[i]}
              </span>
              <span className="text-xl text-base-100 font-standard">
                {value}
              </span>
            </p>
            <span className="text-2xl text-accent ml-[30%] font-premium font-semibold">
              {count}
            </span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default UserView;

/** @format */

import React from "react";
import { useLoaderData } from "react-router-dom";

/* Import Swiper */
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import UseDeviceWidth from "../../Hooks/UseDevice/UseDeviceWidth";
import ShowGallery from "../../components/ShowGallery/ShowGallery";

const ProjectDetails = () => {
  const mobile = UseDeviceWidth();

  const project = useLoaderData();
  const {
    title,
    description,
    thumbnail,
    email,
    phone,
    startDate,
    endDate,
    duration,
    gallery,
    location,
    motive,
    status,
  } = project;

  return (
    <section className="">
      <div className="flex overflow-hidden lg:flex-row flex-col-reverse pt-36 pb-24  justify-between bg-info mb-20">
        <div className="lg:w-[35%] w-full text-center p-5">
          <h1 className="text-4xl text-gray-300 font-Bangla tracking-widest">
            {title}
          </h1>

          <div className="mt-10">
            <p className="text-gray-300 mb-5 font-standard text-lg">
              Location: <span className="text-accent">{location}</span>
            </p>
            <p className="text-gray-300 font-standard text-lg">
              Duration:{" "}
              <span className="text-2xl text-primary">{duration}</span>
            </p>
            <p className="text-gray-300 font-standard text-lg">
              Started At:{" "}
              <span className="font-stylish font-semibold tracking-widest">
                {startDate}
              </span>
            </p>
            {status === "running" ? (
              <p className="text-gray-300 font-standard text-lg">
                Status:{" "}
                <span className="font-premium text-2xl tracking-wider">
                  {status}
                </span>
              </p>
            ) : (
              <p className="text-gray-300 font-standard text-lg">
                Completed At: {endDate}
              </p>
            )}
          </div>
        </div>
        <div className="lg:w-[65%] overflow-hidden w-full">
          <Swiper
            slidesPerView={1}
            centeredSlides={true}
            autoplay={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            Autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
            navigation={mobile ? false : true}
          >
            {gallery.length &&
              gallery?.map(({ url }, i) => (
                <SwiperSlide key={i}>
                  <figure className="max-h-[375px] min-h-full  mx-auto lg:w-[270px] w-[320px]">
                    <img
                      className="rounded-md min-h-full max-h-[350px]   w-[320px] lg:w-[270px]"
                      alt=""
                      src={url}
                    />
                  </figure>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
      <div className="lg:max-w-screen-xl overflow-hidden w-[90%] mx-auto">
        <div className="text-center mt-16 mb-24">
          <h1
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="ease-in-cubic"
            className="lg:text-5xl text-4xl font-stylish"
          >
            Now let's talk about the project
          </h1>
          <p className="lg:w-48 w-32 h-1 mb-10 lg:ml-[40%] ml-[32%] mt-2  bg-accent"></p>
        </div>
        <div className="flex gap-14 lg:pt-10 flex-col lg:mb-32 mb-10 lg:flex-row">
          <div className="lg:w-1/2 w-full mt-10">
            <figure className="lg:w-[480px] lg:min-h-[300px] w-[350px] ml-0 lg:mx-auto my-auto projectThumb relative">
              <img
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-easing="ease-in-cubic"
                className="lg:w-[480px] lg:min-h-[300px] w-[350px] ml-0 rounded-2xl"
                src={thumbnail?.url}
                alt=""
              />
            </figure>
          </div>

          <div className="lg:w-1/2 w-full">
            <p className="font-standard text-lg mt-10">{description}</p>
          </div>
        </div>

        {/* =================== GALLERY ==================== */}
        <ShowGallery gallery={gallery}
        customClass={'my-[10%]'}
        ></ShowGallery>

        <div className="flex lg:max-w-screen-lg w-[90%] mb-20 gap-10 lg:gap-5 mx-auto lg:flex-row flex-col">
          {motive?.length && (
            <div className="lg:w-3/4 w-full mt-20 ml-5">
              <h1
                data-aos="zoom-in"
                data-aos-duration="500"
                data-aos-easing="ease-in-cubic"
                className="lg:text-5xl -ml-5 text-4xl font-semibold font-stylish"
              >
                Our motive
              </h1>
              <p className="w-24 h-[2px] mb-10 lg:ml-[5%] ml-[10%] mt-2  bg-accent"></p>
              <ul className="motiveList">
                {motive?.map((m, i) => (
                  <li key={i} className="list-item items-center gap-5 mb-3">
                    <span className="text-lg font-standard">{m}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="lg:w-1/4 w-full mt-20">
            <h2
              data-aos="zoom-in"
              data-aos-duration="500"
              data-aos-easing="ease-in-cubic"
              className="lg:text-4xl text-3xl text-primary font-stylish mb-5 font-semibold"
            >
              Contact in
            </h2>
            <p className="flex items-center gap-5 mb-3 lg:mb-6">
              <span className="h-10 w-10 bg-gray-300 grid place-items-center rounded-full">
                <AiOutlineMail className="text-xl" />
              </span>
              <span className="text-xl tracking-wider text-gray-600 font-premium">
                {email}
              </span>
            </p>
            <p className="flex items-center gap-5">
              <span className="h-10 w-10 bg-gray-300 grid place-items-center rounded-full">
                <AiOutlinePhone className="text-xl" />
              </span>
              <span className="text-xl tracking-wider text-gray-600 font-premium">
                {phone}
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectDetails;


import React, { createContext, useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";




export const EDIT_BLOGS_CONTEXT = createContext();
const EditBlogs = () => {
  const blogId = localStorage.getItem("BlogId");
  const [newGallery, setNewGallery] = useState([]);
  const [blog, setBlog] = useState({});
  const [loading, setLoading] = useState(false);


  /* ================ Fetch the Blog ================ */
  useEffect(() => {
    let cleanup = false;
    setLoading(true);
    fetch(`${process.env.REACT_APP_SERVER_URL}/admin/blog/${blogId}`)
      .then((res) => res.json())
      .then((data) => {
        if (!cleanup) {
          setBlog(data);
          setLoading(false);
        }
      });

    return () => {
      cleanup = true;
    };
  }, [blogId]);
    
      const data = {
        blog,
        setBlog,
        loading,
        newGallery,
        setNewGallery,
      };

      useEffect(() => {
        let cleanup = false;

        if (!cleanup && !loading) {
          setNewGallery(blog?.gallery);
        }

        return () => {
          cleanup = true;
        };
      }, [blog?.gallery, loading]);


    return (
      <div>
        <EDIT_BLOGS_CONTEXT.Provider value={data}>
          <Outlet></Outlet>
        </EDIT_BLOGS_CONTEXT.Provider>
      </div>
    );
};

export default EditBlogs;
/** @format */

import React from "react";

const HeadingText = ({
  heading,
  description,
  headingClass,
  descriptionClass,
}) => {
  return (
    <div className="lg:max-w-screen-xl w-[90vw] mx-auto text-center">
      <h2
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-easing="ease-in-cubic"
        className={`lg:text-6xl text-5xl  font-stylish font-medium ${
          headingClass && headingClass
        }`}
      >
        {heading}
      </h2>
      <p className="w-28 h-1 bg-accent mt-2 mb-10 absolute left-[36%] lg:left-[47%]"></p>
      <p
        data-aos="fade-up"
        data-aos-duration="800"
        data-aos-easing="ease-in-cubic"
        className={` text-lg font-standard my-20 ${
          descriptionClass && descriptionClass
        }`}
      >
        {description}
      </p>
    </div>
  );
};

export default HeadingText;


/** @format */

export const editProjectActions = {
  FETCHING_START: "FETCHING_START",
  FETCHING_SUCCESS: "FETCHING_SUCCESS",
  FETCHING_ERROR: "FETCHING_ERROR",
  SET_MOTIVE: "SET_MOTIVE",
  ADD_NEW_MOTIVE: "ADD_NEW_MOTIVE",
  NEW_MOTIVE_VALUE: "NEW_MOTIVE_VALUE",
  EDIT_MOTIVES: "EDIT_MOTIVES",
  DELETE_MOTIVE_INDEX: "DELETE_MOTIVE_INDEX",
  DELETE_GALLERY_INDEX: "DELETE_GALLERY_INDEX",
  NEW_GALLERY: "NEW_GALLERY",
  SET_UPDATE_PROJECT: "SET_UPDATE_PROJECT",
  SET_UPDATE_LOADING: "SET_UPDATE_LOADING",
};

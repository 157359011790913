/** @format */

import React from "react";
import { Link } from "react-router-dom";

const RecentBlogs = ({ recentBlogs, admin, postingTime }) => {
  return (
    <div className="lg:w-[25%] w-[90%] mx-auto">
      <div>
        <h2 className="text-2xl mb-10 underline">Recent Blogs</h2>

        <div className="w-full">
          {recentBlogs?.length >= 1 &&
            recentBlogs?.map(({ _id, thumbnail, title }) => (
              <div key={_id} className="flex items-start gap-5 mb-5">
                <img
                  className="h-20 w-32 bg-white"
                  src={thumbnail?.url}
                  alt=""
                />
                <div>
                  <Link
                    to={`/blog/${_id}`}
                    className="text-lg font-medium hover:underline hover:text-primary"
                  >
                    {title?.slice(0, 15)}..
                  </Link>
                  <p className="flex gap-5 items-center text-gray-600 font-semibold -mt-1">
                    <span>{postingTime}</span>
                    <span>{admin}</span>
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
      <Link
        className="text-blue-500 hover:underline tracking-wider"
        to="/allBlogs"
      >
        See All..
      </Link>
    </div>
  );
};

export default RecentBlogs;

import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useBlogs } from "./BlogDetailsAPI";
import { AuthContext } from "../../Contexts/UserContext/UserContext";

const CommentForm = () => {
  const { handleComments } = useBlogs();
  const { user } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /* ====================== making class variable =================================== */

  const inputClass =
    "block px-5 py-3 w-full text-base text-gray-700 bg-transparent rounded-md border-2 border-gray-600 border-opacity-50 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer";

  const labelClass =
    "absolute text-lg text-gray-500  duration-300 transform -translate-y-4 scale-100 top-5 z-10 origin-[0] bg-transparent peer-focus:bg-base-100  px-2 peer-focus:px-4 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-6 left-3";

  const labelClass1 =
    "absolute text-lg text-gray-800  duration-300 transform -translate-y-4 scale-100 -top-3 z-10 origin-[0] bg-transparent peer-focus:bg-base-100  px-2 peer-focus:px-4 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90 peer-focus:-translate-y-6 left-3";

  return (
    <div>
      <form
        id="comment"
        onSubmit={handleSubmit(handleComments)}
        className="mt-20"
      >
        <h1 className="text-3xl font-medium mb-10">Leave a comment</h1>
        <div className="relative z-0 mb-10">
          <input
            type="text"
            id="name"
            defaultValue={user?.displayName}
            className={inputClass}
            {...register("user", { required: "Name is required" })}
          />
          {errors.user && <p className="text-error">{errors.user?.message}</p>}
          <label
            htmlFor="name"
            className={user?.displayName ? labelClass1 : labelClass}
          >
            Name (<span className="text-red-500 text-xl">*</span>)
          </label>
        </div>
        <div className="relative z-0 mb-10">
          <input
            type="email"
            id="email"
            className={inputClass}
            defaultValue={user?.email}
            {...register("email", { required: "Email is required" })}
          />
          {errors.email && (
            <p className="text-error">{errors.email?.message}</p>
          )}
          <label
            htmlFor="email"
            className={user?.email ? labelClass1 : labelClass}
          >
            Email (<span className="text-red-500 text-xl">*</span>)
          </label>
        </div>
        <div className="relative z-0">
          <textarea
            id="comment"
            className={inputClass}
            cols="30"
            rows="10"
            {...register("message", { required: "This field is required" })}
          ></textarea>
          {errors.message && (
            <p className="text-error">{errors.message?.message}</p>
          )}
          <label htmlFor="comment" className={labelClass}>
            Comment (<span className="text-red-500 text-xl">*</span>)
          </label>
        </div>

        <button
          type="submit"
          className="btn mt-10 btn-outline w-full btn-primary rounded-none text-lg"
        >
          Post Comment
        </button>
      </form>
    </div>
  );
};

export default CommentForm;

import { createBrowserRouter } from "react-router-dom";
import AllBlogs from "../pages/AllBlogs/AllBlogs";
import AllServices from "../pages/AllServices/AllServices";
import Home from "../pages/Home/Home";
import AllProjects from "../pages/AllProjects/AllProjects";
import ServiceDetails from "../pages/ServiceDetails/ServiceDetails";
import ErrorPage from "../pages/OthersPage/ErrorPage";
import MainLayout from "../layout/MainLayout";
import ContactMe from "../pages/ContactMe/ContactMe";
import DashboardLayout from "../layout/DashboardLayout";
import AdminRoute from "./AdminRoute";
import PrivateRoute from "./PrivateRoute";
import BlogDetailsAPI from "../pages/BlogDetails/BlogDetailsAPI";
import PrivacyPage from "../pages/OthersPage/PrivacyPage";
import TermsPage from "../pages/OthersPage/TermsPage";
import Profile from "../layout/Profile/Profile";

import Dashboard from "../pages/Dashboard/Dashboard/Dashboard";
import ManageServices from "../pages/Dashboard/ManageService/ManageServices/ManageServices";
import UploadServiceImage from "../pages/Dashboard/ManageService/AddNewService/UploadServiceImage/UploadServiceImage";
import UploadServiceData from "../pages/Dashboard/ManageService/AddNewService/UploadServiceData";
import AddNewService from "../pages/Dashboard/ManageService/AddNewService/AddNewService";
import EditService from "../pages/Dashboard/ManageService/EditService/EditService";
import EditImage from "../pages/Dashboard/ManageService/EditService/EditServiceImage/EditServiceImage";
import EditServiceData from "../pages/Dashboard/ManageService/EditService/EditServiceData";
import EditServiceImage from "../pages/Dashboard/ManageService/EditService/EditServiceImage/EditServiceImage";
import ManageBlogs from "../pages/Dashboard/ManageBlog/ManageBlogs/ManageBlogs/ManageBlogs";
import AddNewBlogs from "../pages/Dashboard/ManageBlog/ManageBlogs/AddNewBlogs/AddNewBlogs";
import UploadBlogData from "../pages/Dashboard/ManageBlog/ManageBlogs/AddNewBlogs/UploadBlogData";
import EditBlogs from "../pages/Dashboard/ManageBlog/ManageBlogs/EditBlogs/EditBlogs";
import EditBlogData from "../pages/Dashboard/ManageBlog/ManageBlogs/EditBlogs/EditBlogData";
import ManageUsers from "../pages/Dashboard/manageUsers/ManageUsers";
import ManageProjects from "../pages/Dashboard/ManageProjects/ManageProjects/ManageProjects";
import AddNewProject from "../pages/Dashboard/ManageProjects/AddNewProject/AddNewProject";
import UploadProjectImage from "../pages/Dashboard/ManageProjects/AddNewProject/UploadProjectImage/UploadProjectImage";
import UploadProjectData from "../pages/Dashboard/ManageProjects/AddNewProject/UploadProjectData";
import EditProject from "../pages/Dashboard/ManageProjects/EditProject/EditProject";
import EditProjectDate from "../pages/Dashboard/ManageProjects/EditProject/EditProjectDate";
import EditProjectImage from "../pages/Dashboard/ManageProjects/EditProject/EditProjectImage/EditProjectImage";
import ProjectDetails from "../pages/ProjectDetails/ProjectDetails";
import DashboardAPI from "../pages/Dashboard/Dashboard/DashboardAPI";


export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout></MainLayout>,
    children: [
      {
        path: "/",
        element: <Home></Home>,
      },
      {
        path: "/home",
        element: <Home></Home>,
      },
      /* ======================== AUTHENTICATION ===================== */
      {
        path: "/profile",
        element: (
          <PrivateRoute>
            <Profile></Profile>
          </PrivateRoute>
        ),
      },
      /* ======================== LEGAL ===================== */
      {
        path: "/terms",
        element: <TermsPage></TermsPage>,
      },
      {
        path: "/privacy",
        element: <PrivacyPage></PrivacyPage>,
      },

      /* ======================== SERVICE ===================== */
      {
        path: "/allServices",
        loader: () => fetch(`${process.env.REACT_APP_SERVER_URL}/services`),
        element: <AllServices></AllServices>,
      },
      {
        path: "/service/:id",
        errorElement: <ErrorPage></ErrorPage>,
        loader: ({ params }) =>
          fetch(`${process.env.REACT_APP_SERVER_URL}/service/${params.id}`),
        element: <ServiceDetails></ServiceDetails>,
      },

      /* ======================== PROJECT ===================== */
      {
        path: "/allProjects",
        element: <AllProjects></AllProjects>,
      },
      {
        path: "/project/:id",
        loader: ({ params }) =>
          fetch(`${process.env.REACT_APP_SERVER_URL}/project/${params.id}`),
        element: <ProjectDetails></ProjectDetails>,
      },

      /* ======================== BLOGS ===================== */
      {
        path: "/allBlogs",
        loader: () => fetch(`${process.env.REACT_APP_SERVER_URL}/blogs`),
        element: <AllBlogs></AllBlogs>,
      },
      {
        path: "/blog/:id",
        errorElement: <ErrorPage></ErrorPage>,
        element: <BlogDetailsAPI></BlogDetailsAPI>,
      },

      /* ======================== CONTACT ===================== */
      {
        path: "/contact",
        element: <ContactMe></ContactMe>,
      },
    ],
  },

  /* ============================ DASHBOARD ======================= */
  {
    path: "/dashboard",
    element: (
      <AdminRoute>
        <DashboardLayout></DashboardLayout>
      </AdminRoute>
    ),
    children: [
      {
        path: "/dashboard/dashboard",
        element: (
            <AdminRoute>
              <DashboardAPI></DashboardAPI>
            </AdminRoute>
        ),
      },

      /* ===================== SERVICE MANAGEMENT =================== */
      {
        path: "/dashboard/manageServices",
        element: (
          <AdminRoute>
            <ManageServices></ManageServices>
          </AdminRoute>
        ),
      },

      /* ===================== ADD SERVICE =================== */
      {
        path: "/dashboard/addService",
        element: (
          <AdminRoute>
            <AddNewService></AddNewService>
          </AdminRoute>
        ),
        children: [
          {
            path: "/dashboard/addService/UploadServiceImage",
            element: <UploadServiceImage></UploadServiceImage>,
          },
          {
            path: "/dashboard/addService",
            element: <UploadServiceData></UploadServiceData>,
          },
        ],
      },

      /* ===================== EDIT SERVICE =================== */
      {
        path: "/dashboard/services/:id",
        element: (
          <AdminRoute>
            <EditService></EditService>
          </AdminRoute>
        ),
        children: [
          {
            path: "/dashboard/services/:id",
            element: <EditServiceData></EditServiceData>,
          },
          {
            path: "/dashboard/services/:id/editImage",
            element: <EditServiceImage></EditServiceImage>,
          },
        ],
      },
      /* ===================== PROJECT MANAGEMENT =================== */

      {
        path: "/dashboard/manageProjects",
        element: (
          <AdminRoute>
            <ManageProjects></ManageProjects>
          </AdminRoute>
        ),
      },
      /* ===================== ADD PROJECT =================== */

      {
        path: "/dashboard/addProject",
        element: (
          <AdminRoute>
            <AddNewProject></AddNewProject>
          </AdminRoute>
        ),
        children: [
          {
            path: "/dashboard/addProject/imageUpload",
            element: (
              <AdminRoute>
                <UploadProjectImage></UploadProjectImage>
              </AdminRoute>
            ),
          },
          {
            path: "/dashboard/addProject",
            element: (
              <AdminRoute>
                <UploadProjectData></UploadProjectData>
              </AdminRoute>
            ),
          },
        ],
      },
      /* ===================== EDIT PROJECT =================== */
      {
        path: "/dashboard/projects/:id",
        element: (
          <AdminRoute>
            <EditProject></EditProject>
          </AdminRoute>
        ),
        children: [
          {
            path: "/dashboard/projects/:id",
            element: (
              <AdminRoute>
                <EditProjectDate></EditProjectDate>
              </AdminRoute>
            ),
          },
          {
            path: "/dashboard/projects/:id/editImage",
            element: (
              <AdminRoute>
                <EditProjectImage></EditProjectImage>
              </AdminRoute>
            ),
          },
        ],
      },

      /* ===================== BLOGS MANAGEMENT =================== */
      {
        path: "/dashboard/manageBlogs",
        element: (
          <AdminRoute>
            <ManageBlogs></ManageBlogs>
          </AdminRoute>
        ),
      },

      /* ===================== ADD BLOG =================== */
      {
        path: "/dashboard/addBlog",
        element: (
          <AdminRoute>
            <AddNewBlogs></AddNewBlogs>
          </AdminRoute>
        ),
        children: [
          {
            path: "/dashboard/addBlog",
            element: (
              <AdminRoute>
                <UploadBlogData></UploadBlogData>
              </AdminRoute>
            ),
          },
        ],
      },
      /* ===================== EDIT BLOG =================== */
      {
        path: "/dashboard/blogs/:id",
        element: (
          <AdminRoute>
            <EditBlogs></EditBlogs>
          </AdminRoute>
        ),
        children: [
          {
            path: "/dashboard/blogs/:id",
            element: (
              <AdminRoute>
                <EditBlogData></EditBlogData>
              </AdminRoute>
            ),
          },
        ],
      },

      /* ===================== USER MANAGEMENT =================== */

      {
        path: "/dashboard/manageUsers",
        element: (
          <AdminRoute>
            <ManageUsers></ManageUsers>
          </AdminRoute>
        ),
      },
    ],
  },
]);




import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FaCheck, FaChevronRight, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import CommonButton from '../../../../components/CommonButton/CommonButton';
import EditTags from '../../../../components/EditTags/EditTags';
import Loading from '../../../../components/Loading/Loading';
import { handleEditTags, handleSetHighLight, setAddNewTag, setGalleryIndex, setNewTagValue, setTagIndex, updatedService } from '../../../../state/EditServiceReducer/EditServiceActionCreator';
import { EDIT_SERVICE_CONTEXT } from './EditService';
import UseDeviceWidth from '../../../../Hooks/UseDevice/UseDeviceWidth';


const EditServiceData = () => {
  const navigate = useNavigate();
  const { state, editDispatch } = useContext(EDIT_SERVICE_CONTEXT);
  const serviceId = localStorage.getItem("ServiceId");
  const mobile = UseDeviceWidth();
  const {
    loading,
    error,
    errorMessage,
    highLights,
    addNewTag,
    newTagValue,
    editTags,
    deleteTagIndex,
    newGallery,
    deleteGalleryIndex,
    updateService,
  } = state;

  const {
    title,
    email,
    phone,
    location,
    thumbnail,
    about,
    tags,
    status,
    index: dataIndex,
  } = updateService;


  // Create react hook form variables
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Set default values for input fields. It's because the default values isn't showing because of the fetching/loading
  useEffect(() => {
    reset({
      title,
      email,
      phone,
      location,
      about,
      tags,
    });
  }, [reset, title, email, phone, location, about, tags]);

  if (loading) {
    return <Loading>Loading...</Loading>;
  }
  if (error) {
    return (
      <p className="text-center my-52 text-2xl tracking-widest text-error font-semibold ">
        {errorMessage}
      </p>
    );
  }

  const [inputValue, index] = editTags;

  if (editTags.length >= 1) {
    highLights[index] = inputValue;
    editDispatch(handleEditTags([]));
  }

  if (deleteTagIndex !== null && highLights.length >= deleteTagIndex) {
    highLights.splice(deleteTagIndex, 1);
    editDispatch(setTagIndex(null));
  }

  // Delete all tags
  const handleDeleteAllTags = () => {
    editDispatch(handleSetHighLight([]));
  };

  // Add new tags
  const handleAddNewTag = () => {
    editDispatch(handleSetHighLight([...highLights, newTagValue]));
    editDispatch(setAddNewTag(false));
  };

  // Edit gallery
  if (deleteGalleryIndex !== null) {
    newGallery.splice(deleteGalleryIndex, 1);
    editDispatch(setGalleryIndex(null));
  }

  /* Submitting form on clicking "next" button */
  const handleEditService = (data) => {
    data.tags = highLights;
    data.status = status;
    data.index = dataIndex;
    data.thumbnail = thumbnail;

    editDispatch(updatedService({ ...updateService, ...data }));
    navigate(`/dashboard/services/${serviceId}/editImage`);
  };

  return (
    <section>
      <div>
        <form onSubmit={handleSubmit(handleEditService)} className="mt-20">
          {/* ===================== Title ============================= */}

          <div className="relative mb-6 flex items-center gap-5">
            <h2 className="text-lg text-gray-800 flex font-semibold">
              Title: <span className="text-red-600 ml-1 -mt-1">*</span>
            </h2>
            <input
              type="text"
              defaultValue={title}
              id="name"
              className="custom-text-input tracking-[2px]"
              {...register("title", { required: "Title is required" })}
            />
            {errors.title && (
              <p className="text-error">{errors.title?.message}</p>
            )}
          </div>

          {/* ================================= About ================================= */}

          <div className="relative mb-6 flex flex-col gap-5">
            <div className="flex lg:flex-row flex-col lg:gap-5 gap-2">
              <h2 className="text-lg text-gray-800 flex  font-semibold">
                About: <span className="text-red-600 ml-1 -mt-1">*</span>
              </h2>
              <textarea
                defaultValue={about}
                id="comment"
                className="custom-text-input"
                cols="30"
                rows={mobile ? '20':'10'}
                {...register("about", { required: "This field is required" })}
              ></textarea>
              {errors.about && (
                <p className="text-error">{errors.about?.message}</p>
              )}
            </div>
          </div>

          {/* ========================================= Tags ==============================*/}

          <div className="relative mb-6 flex flex-col gap-3">
            <h2 className="text-lg text-gray-800 font-semibold underline underline-offset-2">
              Highlights:
            </h2>

            {highLights?.map((highlight, i) => (
              <EditTags
                key={i}
                highlight={highlight}
                index={i}
                editDispatch={editDispatch}
                deleteAction={setTagIndex}
                editAction={handleEditTags}
              ></EditTags>
            ))}
            {addNewTag && (
              <div className="flex flex-col mt-3 lg:mt-5 lg:flex-row items-center gap-3 lg:gap-5">
                <textarea
                  onBlur={(e) => {
                    editDispatch(setNewTagValue(e.target.value));
                  }}
                  type="text"
                  rows="5"
                  className="custom-text-input"
                />

                <div className="flex flex-row  lg:flex-col items-center gap-3">
                  <CommonButton type={"button"} clickFunction={handleAddNewTag}>
                    <FaCheck className="text-sm text-primary" />
                  </CommonButton>
                  <CommonButton
                    type={"button"}
                    clickFunction={() => editDispatch(setAddNewTag(false))}
                  >
                    <FaTimes className="text-sm text-primary" />
                  </CommonButton>
                </div>
              </div>
            )}
            <div className="lg:my-5 my-7 flex gap-10">
              <CommonButton
                type={"button"}
                customClass={"tracking-[1px]"}
                clickFunction={() => editDispatch(setAddNewTag(true))}
              >
                Add New
              </CommonButton>
              <CommonButton
                type={"button"}
                customClass={"tracking-[1px]"}
                clickFunction={handleDeleteAllTags}
              >
                Delete all
              </CommonButton>
            </div>
            {errors.tags && (
              <p className="text-error">{errors.tags?.message}</p>
            )}
          </div>

          {/* ================================ Email & Phone ========================= */}

          <div className="flex lg:flex-row flex-col lg:gap-10 gap-2">
            <div className="relative mb-6 flex items-center lg:w-1/2 gap-5">
              <h2 className="flex text-lg text-gray-800 font-semibold">
                Email: <span className="text-red-600 ml-1 -mt-1">*</span>
              </h2>
              <input
                defaultValue={email}
                type="email"
                id="email"
                className="custom-text-input"
                {...register("email", { required: "Email is required" })}
              />
              {errors.email && (
                <p className="text-error">{errors.email?.message}</p>
              )}
            </div>
            <div className="relative mb-6 flex items-center lg:w-1/2 gap-5">
              <h2 className="text-lg text-gray-800 flex font-semibold">
                Phone: <span className="text-red-600 ml-1 -mt-1">*</span>
              </h2>
              <input
                defaultValue={phone}
                type="number"
                id="phone"
                className="custom-text-input"
                {...register("phone", { required: "Phone is required" })}
              />
              {errors.phone && (
                <p className="text-error">{errors.phone?.message}</p>
              )}
            </div>
          </div>

          {/* ============================== Location ========================= */}
          <div className="relative mb-6 flex lg:flex-row flex-col lg:gap-5 gap-2">
            <h2 className="text-lg text-gray-800 font-semibold">Location: </h2>
            <textarea
              defaultValue={location}
              type="text"
              id="location"
              rows="5"
              className="custom-text-input"
              {...register("location")}
            />
            {errors.location && (
              <p className="text-error">{errors.location?.message}</p>
            )}
          </div>

          <div className="text-right mt-10">
            <CommonButton type={"submit"} customClass={"tracking-[3px]"}>
              Next
              <FaChevronRight className="ml-3" />
            </CommonButton>
          </div>
        </form>
      </div>
    </section>
  );
};

export default EditServiceData;
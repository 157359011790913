/** @format */

import React, { useContext, useState } from "react";
import { FaTimes } from "react-icons/fa";
import facebook from "../../../assets/Login/facebook.ico";
import google from "../../../assets/Login/google.ico";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../Contexts/UserContext/UserContext";
import CommonButton from "../../../components/CommonButton/CommonButton";

const SignUpModal = ({
  handleCreateUser,
  handleGoogleSignIn,
  handleFacebookSignIn,
}) => {
  const { setSignUpModal, autoModal, setAutoModal, setLoginModal } =
    useContext(AuthContext);
  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toggleLogin = () => {
    setSignUpModal(false);
    if (autoModal) {
      setAutoModal(false);
    }
    setLoginModal(true);
  };

  return (
    <div>
      <input type="checkbox" id="signUpModal" className="modal-toggle" />
      <div
        data-aos="zoom-in"
        data-aos-duration="200"
        data-aos-easing="ease-in-cubic"
        className="flex justify-center items-center fixed top-0 left-0 right-0 bottom-0 z-[999]"
      >
        <div className="modal-box bg-white lg:w-[450px] w-[400px]">
          <div className="relative py-0 rounded-lg">
            <span className="absolute hover:bg-gray-400 rounded-full p-1 right-4 top-3">
              <FaTimes
                onClick={() => setSignUpModal(false)}
                className="text-neutral text-xl"
              ></FaTimes>
            </span>
            <h2 className="text-center mb-8 lg:mb-14 text-4xl text-gray-900">
              SignUp
            </h2>
            <form
              onSubmit={handleSubmit(handleCreateUser)}
              className="lg:px-6 px-2 flex flex-col gap-4"
              action=""
            >
              <div>
                <label className="text-gray-700 font-medium" htmlFor="name">
                  Enter Your Name
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Type here"
                  className="custom-text-input !py-3"
                  {...register("name", { required: "Name is required" })}
                />
                {errors.name && (
                  <span className="text-error">{errors.name?.message}</span>
                )}
              </div>
              <div>
                <label className="text-gray-700 font-medium" htmlFor="name">
                  Enter Your Email
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Type here"
                  className="custom-text-input !py-3"
                  {...register("email", { required: "Email is required" })}
                />
                {errors.email && (
                  <span className="text-error">{errors.email?.message}</span>
                )}
              </div>
              <div>
                <label className="text-gray-700 font-medium" htmlFor="name">
                  Enter Your password
                </label>
                <input
                  type="password"
                  name="password"
                  placeholder="Type here"
                  className="custom-text-input !py-3"
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
                {errors.password && (
                  <span className="text-error">{errors.password?.message}</span>
                )}
              </div>
              <div className="divider my-0 font-medium">OR</div>
              {error && <span className="text-error text-center">{error}</span>}
              <div className="flex justify-center gap-5">
                <img
                  className="w-9 h-9 cursor-pointer"
                  onClick={handleGoogleSignIn}
                  src={google}
                  alt=""
                />
                <img
                  className="w-9 h-9 cursor-pointer"
                  onClick={handleFacebookSignIn}
                  src={facebook}
                  alt=""
                />
              </div>
              <p className="text-base mt-3">
                Already have an account? Please{" "}
                <span
                  onClick={toggleLogin}
                  className="text-primary underline mx-2 text-lg font-medium cursor-pointer"
                >
                  login
                </span>
                here.
              </p>
              <CommonButton customClass={"!text-xl"} type={"submit"}>
                Signup
              </CommonButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpModal;

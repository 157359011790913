/** @format */

import React, { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
import {
  addServiceInitialState,
  addServiceReducer,
} from "../../../../state/AddServiceReducer/AddServiceReducer";
import { addServiceAction } from "../../../../state/AddServiceReducer/AddServiceActionTypes";
import { v4 } from 'uuid';

export const ADD_SERVICE_CONTEXT = createContext();
const AddNewService = () => {
  const [state, newDispatch] = useReducer(
    addServiceReducer,
    addServiceInitialState
  );
  

  useEffect(() => {
    newDispatch({type: addServiceAction.SET_STORAGE_ID, payload:v4()})
  }, [])

  const data = {
    state,
    newDispatch,
  };

  return (
    <section>
      <h2 className="text-gray-800 my-10 text-3xl font-semibold underline">
        Add new service
      </h2>

      <div className="flex flex-col gap-10">
        {/* <ul className="my-7 items-center lg:w-3/4 w-full mx-auto active:!top-0 custom-file-label">
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "text-secondary w-full mx-auto font-medium lg:text-lg underline"
                : " mx-auto w-full lg:text-lg"
            }
            to="/dashboard/addService/uploadData"
          >
            Upload data
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "text-secondary w-full ml-5 lg:ml-28 font-medium lg:text-lg underline"
                : " ml-5 lg:ml-28 w-full lg:text-lg"
            }
            to="/dashboard/addService/imageUpload"
          >
            Upload Image
          </NavLink>
        </ul> */}
  

        <div>
          <ADD_SERVICE_CONTEXT.Provider value={data}>
            <Outlet></Outlet>
          </ADD_SERVICE_CONTEXT.Provider>
        </div>
      </div>
    </section>
  );
};

export default AddNewService;

/** @format */

import { useEffect } from "react";
import { useState } from "react";

const useResizeImage = (mainImage, width, percentage) => {
  const [newImage, setNewImage] = useState([]);
  const [cleanUp, setCleanUp] = useState(false);

  useEffect(() => {
    if (mainImage?.length === 0 || !width || !percentage) {
      return;
    }

    if (mainImage?.length >= 1) {
      for (let value of Object.values(mainImage)) {
        const reader = new FileReader();
        reader.readAsDataURL(value);

        reader.onload = (event) => {
          const image_url = event.target.result;
          let image = document.createElement("img");
          image.src = image_url;

          image.onload = (e) => {
            let canvas = document.createElement("canvas");
            let ratio;

            if (e.target.width > width) {
              ratio = width / e.target.width;
              canvas.width = width;
            } else {
              ratio = 1;
              canvas.width = e.target.width;
            }

            ratio = width / e.target.width;
            canvas.width = width;

            canvas.height = ratio * e.target.height;

            const context = canvas.getContext("2d");
            context.drawImage(image, 0, 0, canvas.width, canvas.height);

            let new_image_url = context.canvas.toDataURL(
              "image/png",
              percentage
            );

            let arr = new_image_url.split(",");
            let imgName = arr[0].match(/:(.*?);/)[1];
            let data = arr[1];

            let dataStr = atob(data);
            let n = dataStr.length;

            let dataArr = new Uint8Array(n);
            while (n--) {
              dataArr[n] = dataStr.charCodeAt(n);
            }

            let file = new File([dataArr], "file.png", { type: imgName });

            if (!cleanUp) {
              setNewImage((prev) => [...prev, file]);
            }
          };
        };
      }
    }
    return () => {
      setCleanUp(true);
    };
  }, [mainImage, percentage, width, cleanUp]);

  return newImage;
};

export default useResizeImage;

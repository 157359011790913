
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useContext } from "react";
import { createContext } from "react";
import Loading from "../../../components/Loading/Loading";
import { AuthContext } from "../../../Contexts/UserContext/UserContext";
import Dashboard from "./Dashboard";


export const DASHBOARD_CONTEXT = createContext();

const DashboardAPI = () => {
  const { logOut } = useContext(AuthContext);

  /* ======================= GEL TOTAL OVERVIEW ================ */
  const { data: OVERVIEW = [] } = useQuery({
    queryKey: ["overview"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/admin/totalView`,
        {
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      const data = await response.json();
      if (response.status === 401 || response.status === 403) {
        return logOut();
      }
      return data;
    },
  });

  /* ======================= GEL SERVICE OVERVIEW ================ */
  const { data: SERVICE_VIEW = [], isLoading } = useQuery({
    queryKey: ["SERVICE_VIEW"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/admin/serviceView`,
        {
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      const data = await response.json();
      if (response.status === 401 || response.status === 403) {
        return logOut();
      }
      return data;
    },
  });

  /* ======================= GEL SERVICE OVERVIEW ================ */
  const { data: PROJECT_VIEW = [] } = useQuery({
    queryKey: ["PROJECT_VIEW"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/admin/projectView`,
        {
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
        const data = await response.json();
      if (response.status === 401 || response.status === 403) {
        return logOut();
      }
      return data;
    },
  });

  /* ======================= GEL BLOG OVERVIEW ================ */
  const { data: BLOGS_VIEW = [] } = useQuery({
    queryKey: ["BLOGS_VIEW"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/admin/blogView`,
        {
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      const data = await response.json();
      if (response.status === 401 || response.status === 403) {
        return logOut();
      }
      return data;
    },
  });
    
  /* ======================= GEL USER OVERVIEW ================ */
  const { data: USER_VIEW = [] } = useQuery({
    queryKey: ["USER_VIEW"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/admin/userView`,
        {
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
          },
        }
      );
      const data = await response.json();
      if (response.status === 401 || response.status === 403) {
        return logOut();
      }
      return data;
    },
  });

    const data = { SERVICE_VIEW, PROJECT_VIEW, BLOGS_VIEW, USER_VIEW, OVERVIEW };
    

  if (isLoading) {
    return <Loading>Loading..</Loading>;
  }
  return (
    <div>
      <DASHBOARD_CONTEXT.Provider value={data}>
        <Dashboard></Dashboard>
      </DASHBOARD_CONTEXT.Provider>
    </div>
  );
};

export default DashboardAPI;
/** @format */

import React from "react";
import { FaMapMarkerAlt, FaPhone, FaPaperPlane, FaGlobe } from "react-icons/fa";
import UseTitle from "../../Hooks/UseTitle/UseTitle";
import HeadingText from "../../components/HeadingText/HeadingText";

const ContactMe = () => {
  UseTitle("JE-Contact");

  const contacts = [
    {
      name: "Address",
      value: "Gazipur, BOF-1703, Gazipur Sadar, Gazipur",
      icon: <FaMapMarkerAlt />,
    },
    {
      name: "Phone",
      value: "+880 1611542262",
      icon: <FaPhone />,
    },
    {
      name: "Email",
      value: "zahidh87@gmail.com",
      icon: <FaPaperPlane />,
    },
    {
      name: "Website",
      value: "jahidenterprise.com",
      icon: <FaGlobe />,
    },
  ];

  return (
    <section className="" id="contact">
      <div className="lg:max-w-screen-xl mb-32 lg:mt-48 mt-28 w-[90%] mx-auto">
        <p className="text-secondary text-center text-xl font-medium uppercase">
          Contact Me
        </p>
        <HeadingText
          heading={"Have a project?"}
          description={
            "If you have a project on your mind, don't hesitate to reach out to me! I am always open to hearing new ideas and am happy to help bring your project to fruition."
          }
          descriptionClass={"text-gray-700"}
        ></HeadingText>

        <div className="grid lg:grid-cols-2 justify-center gap-5 bg-gray-300 p-5 rounded-sm">
          {contacts.map((contact, index) => (
            <div key={index} className="flex items-center gap-3 ">
              <div className="hover:bg-opacity-75 text-base-100 bg-secondary rounded-full grid place-items-center h-12 w-12">
                {contact.icon}
              </div>
              <div className="text-start">
                <p className="text-xl font-standard font-medium">
                  {contact.name}:
                </p>
                <p className="lg:text-xl text-lg font-premium text-gray-700">
                  {contact.value}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ContactMe;

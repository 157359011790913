
import React, { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { CgDanger } from "react-icons/cg";
import { GrUpgrade } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../../../../components/CommonButton/CommonButton";
import Loading from "../../../../../components/Loading/Loading";
import { AuthContext } from "../../../../../Contexts/UserContext/UserContext";
import EditBlogGallery from "./EditBlogGallery";
import { EDIT_BLOGS_CONTEXT } from "./EditBlogs";
import EditBlogThumb from "./EditBlogThumb";
import UseDeviceWidth from "../../../../../Hooks/UseDevice/UseDeviceWidth";

const EditBlogData = () => {
  const { blog, loading, newGallery } = useContext(EDIT_BLOGS_CONTEXT);
  const { logOut } = useContext(AuthContext);
  const { title, description } = blog;
  const [updateLoading, setUpdateLoading] = useState(false);
  const navigate = useNavigate();
  const blogId = localStorage.getItem("BlogId");
  const mobile = UseDeviceWidth();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    reset({
      title,
      description,
    });
  }, [title, description, reset]);

  if (loading) {
    return <Loading>Loading documents...</Loading>;
  }

  /* ======================== Save the data to the database ========================= */
  const handleUpdateBlog = (data) => {
    setUpdateLoading(true);

    data.postingTime = new Date().toLocaleDateString();
    delete blog._id;
    blog.gallery = newGallery;
    data = { ...blog, ...data };

    fetch(`${process.env.REACT_APP_SERVER_URL}/admin/blog/${blogId}/update`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        console.log(res);
        if (res.status === 401 || res.status === 403) {
          return logOut();
        }
        return res.json();
      })
      .then((data) => {
        if (data.acknowledged) {
          setUpdateLoading(false);
          toast.success("Your blogs has been modified successfully");
          navigate(`/blog/${blogId}`);
          localStorage.removeItem("BlogId");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <section>
      {updateLoading ? (
        <Loading>Updating please wait...</Loading>
      ) : (
        <section className="relative pt-14 pb-32">
          <h2 className="text-gray-800 font-stylish mt-10 text-4xl font-semibold">
            Update your blog
          </h2>
          <p className="w-28 h-1 mb-16 lg:ml-[10%] mt-2 ml-[25%]  bg-primary"></p>
          <div>
            <form onSubmit={handleSubmit(handleUpdateBlog)} className="mt-20">
              {/* ===================== Title ============================= */}

              <div className="relative mb-14 flex flex-col items-center gap-2">
                <div className="w-full gap-10 flex items-center">
                  <h2 className="text-xl font-premium text-gray-800 flex font-medium">
                    Title: <span className="text-red-600 ml-1 -mt-1">*</span>
                  </h2>
                  <input
                    defaultValue={title}
                    type="text"
                    id="title"
                    placeholder="Enter service title here"
                    className="custom-text-input font-standard"
                    {...register("title", { required: "Title is required" })}
                  />
                </div>
                {errors.title && (
                  <p className="flex flex-row items-center gap-3">
                    <CgDanger className="text-error" size={20} />
                    <span className="text-red-600 font-standard text-sm tracking-widest">
                      {errors.title?.message}
                    </span>
                  </p>
                )}
              </div>

              {/* 
            {/* =============================== DESCRIPTION ================================= */}

              <div className="relative mb-10 flex flex-col items-center gap-2">
                <div className="flex w-full items-start lg:flex-row flex-col lg:gap-5 gap-2">
                  <h2 className="text-xl font-premium flex  text-gray-800 font-medium">
                    Description:{" "}
                    <span className="text-red-600 ml-1 -mt-1">*</span>
                  </h2>
                  <textarea
                    defaultValue={description}
                    id="description"
                    placeholder="Write service description"
                    className="custom-text-input font-standard"
                    cols="30"
                    rows={mobile ? "20" : "10"}
                    {...register("description", {
                      required: "This field is required",
                    })}
                  ></textarea>
                </div>
                {errors.description && (
                  <p className="flex flex-row items-center gap-3">
                    <CgDanger className="text-error" size={20} />
                    <span className="text-red-600 font-standard text-sm tracking-widest">
                      {errors.description?.message}
                    </span>
                  </p>
                )}
              </div>

              <div className="w-full mt-10 text-right absolute bottom-0 right-0">
                <CommonButton customClass={"tracking-[3px]"} type={"submit"}>
                  Update blogs
                  <GrUpgrade className="ml-2" />
                </CommonButton>
              </div>
            </form>
          </div>
          {/* ==================== THUMB UPLOAD ================ */}
          <EditBlogThumb></EditBlogThumb>

          {/* ===================== GALLERY UPLOAD ================ */}
          <EditBlogGallery></EditBlogGallery>
        </section>
      )}
    </section>
  );
};

export default EditBlogData;

/** @format */

import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { CgDanger } from "react-icons/cg";
import { ADD_BLOGS_CONTEXT } from "./AddNewBlogs";
import BlogGalleryUpload from "./BlogGalleryUpload";
import BlogThumbUpload from "./BlogThumbUpload";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../../../../components/CommonButton/CommonButton";
import { AuthContext } from "../../../../../Contexts/UserContext/UserContext";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import { ImageDrop } from "quill-image-drop-module";
import "react-quill/dist/quill.snow.css";
import { useState } from "react";

Quill.register(
  {
    "modules/imageResize": ImageResize,
    "modules/imageDrop": ImageDrop,
  },
  true
);

const UploadBlogData = () => {
  const { newBlogs, newGallery } = useContext(ADD_BLOGS_CONTEXT);
  const { logOut } = useContext(AuthContext);
  const [value, setValue] = useState("");

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toolbarOptions = [
    [{ header: "1" }, { header: "2" }],
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: [1, 2, 3, 4, 5, 6, false] }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }, { size: ["small", false, "large", "huge"] }],
    [{ align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ];

  const modules = {
    toolbar: toolbarOptions,
    imageResize: {
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
    imageDrop: true,
  };

  /* ======================== Save the data to the database ========================= */
  const handleAddNewBlog = (data) => {
    data.gallery = [...newGallery];
    data = { ...newBlogs, ...data, description: value };

    // console.log(data);
    // console.log(value);

    fetch(`${process.env.REACT_APP_SERVER_URL}/admin/blog/newBlog`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 401 || res.status === 403) {
          return logOut();
        }
        return res.json();
      })
      .then((data) => {
        if (data.acknowledged) {
          toast.success("New blog added successfully");
          navigate(`/allBlogs`);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <section className="relative lg:pt-0 lg:pb-20 pb-10 pt-0">
      <div>
        <form onSubmit={handleSubmit(handleAddNewBlog)} className="lg:mt-20">
          {/* ===================== Title ============================= */}

          <div className="relative mb-14 flex flex-col items-center gap-2">
            <div className="w-full gap-10 flex items-center">
              <h2 className="text-xl font-premium text-gray-800 flex font-medium">
                Title: <span className="text-red-600 ml-1 -mt-1">*</span>
              </h2>
              <input
                type="text"
                id="title"
                placeholder="Enter blog title here"
                className="custom-text-input font-standard"
                {...register("title", { required: "Title is required" })}
              />
            </div>
            {errors.title && (
              <p className="flex flex-row items-center gap-3">
                <CgDanger className="text-error" size={20} />
                <span className="text-red-600 font-standard text-sm tracking-widest">
                  {errors.title?.message}
                </span>
              </p>
            )}
          </div>

          {/* 
            {/* =============================== DESCRIPTION ================================= */}
          {/* 
          <div className="relative mb-10 flex flex-col items-center gap-2">
            <div className="flex w-full items-start lg:flex-row flex-col lg:gap-5 gap-2">
              <h2 className="text-xl font-premium flex  text-gray-800 font-medium">
                Description: <span className="text-red-600 ml-1 -mt-1">*</span>
              </h2>
              <textarea
                id="description"
                placeholder="Write service description"
                className="custom-text-input font-standard"
                cols="30"
                rows="10"
                {...register("description", {
                  required: "This field is required",
                })}
              ></textarea>
            </div>
            {errors.description && (
              <p className="flex flex-row items-center gap-3">
                <CgDanger className="text-error" size={20} />
                <span className="text-red-600 font-standard text-sm tracking-widest">
                  {errors.description?.message}
                </span>
              </p>
            )}
          </div> */}

          <ReactQuill
            className=" resize-y overflow-y-scroll min-h-16"
            theme="snow"
            value={value}
            onChange={(e) => setValue(e)}
            modules={modules}
          />

          <div className="w-full mt-10 text-right absolute bottom-0 right-0">
            <CommonButton customClass={"tracking-[3px]"} type={"submit"}>
              Add blogs
              <FaPlus className="ml-2" />
            </CommonButton>
          </div>
        </form>
      </div>
      {/* ==================== THUMB UPLOAD ================ */}
      <BlogThumbUpload></BlogThumbUpload>

      {/* ===================== GALLERY UPLOAD ================ */}
      <BlogGalleryUpload></BlogGalleryUpload>
    </section>
  );
};

export default UploadBlogData;


import React from "react";
import { useState, useEffect, useContext } from "react";
import { ADD_PROJECT_CONTEXT } from "../AddNewProject";
import { HiOutlineUpload } from "react-icons/hi";
import useResizeImage from "../../../../../Hooks/ResizeImage/useResizeImage";
import useUploadImage from "../../../../../Hooks/UploadImage/useUploadImage";
import { newProjectData } from "../../../../../state/AddProjectReducer/AddProjectActionCreator";

const UploadProjectThumb = () => {

  const {
    state: { newProject, storageId },
    newDispatch,
  } = useContext(ADD_PROJECT_CONTEXT);
  const { thumbnail } = newProject;

  const [file, setFile] = useState(null);

  const compressedImage = useResizeImage(file, 720, 70);

  const [url, progress] = useUploadImage(
    compressedImage,
    "Projects",
    storageId,
    false
  );

  useEffect(() => {
    if (url?.length > 0) {
      newDispatch(
        newProjectData({
          ...newProject,
          thumbnail: url[0],
        })
      );
    }
  }, [url]);


    return (
      <section className="flex flex-col items-center gap-10 lg:gap-20 w-full">
        <div className="w-full">
          <h2 className="lg:text-2xl mb-5 text-lg w-full text-start text-gray-800 font-semibold">
            Thumbnail:
          </h2>
          <div className="columns-1">
            <img src={thumbnail?.url} className="lg:w-3/5" alt="" />
          </div>
        </div>

        <div className="lg:w-2/5 w-3/5">
          <input
            onChange={(e) => setFile(e.target.files)}
            type="file"
            id="thumbnail"
            className="opacity-0 relative w-1 h-1"
            required
          />
          {progress !== null ? (
            <progress
              className="progress h-1 mt-5 progress-success w-full"
              value={progress}
              max="100"
            ></progress>
          ) : (
            <label
              title="upload cover image"
              htmlFor="thumbnail"
              className="custom-file-label !font-premium  tracking-[2px]"
            >
              <HiOutlineUpload size={30} />
              Select Thumbnail
            </label>
          )}
        </div>
      </section>
    );
};

export default UploadProjectThumb;

import { editProjectActions } from "./EditProjectActions";

export const editProjectInitialState = {
  loading: false,
  error: false,
  project: {},
  errorMessage: "",
  newMotive: [],
  addNewMotive: false,
  newMotiveValue: [""],
  editMotive: [],
  deleteMotiveIndex: [null],
  newGallery: [],
  deleteGalleryIndex: [null],
  updateProject: {},
  updateLoading: false,
};

export const editProjectReducer = (state, action) => {
  switch (action.type) {
    case editProjectActions.FETCHING_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case editProjectActions.FETCHING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        project: action.payload,
        updateProject: { ...state.updateProject, ...action.payload },
      };
    case editProjectActions.FETCHING_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case editProjectActions.SET_MOTIVE:
      return {
        ...state,
        newMotive: action.payload,
      };
    case editProjectActions.ADD_NEW_MOTIVE:
      return {
        ...state,
        addNewMotive: action.payload,
      };
    case editProjectActions.NEW_MOTIVE_VALUE:
      return {
        ...state,
        newMotiveValue: action.payload,
      };
    case editProjectActions.EDIT_MOTIVES:
      return {
        ...state,
        editMotive: action.payload,
      };
    case editProjectActions.DELETE_MOTIVE_INDEX:
      return {
        ...state,
        deleteMotiveIndex: action.payload,
      };
    case editProjectActions.NEW_GALLERY:
      return {
        ...state,
        newGallery: [...state.newGallery, ...action.payload],
      };
    case editProjectActions.DELETE_GALLERY_INDEX:
      return {
        ...state,
        deleteGalleryIndex: action.payload,
      };
    case editProjectActions.SET_UPDATE_PROJECT:
      return {
        ...state,
        updateProject: action.payload,
      };
    case editProjectActions.SET_UPDATE_LOADING:
      return {
        ...state,
        updateLoading: action.payload,
      };

    default:
      return state;
  }
};

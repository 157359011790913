
/** @format */

import { useQuery } from "@tanstack/react-query";
import { deleteObject, ref } from "firebase/storage";
import React, { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { FaComment, FaEdit, FaHeart, FaPlus, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../../../../../components/CommonButton/CommonButton";
import ConfirmationModal from "../../../../../components/ConfirmationModal/ConfirmationModal";
import Loading from "../../../../../components/Loading/Loading";
import { AuthContext } from "../../../../../Contexts/UserContext/UserContext";
import { storage } from "../../../../../Firebase/Firebase.config";


const ManageBlogs = () => {
  const [deleteBlog, setDeleteBlog] = useState([]);
  const navigate = useNavigate();
  const { logOut } = useContext(AuthContext);

  /* ============================= GET ALL BLOGS ===================== */
  const {
    data: Blogs = [],
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["myBlogs"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/blogs`);

      const data = await response.json();
      return data;
    },
  });

  if (isLoading) {
    return <Loading>Loading...</Loading>;
  }

  const navigateWithId = (id) => {
    localStorage.setItem("BlogId", id);
    navigate(`/dashboard/blogs/${id}`);
  };

  /* ============================= DELETE BLOG ========================== */

  const handleDelete = () => {

    let [id, storageId] = deleteBlog;

    let imgLocations = [];
    const findBlog = Blogs.find((blog) => blog._id === id);
    const { thumbnail, gallery } = findBlog;

    imgLocations.push(thumbnail.imageId);

    if (gallery?.length > 0) {
      gallery.forEach((data) => {
        imgLocations.push(data.imageId);
      });
    }

    imgLocations.forEach((location) => {
      let deleteRef = ref(storage, `Blogs/${storageId}/${location}`);

      deleteObject(deleteRef)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    });

    fetch(`${process.env.REACT_APP_SERVER_URL}/admin/deleteBlog/${id}`, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
      },
    })
      .then((res) => {
        if (res.status === 401 || res.status === 403) {
          return logOut();
        }
        return res.json();
      })
      .then((data) => {
        if (data.acknowledged) {
          refetch();
          setDeleteBlog([]);
          toast.success("Blog deleted successfully");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  

  return (
    <section>
      <h2 className="text-4xl font-semibold mt-16 font-stylish">My Blogs</h2>
      <p className="w-20 h-1 mb-16 lg:ml-5 mt-2 ml-8  bg-primary"></p>
      <div className="flex items-center flex-row gap-5 lg:gap-0 justify-between mb-10 ">
        <div className="flex gap-5 items-center">
          <p className="text-lg">
            Total Blogs :{" "}
            <span className="text-2xl font-semibold">{Blogs?.length}</span>
          </p>
        </div>
        <div>
          <Link to="/dashboard/addBlog">
            <CommonButton>
              Add new Blogs
              <FaPlus className="ml-2" />
            </CommonButton>
          </Link>
        </div>
      </div>

      <div>
        {Blogs?.map(
          (
            {
              _id,
              thumbnail,
              title,
              description,
              postingTime,
              admin,
              likes,
              comments,
              storageId,
            },
            i
          ) => (
            <div key={i} className="flex items-start lg:gap-5 gap-2">
              <h2 className="text-2xl font-semibold">{i + 1}</h2>
              <div
                key={_id}
                className="flex w-screen lg:flex-row flex-col bg-gray-50 items-center gap-0 lg:gap-4 justify-between mb-8 rounded-lg  p-3 shadow-2xl"
              >
                <figure className="lg:w-[20%] mx-auto">
                  <img
                    className="rounded-xl lg:h-[130px] bg-white lg:w-full w-[350px] h-[200px]"
                    src={thumbnail?.url}
                    alt=""
                  />
                </figure>
                <div className="lg:w-1/2 pb-0 mt-5 lg:mt-0">
                  <h2 className="text-xl font-stylish text-gray-800 font-semibold mb-3">
                    {title}
                  </h2>
                  <p className="text-base font-standard text-gray-600">
                    {description?.slice(0, 100)}...
                  </p>
                  <div className="mt-3 flex  items-center justify-center lg:justify-start ">
                    <p
                      title="edit service"
                      onClick={() => navigateWithId(_id)}
                      className="hover:bg-gray-300 hover:scale-105 cursor-pointer transition-all duration-300  grid place-items-center rounded-full w-12 h-12"
                    >
                      <FaEdit className="text-xl" />
                    </p>
                    <label
                      title="delete service"
                      onClick={() => setDeleteBlog([_id, storageId])}
                      htmlFor="confirmModal"
                      className="hover:bg-gray-300 hover:scale-95 cursor-pointer transition-all duration-300 lg:mr-8 grid place-items-center rounded-full w-12 h-12"
                    >
                      <FaTrash className="text-xl" />
                    </label>
                  </div>
                </div>
                <div className="lg:w-[30%] text-center">
                  <div className="mb-8">
                    <p className="text-lg h-5 font-premium font-medium text-gray-800">
                      {admin}
                    </p>
                    <p className="text-md h-1 font-medium text-gray-600">
                      {postingTime}
                    </p>
                  </div>
                  <div className="flex lg:flex-col flex-row items-center lg:gap-1 gap-8">
                    <p className="flex justify-center gap-2 flex-row items-center">
                      <FaHeart className="text-xl text-pink-500" />
                      <span className="text-xl font-premium  text-primary">
                        {likes?.length}
                      </span>
                    </p>
                    <p className="flex justify-center gap-2 flex-row items-center">
                      <FaComment className="text-xl text-blue-500" />
                      <span className="text-xl font-premium  text-primary">
                        {comments?.length}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>

      <ConfirmationModal
        actionText={"Delete"}
        modalText={"Are you sure you want to delete this blogs"}
        action={handleDelete}
      ></ConfirmationModal>
    </section>
  );
};

export default ManageBlogs;

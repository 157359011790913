
import React from 'react';
import projectOnMind from '../../../assets/ProjectOnMind/My project.png';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import UseDeviceWidth from '../../../Hooks/UseDevice/UseDeviceWidth';
const ProjectOnMind = () => {

  const mobile = UseDeviceWidth();
  

    return (
      <section
        data-aos={mobile ? 'fade-up' : 'flip-up'}
        data-aos-duration="1500"
        data-easing="ease-in-cubic"
        className="bg-primary my-32 pt-5"
      >
        <div className="lg:max-w-screen-xl mx-auto flex lg:flex-row flex-col lg:gap-10 items-center">
          <div className="lg:w-3/5 w-10/12 text-start py-5">
            <h1
              data-aos="fade-up"
              data-aos-duration="1500"
              data-easing="ease-in-cubic"
              className="lg:text-5xl text-4xl  font-premium text-base-100 font-light"
            >
              Bring Your Ideas to Life
            </h1>
            <p className="w-28 lg:w-36 h-[2px] bg-accent mt-2 mb-10 absolute left-[36%] lg:left-[20%]"></p>
            <div className="text-base my-10 font-standard text-gray-400">
              <p>
                Got a project on your mind that you're itching to get started
                on? Look no further! My team and I are eager to help make your
                ideas a reality.
              </p>{" "}
              <br />
              <p>
                Whether it's a small-scale home renovation or a large commercial
                endeavor, we have the experience and expertise to bring your
                vision to life. Don't hesitate to get in touch with us – we
                can't wait to hear from you!
              </p>
            </div>
            <NavLink
              smooth
              to="/contact"
              className="btn glass text-base-100 rounded-md px-10"
            >
              Contact Me
            </NavLink>
          </div>
          <div className="lg:w-2/5 ">
            <img
              className="lg:-mt-48 h-[530px]  lg:ml-20 w-full mr-16 lg:mr-0"
              src={projectOnMind}
              alt=""
            />
          </div>
        </div>
      </section>
    );
};

export default ProjectOnMind;
/** @format */

import { useContext } from "react";
import React from "react";
import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { DASHBOARD_CONTEXT } from "../DashboardAPI";
import UseDeviceWidth from "../../../../Hooks/UseDevice/UseDeviceWidth";

const ServiceView = () => {
  const mobile = UseDeviceWidth();
    const { SERVICE_VIEW } = useContext(DASHBOARD_CONTEXT);
    

  return (
    <section className="my-20 overflow-hidden">
      <div>
        <h1 className="lg:text-4xl text-3xl font-premium mb-10">
          Service Analytics
        </h1>
      </div>
      <div className="flex gap-5 lg:flex-row flex-col">
        <div className="lg:flex lg:flex-col grid grid-cols-2 lg:gap-5 gap-3 lg:w-1/4">
          {SERVICE_VIEW?.length > 0 &&
            SERVICE_VIEW?.slice(0, 4).map(({ value, count }, i) => (
              <div
                key={i}
                className="py-5 rounded-2xl relative serviceView text-center shadow-2xl"
              >
                <h3 className="lg:text-xl text-base lg:mb-3 font-standard text-gray-800">
                  {value}
                </h3>
                <p className="text-3xl font-stylish font-bold text-primary">
                  {count}
                </p>
              </div>
            ))}
        </div>
        <div className="lg:w-3/4 w-[400px] flex lg:flex-col flex-col-reverse gap-10">
          <div className="h-[300px] lg:h-[370px] overflow-hidden">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                width={500}
                height={400}
                data={SERVICE_VIEW}
                margin={{
                  top: 20,
                  right: mobile ? 0 : 40,
                  bottom: 20,
                  left: 0,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis
                  dataKey="value"
                  label={{
                    value: "name",
                    position: "insideBottomRight",
                    offset: 0,
                  }}
                  scale="band"
                />
                <YAxis
                  label={{ value: "value", angle: -90, position: "insideLeft" }}
                />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="count"
                  fill="#8884d8"
                  stroke="#8884d8"
                />
                <Bar dataKey="count" barSize={20} fill="rgb(2, 67, 135)" />
              </ComposedChart>
            </ResponsiveContainer>
          </div>

          <div className="lg:flex lg:flex-row lg:ml-5 grid grid-cols-2 lg:w-[70%] w-full lg:gap-5 gap-3">
            {SERVICE_VIEW?.slice(4, SERVICE_VIEW.length).map(
              ({ value, count }, i) => (
                <div
                  key={i}
                  className="lg:w-10/12 py-5 relative serviceView rounded-2xl text-center shadow-2xl"
                >
                  <h3 className="lg:text-xl text-base lg:mb-3 font-standard text-gray-800">
                    {value}
                  </h3>
                  <p className="text-3xl font-stylish font-bold text-primary">
                    {count}
                  </p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceView;

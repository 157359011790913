import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Link } from "react-router-dom";
import CommonButton from "../../../components/CommonButton/CommonButton";
import HeadingText from "../../../components/HeadingText/HeadingText";

const MyBlog = () => {
  const { data: blogs = [] } = useQuery({
    queryKey: ["blogs"],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/blogs?data=3`
      );
      const data = await response.json();
      return data;
    },
  });

  return (
    <section id="blog" className="bg-white my-24">
      <div className="lg:max-w-screen-xl w-[90%] mx-auto py-24">
        <HeadingText
          heading={"My Insights"}
          description={
            "Step into the world of contracting insights and inspirations with my blog section. Here, you will find a treasure trove of expert knowledge, personal experiences, and out-of-the-box perspectives that will challenge and enlighten you. My blogs offer a unique blend of analysis and creativity."
          }
          descriptionClass={"text-gray-700"}
        ></HeadingText>

        <div className="grid lg:grid-cols-3 grid-cols-1 gap-10 lg:gap-8">
          {blogs?.length >= 1 &&
            blogs?.map(
              (
                { _id, thumbnail, postingTime, admin, title, description },
                i
              ) => (
                <div
                  key={i}
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-easing="ease-in-cubic"
                  className="card lg:h-[550px] w-full lg:w-[350px] bg-base-100 shadow-xl rounded-xl"
                >
                  <div className="h-[250px] rounded-t-xl">
                    <Link to={`/blog/${_id}`}>
                      <img
                        src={thumbnail?.url}
                        className=" rounded-xl h-full"
                        alt="Shoes"
                      />
                    </Link>
                  </div>
                  <div className="bg-white py-2 px-3 lg:h-[300px] relative text-start">
                    <p className="flex gap-5 mb-5  items-center">
                      <span className="font-premium tracking-wider">
                        {postingTime}
                      </span>
                      <span className="text-gray-700 font-standard">
                        {admin}
                      </span>
                    </p>

                    <Link
                      onClick={() =>
                        window.scrollTo({ top: 0, behavior: "smooth" })
                      }
                      to={`/blog/${_id}`}
                    >
                      <h2 className="card-title leading-5 font-standard hover:text-primary hover:underline text-xl lg:text-2xl font-medium mb-10 lg:mb-8">
                        {title}
                      </h2>
                    </Link>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: description?.slice(0, 120),
                      }}
                    ></p>
                  </div>
                </div>
              )
            )}
        </div>
        <div className="mt-16 text-center">
          <Link
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            to="/allBlogs"
          >
            <CommonButton
              type={"button"}
              customClass={"lg:w-1/4 w-1/2 tracking-[3px]"}
            >
              See All
            </CommonButton>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default MyBlog;

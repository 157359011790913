
/** @format */

import React, { createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { v4 } from "uuid";

export const ADD_BLOGS_CONTEXT = createContext();
const AddNewBlogs = () => {
  const [newBlogs, setNewBlogs] = useState({
    index: 0,
    title: "",
    description: "",
    likes: [],
    comments: [],
    reply: [],
    postingTime: "",
    admin: "Jahid Hasan",
    thumbnail: {},
    storageId: v4(),
  });
  const [newGallery, setNewGallery] = useState([]);

  const data = { newBlogs, setNewBlogs, setNewGallery, newGallery };

  return (
    <section>
      <h2 className="text-gray-800 font-stylish mt-10 text-4xl font-semibold">
        Add new Blog
      </h2>
      <p className="w-28 h-1 mb-16 lg:ml-[5%] mt-2 ml-[15%]  bg-primary"></p>

      <div className="flex flex-col gap-10">
        <div>
          <ADD_BLOGS_CONTEXT.Provider value={data}>
            <Outlet></Outlet>
          </ADD_BLOGS_CONTEXT.Provider>
        </div>
      </div>
    </section>
  );
};

export default AddNewBlogs;

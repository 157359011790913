import React from "react";
import { FaFacebook, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import { NavHashLink as NavLink } from "react-router-hash-link";
import logo from "../../../assets/Footer/logo.png";

const Footer = () => {
  const linkStyle =
    "footerLink relative inline-flex justify-center text-gray-300 hover:text-accent text-lg tracking-widest";
  return (
    <section className="mt-20 overflow-hidden">
      <footer className="lg:px-10 px-5 pt-10 pb-5 bg-primary">
        <div className="flex lg:flex-row flex-col items-center gap-10">
          <div className="lg:w-1/3 w-full text-center">
            <img src={logo} className="mb-3 mx-auto w-20" alt="" />
            <p className="text-4xl font-stylish mb-5 text-accent">
              Jahid <span className="text-base-100 text-2xl">Enterprise</span>
            </p>
            <p className="text-gray-400 lg:mb-5 mb-3 font-standard text-base">
              Don't wait for opportunities, create them. Let's collaborate and
              make a positive impact in the world. Remember, every great
              achievement starts with a single step. So take that step today,
              and work together to create a brighter future for you and those
              around you.
            </p>
          </div>
          <div className="flex flex-col lg:gap-4 gap-2 lg:w-1/3 w-full">
            <NavLink to="/#projectSample" smooth className={linkStyle}>
              Projects
            </NavLink>
            <NavLink to="/#service" smooth className={linkStyle}>
              Services
            </NavLink>
            <NavLink
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              to="/privacy"
              smooth
              className={linkStyle}
            >
              Privacy
            </NavLink>
            <NavLink
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              to="/terms"
              smooth
              className={linkStyle}
            >
              Terms
            </NavLink>
          </div>

          <div className="lg:w-1/3 w-full">
            <h2 className="lg:text-xl text-lg text-center font-standard text-gray-400 mb-5">
              Connect me with_
            </h2>
            <div className="flex lg:items-center justify-center gap-10">
              <Link
                to="https://www.facebook.com/profile.php?id=100000535912980"
                target="_blank"
              >
                <FaFacebook className="text-3xl text-gray-400 hover:text-white" />
              </Link>
              {/* <Link to="" target="_blank">
                <FaTwitter className="text-3xl text-gray-400 hover:text-white" />
              </Link>
              <Link to="" target="_blank">
                <FaLinkedinIn className="text-3xl text-gray-400 hover:text-white" />
              </Link> */}
            </div>
          </div>
        </div>

        <div>
          <hr className="my-5 h-[2px] w-full mx-auto bg-gray-300 relative border-none" />
          <p className="text-gray-400 font-premium  text-center lg:text-lg text-base tracking-widest">
            Copyright © 2024 - All right reserved by Jahid Enterprise
          </p>
          <p className="font-premium text-center text-gray-500 text-base font-light tracking-widest">
            Developed by{" "}
            <a
              rel="noreferrer"
              target="_blank"
              className="underline link-accent"
              href="https://www.sajidsorker.com/"
            >
              Sajid Sorker
            </a>
          </p>
        </div>
      </footer>
    </section>
  );
};

export default Footer;

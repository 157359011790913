
import React from 'react';

const ErrorPage = () => {
    return (
      <div>
        <h2 className="text-center my-52 text-2xl tracking-widest text-error font-semibold ">
          Something went wrong
        </h2>
      </div>
    );
};

export default ErrorPage;
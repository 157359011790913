import React from "react";
import traveling from "../../../assets/about/travel.png";
import eating from "../../../assets/about/eating.png";
import fishing from "../../../assets/about/fishing.png";
import jahid from "../../../assets/about/jahid.jpg";
import './AboutMe.css';


const AboutMe = () => {

  
  const hobbies = [
    {
      id: 1,
      name: "Travel",
      img: traveling,
    },
    {
      id: 2,
      name: "Eating",
      img: eating,
    },
    {
      id: 3,
      name: "Fishing",
      img: fishing,
    },
  ];


  return (
    <section
      id="about"
      className="lg:max-w-screen-xl mx-auto w-[90%] mt-20 py-28 bg-base-100"
    >
      <div className="flex flex-col-reverse gap-20 lg:flex-row">
        <div className="img-box">
          <figure
            data-aos="zoom-in"
            data-aos-duration="500"
            data-aos-easing="linear"
            className="h-[400px] lg:h-full lg:w-full w-[370px]"
          >
            <img
              src={jahid}
              className="rounded-md about-img h-[400px] w-[370px] lg:h-full lg:w-full shadow-2xl"
              alt="people"
            />
          </figure>
        </div>
        <div className="text-justify  font-standard">
          <h1
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="500"
            className="lg:text-6xl text-5xl font-stylish"
          >
            Who Am I
          </h1>
          <p className="w-28 h-1 bg-accent mt-2 mb-10 absolute left-[20%] lg:left-[55%]"></p>
          <p className="text-lg mb-5 mt-16">
            As the sun rises over the bustling city of Gazipur, I begin my day
            with a sense of purpose and passion that few can match. I am Jahid
            Hasan, a simple and unassuming man who prides himself on embodying
            the values of reliability, hard work, and honesty.
          </p>
          <p className="text-lg my-5">
            In my free time, I indulge in my love for travel and fishing,
            cherishing moments spent with my cherished friends and family. But
            beyond these simple pleasures lies a deep commitment to serving
            humanity in all its forms.
          </p>

          <p className="text-xl my-5 bg-secondary font-premium  overflow-hidden bg-opacity-20 p-5 rounded-lg">
            With a heart that beats for the greater good, I have built not one,
            but two service institutes that are changing lives and making a
            difference in the world. The first is an agent banking center called{" "}
            <i>
              <a
                href="https://www.mutualtrustbank.com/"
                className="font-normal tracking-wider link-info underline underline-offset-2"
              >
                Mutual Trust Bank Chatar Bazar ABC.
              </a>
            </i>{" "}
            , where I advise people to make regular savings to secure their
            future generations and help small businesses grow.
          </p>
          <div className="flex justify-between w-11/12 lg:w-3/4 mt-10">
            {hobbies.map((hobby) => (
              <div
                key={hobby.id}
                className="flex border-l-none  lg:border-l-4 pl-3 lg:pl-6 lg:border-primary lg:border-opacity-70 items-center gap-2"
              >
                <span className="p-2 bg-primary bg-opacity-70 rounded-lg">
                  <img
                    className="hover:-translate-y-4 transition-transform duration-500"
                    width={30}
                    src={hobby.img}
                    alt={hobby.name}
                  />
                </span>
                <span className="font-bold text-lg">{hobby.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="text-justify font-premium lg:mt-10 -mt-10 bg-secondary overflow-hidden bg-opacity-20 p-5 rounded-lg">
        <p className="text-xl my-5">
          The second institute,{" "}
          <i>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/khidmahpharma"
              className="link-info underline font-normal underline-offset-2 tracking-wider"
            >
              Khidmah Pharma and Diagnostic(KPD)
            </a>
          </i>
          , is a beacon of hope for those seeking top-notch medical care and
          service. Here, a team of highly trained and experienced medical
          professionals is dedicated to providing the best possible care and
          service to each and every one of our patients.
        </p>
      </div>
      <p className="text-lg font-standard mt-10">
        Amidst the hustle and bustle of the modern world, I stand as a beacon of
        hope and a symbol of unwavering dedication. My journey has taken me
        through many trials and tribulations, but through it all, I have
        remained steadfast in my commitment to making a positive impact on the
        world.
      </p>
    </section>
  );
};

export default AboutMe;

/** @format */

import React, { useState } from "react";
import { FaCheck, FaEdit, FaTimes } from "react-icons/fa";

const EditTags = ({
  highlight,
  newDispatch,
  index,
  editDispatch,
  editAction,
  deleteAction,
  newDelete,
  newEdit,
}) => {
  const [inputForm, setInputForm] = useState(false);
  const [inputValue, setInputValue] = useState();

  const handleEditTags = () => {
    setInputForm(false);
    if (editDispatch) {
      editDispatch(editAction([inputValue, index]));
    }
    if (newDispatch) {
      newDispatch(newEdit([inputValue, index]));
    }
  };

  return (
    <div className="flex items-center gap-3">
      <span className="text-xl font-semibold">{index + 1}.</span>
      {inputForm ? (
        <div className="flex items-center gap-5">
          <textarea
            onBlur={(e) => setInputValue(e.target.value)}
            type="text"
            cols="50"
            rows="4"
            defaultValue={highlight}
            className="custom-text-input font-standard"
          />
          <span
            onClick={handleEditTags}
            className="hover:scale-110 p-3 transition-transform duration-200 bg-gray-300 rounded-full"
          >
            <FaCheck className="text-lg text-gray-700" />
          </span>
        </div>
      ) : (
        <div className="flex items-center gap-5">
          <p className="text-lg font-medium font-standard text-gray-600 px-6 py-1 ">
            {highlight}
          </p>
          <span
            onClick={() => setInputForm(true)}
            className="p-4 hover:scale-110 transition-transform duration-200 bg-gray-300 rounded-full"
          >
            <FaEdit
              title="Edit highlights"
              className="text-base text-gray-700"
            />
          </span>
          <span
            onClick={() => {
              editDispatch
                ? editDispatch(deleteAction(index))
                : newDispatch(newDelete(index));
            }}
            className="p-4 hover:scale-110 transition-transform duration-200 bg-gray-300 rounded-full"
          >
            <FaTimes
              title="Cancel edit highlights"
              className="text-base text-gray-700"
            />
          </span>
        </div>
      )}
    </div>
  );
};

export default EditTags;

/** @format */

export const addServiceAction = {
  SET_ADD_NEW_TAG: "SET_ADD_NEW_TAG",
  SET_HIGH_LIGHT_TAG: "SET_HIGH_LIGHT_TAG",
  SET_INPUT_VALUE: "SET_INPUT_VALUE",
  SET_DELETE_TAG_INDEX: "SET_DELETE_TAG_INDEX",
  SET_EDIT_TAGS: "SET_EDIT_TAGS",
  SET_ADD_SERVICE_LOADING: "SET_ADD_SERVICE_LOADING",
  SET_NEW_SERVICE: "SET_NEW_SERVICE",
  SET_GALLERY_IMAGE: 'SET_GALLERY_IMAGE',
  SET_STORAGE_ID: "SET_STORAGE_ID",
};

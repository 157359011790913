


export const addProjectAction = {
  SET_ADD_NEW_MOTIVE: "SET_ADD_NEW_MOTIVE",
  SET_HIGH_LIGHT_MOTIVE: "SET_HIGH_LIGHT_MOTIVE",
  SET_INPUT_VALUE: "SET_INPUT_VALUE",
  SET_DELETE_MOTIVE_INDEX: "SET_DELETE_MOTIVE_INDEX",
  SET_EDIT_MOTIVE: "SET_EDIT_MOTIVE",
  SET_ADD_PROJECT_LOADING: "SET_ADD_PROJECT_LOADING",
  SET_NEW_PROJECT: "SET_NEW_PROJECT",
  SET_GALLERY_IMAGE: "SET_GALLERY_IMAGE",
  SET_STORAGE_ID: "SET_STORAGE_ID",
};

import React from "react";
import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Loading from "../components/Loading/Loading";
import { AuthContext } from "../Contexts/UserContext/UserContext";
import UseAdmin from "../Hooks/UseAdmin/UseAdmin";

const AdminRoute = ({ children }) => {
  const { user, loading } = useContext(AuthContext);
  const [data, adminLoading] = UseAdmin(user?.email);
  const location = useLocation();

  if (loading || adminLoading) {
    return <Loading>Admin Loading...</Loading>;
  }
  if (user && data.admin) {
    return children;
  }
  return <Navigate to="/" state={{ from: location }} replace></Navigate>;
};

export default AdminRoute;

/** @format */

import React, { createContext, useReducer } from "react";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import {
  editServiceInitialState,
  editServiceReducer,
} from "../../../../state/EditServiceReducer/EditServiceReducer";
import { editServiceActions } from "../../../../state/EditServiceReducer/EditServiceActions";
import { handleNewGallery, handleSetHighLight, onFetchingError, onFetchingSuccess } from "../../../../state/EditServiceReducer/EditServiceActionCreator";
import Loading from "../../../../components/Loading/Loading";



export const EDIT_SERVICE_CONTEXT = createContext();

const EditService = () => {
  const serviceId = localStorage.getItem("ServiceId");

 

  const [state, editDispatch] = useReducer(
    editServiceReducer,
    editServiceInitialState
  );

  const data = {
    state, editDispatch
  }

  const {
    loading,
    error,
    errorMessage,
   
  } = state;




  // Fetching the service
  useEffect(() => {
    if (serviceId !== null) {
      editDispatch({ type: editServiceActions.FETCHING_START });
      fetch(`${process.env.REACT_APP_SERVER_URL}/service/${serviceId}`)
        .then((res) => res.json())
        .then((data) => {
          
          editDispatch(onFetchingSuccess(data));
          editDispatch(handleSetHighLight(data.tags));
          editDispatch(handleNewGallery(data.gallery));
        })
        .catch((err) => {
          editDispatch(onFetchingError(err.message));
        });
    }
  }, [serviceId]);

 


  if (loading) {
    return <Loading>Loading...</Loading>;
  }
  if (error) {
    return (
      <p className="text-center my-52 text-2xl tracking-widest text-error font-semibold ">
        {errorMessage}
      </p>
    );
  }

 

  return (
    <section>
      <h2 className="text-3xl my-10 font-serif font-semibold underline">
        Update your service
      </h2>
      {/* =============================================================== */}

      <div className="flex flex-col gap-10">

        <div>
          <EDIT_SERVICE_CONTEXT.Provider value={data}>
            <Outlet></Outlet>
          </EDIT_SERVICE_CONTEXT.Provider>
        </div>
      </div>

    </section>
  );
};

export default EditService;

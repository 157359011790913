/** @format */

import React from "react";

const TermsPage = () => {
  return (
    <section className="pt-32 w-[85%] lg:max-w-screen-lg mx-auto">
      <h2 className="lg:text-5xl text-4xl font-stylish font-semibold">
        Terms of service:
      </h2>
      <p className="w-24 h-1 mb-16 lg:ml-[10%] mt-2 ml-[20%]  bg-primary"></p>
      <p className="font-standard">
        Welcome to my website! By using this website, you agree to be bound by
        the following terms and conditions of use, which together with our
        privacy policy govern the relationship between you and us in relation to
        this website. If you disagree with any part of these terms and
        conditions, please do not use our website.
      </p>
      <br />
      <p className="font-standard">
        The term 'us' or 'we' refers to the owner of the website. The term 'you'
        refers to the user or viewer of our website.
      </p>{" "}
      <br />
      <ul className="list-disc">
        <li className="font-premium text-lg">
          The content of the pages of this website is for your general
          information and use only. It is subject to change without notice.
        </li>
        <li className="font-premium text-lg">
          The content of the pages of this website is for your general
          information and use only. It is subject to change without notice.
        </li>
        <li className="font-premium text-lg">
          Your use of any information or materials on this website is entirely
          at your own risk, for which we shall not be liable. It shall be your
          own responsibility to ensure that any products, services or
          information available through this website meet your specific
          requirements.
        </li>
        <li className="font-premium text-lg">
          This website contains material which is owned by or licensed to us.
          This material includes, but is not limited to, the design, layout,
          look, appearance and graphics. Reproduction is prohibited other than
          in accordance with the copyright notice, which forms part of these
          terms and conditions.
        </li>
        <li className="font-premium text-lg">
          Unauthorised use of this website may give rise to a claim for damages
          and/or be a criminal offence.
        </li>
        <li className="font-premium text-lg">
          From time to time, this website may also include links to other
          websites. These links are provided for your convenience to provide
          further information. They do not signify that we endorse the
          website(s). We have no responsibility for the content of the linked
          website(s).
        </li>
      </ul>
      <br />
      <p className="font-standard">
        We reserve the right to modify these terms and conditions at any time
        without prior notice. It is your responsibility to periodically review
        these terms and conditions to stay informed of any changes. Your
        continued use of this website constitutes your agreement to these terms
        and conditions and any updates.
      </p>
    </section>
  );
};

export default TermsPage;

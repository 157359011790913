/** @format */

import React from "react";
import CommonButton from "../CommonButton/CommonButton";

const ConfirmationModal = ({ action, modalText, actionText }) => {
  return (
    <div>
      <input type="checkbox" id="confirmModal" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box p-5">
          <h3 className="font-medium text-xl mt-5">{modalText}</h3>
          <div className="modal-action gap-5 mt-10">
            <CommonButton
              customClass={"btn-sm !px-4"}
              clickFunction={() => action()}
            >
              <label htmlFor="confirmModal">{actionText}</label>
            </CommonButton>

            <CommonButton customClass={"btn-sm !px-4"}>
              <label htmlFor="confirmModal">Cancel</label>
            </CommonButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;

/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BlogBanner from "../../assets/SPB/BlogBanner.png";
import { FaShare, FaComment, FaHeart, FaUserAlt } from "react-icons/fa";
import { useForm } from "react-hook-form";
import Reply from "./Reply";
import { useBlogs } from "./BlogDetailsAPI";
import Loading from "../../components/Loading/Loading";
import { RiDoubleQuotesL } from "react-icons/ri";
import CommentForm from "./CommentForm";
import RecentBlogs from "./RecentBlogs";

const BlogDetails = () => {
  const { isLoading, handleLikes, handleDisLikes, currentBlogs, recentBlogs } =
    useBlogs();

  const [allComment, setAllComment] = useState([]);
  const [commentLimit, setCommentLimit] = useState(5);
  const [splitComment, setSplitComment] = useState([]);

  useEffect(() => {
    setAllComment(currentBlogs?.comments);
    setSplitComment(allComment?.slice(0, commentLimit));
  }, [allComment, currentBlogs?.comments, commentLimit]);

  /* ======================= Handling Error ======================== */
  if (isLoading) {
    return <Loading>Loading...</Loading>;
  }

  const {
    admin,
    share,
    thumbnail,
    title,
    gallery,
    postingTime,
    description,
    alreadyLiked,
    likes,
    alReadyComment,
  } = currentBlogs;

  return (
    <section>
      {/* ======================== BANNER SECTION ========================= */}
      <div
        style={{ backgroundImage: `url(${BlogBanner})` }}
        className="bg-cover hidden bg-no-repeat bg-center lg:h-[90vh] h-[60vh] flex justify-center items-end"
      >
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in"
          className="text-center lg:mb-32 mb-20"
        >
          <p className="text-xl  mb-3">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Reiciendis
            earum quasi rem, iusto eius adipisci.
          </p>
          <h1 className="text-neutral text-5xl font-bold">Blog Details</h1>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col gap-8 lg:max-w-screen-xl mx-auto my-32">
        {/* =========================  Blog details section ================== */}
        <div className="lg:w-[75%] w-[90%] mx-auto">
          <div>
            <h2
              data-aos="fade-up"
              data-aos-duration="300"
              data-aos-easing="ease-in-cubic"
              className="text-4xl font-medium mb-8"
            >
              {title}
            </h2>
            <div dangerouslySetInnerHTML={{ __html: description }}></div>

            <img className="w-[520px] my-10" src={thumbnail?.url} alt="blog" />
          </div>
          <div className="flex lg:flex-row flex-col justify-between lg:mr-20 mt-10">
            <div className="flex gap-5 mb-5 items-center text-lg text-gray-600 font-semibold">
              <span>{postingTime}</span>
              <span>{admin}</span>
            </div>

            <div className="flex gap-10 items-center text-gray-500 font-semibold">
              <p className="flex items-center gap-2">
                {alreadyLiked ? (
                  <FaHeart
                    title="dislike"
                    onClick={handleDisLikes}
                    className="text-pink-500 cursor-pointer text-2xl"
                  />
                ) : (
                  <FaHeart
                    title="like"
                    onClick={handleLikes}
                    className="text-gray-600 cursor-pointer text-2xl"
                  />
                )}
                <span className="text-lg text-gray-800">{likes?.length}</span>
              </p>

              <p className="flex items-center gap-2">
                <a href="#comment">
                  <FaComment
                    title="comment"
                    className={`text-2xl  hover:text-blue-500 ${
                      alReadyComment ? "text-blue-500" : "text-gray-600"
                    }`}
                  />
                </a>
                <span className="text-lg text-gray-800">
                  {allComment?.length}
                </span>
              </p>

              <p className="flex items-center gap-2">
                <FaShare
                  title="share"
                  className="text-2xl text-gray-600 hover:text-green-500"
                />
                <span className="text-lg text-gray-800">{share}</span>
              </p>
            </div>
          </div>

          {/* ========================= Comment section ================== */}

          <div className="mt-20">
            <h1 className="text-3xl underline underline-offset-2 text-neutral mb-10">
              Recent Comments
            </h1>

            <>
              {allComment?.length === 0 ? (
                <h3 className="text-lg ">No comments yet..</h3>
              ) : (
                <div>
                  {splitComment?.length >= 1 &&
                    splitComment?.map(
                      (
                        { user, id, message, image, time, reply, uid },
                        index
                      ) => (
                        <div key={id} className="mb-10">
                          <div className="flex items-start gap-5">
                            <span className="font-semibold text-lg">
                              {index + 1}.
                            </span>
                            {image ? (
                              <img
                                className="rounded-full w-12 h-12 bg-white"
                                src={image}
                                alt="comment"
                              />
                            ) : (
                              <span className="bg-gray-400 rounded-full p-2">
                                <FaUserAlt className="text-3xl text-gray-500 " />
                              </span>
                            )}

                            <div className="text-gray-600 font-semibold mb-4">
                              <p className="text-primary text-xl font-medium">
                                {user}
                              </p>
                              <p className="text-lg -mt-2 text-neutral">
                                on{" "}
                                <span className="text-xs text-gray-700">
                                  {time}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="flex mt-5 gap-1 items-start">
                            <RiDoubleQuotesL
                              size={35}
                              className="text-gray-500 -mt-2"
                            />
                            <p className="text-lg text-gray-600 mb-3 ">
                              {message}
                            </p>
                          </div>

                          {/* ============================== Reply Form ==================================*/}
                          {reply ? (
                            <div
                              className="ml-20 flex items-start gap-2"
                              key={reply?.id}
                            >
                              <span className="text-sm text-blue-500">
                                Replied by
                              </span>
                              <div>
                                <p className="text-primary text-xl -mt-1 font-medium">
                                  {reply?.user}
                                </p>
                                <p className="text-xs text-gray-700">
                                  {reply?.time}
                                </p>
                                <p className="text-gray-700 mt-4">
                                  {reply?.message}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <Reply commentId={id} uid={uid} user={user}></Reply>
                          )}
                        </div>
                      )
                    )}
                </div>
              )}
            </>
            {allComment?.length > splitComment?.length ? (
              <p
                onClick={() => setCommentLimit(commentLimit + 5)}
                className="text-blue-600 cursor-pointer hover:underline"
              >
                View more...
              </p>
            ) : (
              splitComment?.length > 5 && (
                <p
                  onClick={() => setCommentLimit(commentLimit - 5)}
                  className="text-blue-600 cursor-pointer hover:underline"
                >
                  View less...
                </p>
              )
            )}
          </div>

          {/* =========================== Comment form ============================ */}

          <CommentForm></CommentForm>
        </div>

        {/* ========================= Recent Blogs section ================== */}

        <RecentBlogs
          recentBlogs={recentBlogs}
          admin={admin}
          postingTime={postingTime}
        ></RecentBlogs>
      </div>
    </section>
  );
};

export default BlogDetails;
